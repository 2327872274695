export const BillingEN = {
  seo: {
    title: "Payments | universo corsi",
  },

  planViewer: {
    title: "My plan",

    alertCanceled: {
      title: "Subscription cancelled",
      body: "Your subscription will not renew. You will be able to continue accessing all content until the <strong>{{expireDate}}</strong>. Upon completion, you will lose access to your Welevel benefits.",
    },
    alertExpired: {
      title: "Subscription ended",
      body: "To access all Welevel content activate a new subscription.",
    },
    alertPastDue: {
      title: "Unable to renew your subscription",
      body: "Your membership has failed to renew. You will continue to access all content until <strong>{{expireDate}}</strong>. Once completed, you will lose access to your Welevel benefits.",
    },
    alertNoRenew: {
      title: "Subscription without renewal",
      body: "Your subscription will not renew. You will be able to continue accessing all content until the <strong>{{expireDate}}</strong>. Upon completion, you will lose access to your Welevel benefits.",
    },

    messages: {
      success: "Plan changed successfully! Wait for the page to refresh.",
      error: "Error occurred changing the plan",
    },
  },

  paymentMethods: {
    title: "Payment methods",

    actions: {
      new: "New payment method",
      default: "Set as default",
      delete: "Delete payment method",
    },

    dialogAdd: {
      title: "Add a payment method",
    },

    dialogDefault: {
      title: "You are changing your default payment method",
      body: "Do you want to continue?",
    },

    dialogDelete: {
      title: "You are removing this payment method. Do you want to continue?",
      body: "By delete a payment method the associated subscriptions will be terminated",
      confirm: "Delete payment method",
    },
  },

  subscriptions: {
    title: "Subscriptions",
    stillNot: "You don't have any subscriptions yet",
  },

  transactions: {
    title: "Purchases",
    stillNot: "You don't have any transactions yet",
  },

  planCardV2: {
    plan: {
      current: "Current plan",
      notAvailableAnymore: "Plan no longer available",
    },
    vatExcluded: "TAX excluded",

    instalment: {
      body: "Accrued in <strong>{{instalmentCount}}</strong> installments of <strong>{{instalmentPrice}}</strong>",

      monthly: " per month",
      yearly: " per year",
      monthCycle: " every {{monthCycle}} months",
    },

    method: "Payment method: ",
    nextRenewal: "Next renewal date: ",
    cancelSubscription: "Cancel subscription",

    buttons: {
      choose: "Choose this plan",
      needHelp: "Do you need help?",
      contactUs: "Contact us",
    },
  },

  paymentMethod: {
    holder: "<strong>Holder</strong>",
    expireDate: "<strong>Expiry date</strong>",
    setAsDefault: "Set as default",
    delete: "Delete",
  },
};
