export const PartnersIT = {
  seo: {
    title: "Le nostre aziende formatrici",
  },

  header: {
    title: "Ti presentiamo i nostri partner",
    description:
      "Le aziende che hanno come obiettivo quello di offrire servizi per gli operatori del settore del turismo e dell’ospitalità",
  },
};
