export const LayoutIT = {
  navbar: {
    menu: {
      studyplans: "Percorsi",
      events: "Eventi Live",
      explore: "Esplora",
      subscribe: "Abbonati",
      unlimited: "Welevel Unlimited",
      business: "Welevel Business",
    },
    avatar: {
      login: "Accedi",
      register: "Registrati",
      guest: "Guest",
      tooltip: {
        guest: "Accedi o registrati a Universo Corsi",
        account: "Account",
      },
    },
    userMenu: {
      me: "La mia home",
      profile: "Profilo pubblico",
      billing: "Pagamenti",
      settings: "Impostazioni",
      help: "Cerchi aiuto?",
      logout: "Esci",
      tooltip: {
        locked:
          "Per accedere alle impostazioni devi prima confermare la tua email.",
      },
    },
  },
  footer: {
    welevel: {
      title: "Universo Corsi",
      /* - */
      about: "Chi siamo",
      teachers: "Formatori",
      partners: "Partner",

      contact: "Contattaci",
      support: "Domande frequenti",
    },
    membership: {
      title: "Abbonamenti",
      /* - */
      unlimited: "Welevel Unlimited",
      business: "Welevel Business",
    },
    featured: {
      title: "Scopri di più",
      /* - */
      explore: "Video corsi",
      studyplans: "Percorsi formativi cerfiticati",
      events: "Eventi e Lezioni live",
      masterclass: "Masterclass",
      topics: "Argomenti",
    },
    information: {
      title: "Informazioni",
      /* - */
      terms: "Termini e condizioni",
      privacy: "Privacy Policy",
      cookie: "Preferenze cookie",
      job: "Trova Lavoro",
    },
    languageSelector: {
      title: "Seleziona lingua",
      languages: {
        ita: "Italiano",
        eng: "Inglese",
      },
    },
    newsletter: {
      title: "Non perdere le novità",
      subtitle: "Iscriviti alla nostra newsletter!",

      // Validation
      errors: {
        missing: "Dato necessario",
        email: "Formato email non valido",
        privacy: "Consenso necessario",
      },
      labels: {
        privacy: "Accetto la <url>privacy policy</url> di Welevel.",
      },
      helperText: {
        name: "Inserisci il tuo nome",
        email: "Inserisci la tua email",
      },
      processing: "Sto inviando...",
      success: "Sei iscritto!",
      error: "C'è stato un problema",
    },
  },
  authAlert: {
    email: {
      title: "Non hai ancora confermato la mail.",
      body: "La tua esperienza in Universo Corsi sarà limitata. Se non hai ricevuto la mail <strong>clicca</strong> per reinviarla.",
    },
    password: {
      title: "Non hai ancora completato la registrazione.",
      body: "La tua esperienza in Universo Corsi sarà limitata. Completa ora la <strong>registrazione</strong>.",
    },
  },
  LayoutUser: {
    contactCTA: "Contatta Ora",
    contactCTAShort: "Contatta",

    courseSectionTitle: "Corsi di {{userName}}",
    eventSectionTitle: "Eventi di {{userName}}",
    partnerAgencySectionTitle: "{{userName}} lavora presso",
    teacherSectionTitle: "I docenti di {{userName}}",

    courseSectionEmptyTitle: "{{userName}} non ha ancora aggiunto nessun corso",
    eventSectionEmptyTitle: "{{userName}} non ha ancora aggiunto nessun evento",
    partnerAgencySectionEmptyTitle:
      "{{userName}} non ha ancora aggiunto delle aziende",
    teacherSectionEmptyTitle: "{{userName}} non ha ancora aggiunto dei docenti",
  },
  LayoutUserInfo: {
    infoTitle: "Informazioni su {{userName}}",
    infoEmpty: "{{userName}} non ha ancora una descrizione",
    topicsTitle: "Argomenti",
    reviewsTitle: "Recensioni dei corsi",
    linksTitle: "Link",
  },
};
