import i18n from "i18next";
import { Menu, MenuItem } from "@material-ui/core";
import { Trans, useTranslation } from "react-i18next";
import React, { useState } from "react";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import moment from "moment/min/moment-with-locales";
import itaFlag from "../../../assets/images/flags/ita.png";
import enFlag from "../../../assets/images/flags/eng.png";
import { FooterLink } from "./linksMenu";

moment.locale(i18n.language);

const languages = [
  {
    lang: "it-IT",
    key: "layout.footer.languageSelector.languages.ita",
    icon: <img src={itaFlag} alt="ita_flag" height="20" width="30" />,
  },
  {
    lang: "en-US",
    key: "layout.footer.languageSelector.languages.eng",
    icon: <img src={enFlag} alt="en_flag" height="20" width="30" />,
  },
];

const LanguageSelector = () => {
  const { t } = useTranslation();

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleLanguage = (lang) => {
    switch (lang) {
      case "it-IT":
      case "en-US":
        // Set the selected language in local storage
        localStorage.setItem("i18nextLng", lang);
        i18n.changeLanguage(lang);
        moment.locale(lang);
        break;
      default:
        localStorage.setItem("i18nextLng", "it-IT");
        i18n.changeLanguage("it-IT");
        moment.locale("it-IT");
    }

    handleClose();
    document.location.reload();
  };

  const handleClose = (lan) => {
    setAnchorEl(null);
  };

  return (
    <>
      <FooterLink onClick={handleClick} style={{ cursor: "pointer" }}>
        <Trans i18nKey="layout.footer.languageSelector.title" />
      </FooterLink>

      <Menu
        id="lang-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => handleClose("")}>
        {languages.map(({ lang, key, icon }, index) => {
          return (
            <MenuItem
              key={lang}
              selected={lang === i18n.language}
              style={{ fontSize: "1rem" }}
              onClick={() => handleLanguage(lang)}>
              {icon && <ListItemIcon>{icon}</ListItemIcon>}
              <ListItemText primary={t(key)} />
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
};
export default LanguageSelector;
