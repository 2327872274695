import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

export default function Redirect({ to }) {
  const navigate = useNavigate();
  // console.log("redirecting from", window.location.pathname, "to", to);
  useEffect(() => {
    navigate(to);
  }, []);
  return null;
}
