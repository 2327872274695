import { CheckoutEN } from "./checkout";
import { CourseBoxEN } from "./courseBox";
import { Cards } from "./cards";
import { GeneralEN } from "./general";
import { LayoutEN } from "./layout";
import { LoginEN } from "./login";
import { NavigatorEN } from "./navigator";
import { PasswordRecoveryEN } from "./passwordRecovery";
import { PlanAssetEN } from "./planAsset";
import { BillingEN } from "./routes/billings";
import { EmailConfirmEN } from "./routes/emailConfirm";
import { EventEN } from "./routes/event";
import { EventsEN } from "./routes/events";
import { HomeEN } from "./routes/homepage";
import { MeEN } from "./routes/me";
import { passwordResetEN } from "./routes/passwordReset";
import { SettingsEN } from "./routes/settings";
import { StreamEN } from "./routes/stream";
import { SignUpEN } from "./signUp";
import { UpsellBannerEN } from "./upsellBanner";
import { CourseEN } from "./routes/course";
import { PlaylistEN } from "./routes/playlist";
import { LiveChatEN } from "./liveChat";
import { forgotPasswordEN } from "./routes/forgotPassword";
import { DashboardEN } from "./routes/dashboard";
import { VideoDropzoneEN } from "./videoDropzone";
import { SearchEN } from "./search";
import { DialogAddToPlaylistEN } from "./dialogAddToPlaylist";
import { QuestionBannerEN } from "./questionBanner";
import { PartnerEN } from "./routes/partner";
import { PartnersEN } from "./routes/partners";
import { SupportEN } from "./routes/support";
import { TeachersEN } from "./routes/teachers";
import { UserEN } from "./routes/user";
import { AskQuestionModalEN } from "./askQuestionModal";
import { YupLocaleEN } from "./yup";
import { ContactUsEN } from "./routes/contactUs";
import { ErrorsEN } from "./errors";
import { AvatarEN } from "./components/avatar";
import { CopyToClipboardEN } from "./components/copyToClipboard";
import { ImageDropzoneEN } from "./components/imageDropzone";
import { DropzoneEN } from "./components/dropzone";
import { ImageEditorEN } from "./components/imageEditor";
import { ReviewFormEN } from "./components/reviewForm";
import { ExploreEN } from "./routes/explore";
import { ErrorBoundaryEN } from "./components/errorBoundary";
import { LessonEN } from "./routes/lesson";
import { AcceptInvitationEN } from "./routes/acceptInvitation";
import { RequestConfirmEmailEN } from "./routes/requestConfirmationEmail";
import { completeSubscriptionFormEN } from "./fragments/completeSubscriptionForm";
import { CompleteRegistrationEN } from "./routes/completeRegistration";
import { ProfileLockedEN } from "./routes/profileLocked";

export const TRANSLATIONS_EN = {
  /* Global Strings used in multiple sections */
  general: GeneralEN,
  yup: YupLocaleEN,
  errors: ErrorsEN,
  layout: LayoutEN,
  login: LoginEN,
  signUp: SignUpEN,
  passwordRecovery: PasswordRecoveryEN,
  checkout: CheckoutEN,
  components: {
    avatar: AvatarEN,
    copyClipboard: CopyToClipboardEN,
    dropzone: DropzoneEN,
    errorBoundary: ErrorBoundaryEN,
    imageDropzone: ImageDropzoneEN,
    imageEditor: ImageEditorEN,
    reviewForm: ReviewFormEN,
  },
  fragments: {
    completeSubscriptionForm: completeSubscriptionFormEN,
  },
  route: {
    acceptInvitation: AcceptInvitationEN,
    completeRegistration: CompleteRegistrationEN,
    passwordReset: passwordResetEN,
    forgotPassword: forgotPasswordEN,
    emailConfirm: EmailConfirmEN,
    dashboard: DashboardEN,
    homepage: HomeEN,
    me: MeEN,
    explore: ExploreEN,
    billing: BillingEN,
    settings: SettingsEN,
    course: CourseEN,
    event: EventEN,
    events: EventsEN,
    playlist: PlaylistEN,
    profileLocked: ProfileLockedEN,
    stream: StreamEN,
    partner: PartnerEN,
    partners: PartnersEN,
    support: SupportEN,
    teachers: TeachersEN,
    user: UserEN,
    contactUs: ContactUsEN,
    lesson: LessonEN,
    requestConfirmationEmail: RequestConfirmEmailEN,
  },
  chat: LiveChatEN,
  upsellBanner: UpsellBannerEN,
  questionBanner: QuestionBannerEN,
  cards: Cards,
  navigator: NavigatorEN,
  courseBox: CourseBoxEN,
  plansAsset: PlanAssetEN,
  videoDropzone: VideoDropzoneEN,
  search: SearchEN,
  dialogAddToPlaylist: DialogAddToPlaylistEN,
  askQuestionModal: AskQuestionModalEN,
};
