export const LayoutEN = {
  navbar: {
    menu: {
      studyplans: "Studyplans",
      events: "Live Events",
      explore: "Explore",
      unlimited: "Welevel Unlimited",
      business: "Welevel Business",
    },
    avatar: {
      login: "Sign in",
      register: "Sign Up",
      guest: "Guest",
      tooltip: {
        guest: "Sign In or Up for Welevel",
        account: "Account",
      },
    },
    userMenu: {
      me: "My Home",
      profile: "Public profile",
      settings: "Settings",
      billing: "Billing",
      help: "Need help?",
      logout: "Sign Out",
      tooltip: {
        locked: "To access the settings you must first confirm your email.",
      },
    },
  },
  footer: {
    welevel: {
      title: "Universo Corsi",
      /* - */
      about: "About us",
      teachers: "Teachers",
      partners: "Partners",

      contact: "Contact us",
      support: "Common questions",
    },
    membership: {
      title: "Subscriptions",
      /* - */
      unlimited: "Welevel Unlimited",
      business: "Welevel Business",
    },
    featured: {
      title: "Highlights",
      /* - */
      explore: "Video courses",
      studyplans: "Certified Training",
      events: "Live Events and Lessons",
      masterclass: "Masterclasses",
      topics: "Topics",
    },
    information: {
      title: "Information",
      /* - */
      terms: "Terms and conditions",
      privacy: "Privacy Policy",
      cookie: "Cookie preferences",
      job: "Find Job",
    },
    languageSelector: {
      title: "Select language",
      languages: {
        ita: "Italian",
        eng: "English",
      },
    },
    newsletter: {
      title: "Don't miss the news",
      subtitle: "Subscribe to our newsletter!",

      // Validation
      errors: {
        missing: "Necessary data",
        email: "Invalid email format",
        privacy: "Required to agree",
      },
      labels: {
        privacy: "I accept the Welevel's <url>privacy policy</url>.",
      },
      helperText: {
        name: "Insert your name",
        email: "Insert your email",
      },
      processing: "I'm sending...",
      success: "You'are subscribed!",
      error: "There was a problem",
    },
  },
  authAlert: {
    email: {
      title: "You haven't confirmed the email yet.",
      body: "Your experience in Universo Corsi will be limited. If you did not receive the email <strong>click</strong> to resend it.",
    },
    password: {
      title: "You have not yet completed registration.",
      body: "Your experience in Universo Corsi will be limited. Complete <strong>registration</strong> now.",
    },
  },
  LayoutUser: {
    contactCTA: "Contact Now",
    contactCTAShort: "Contact",

    courseSectionTitle: "{{userName}} courses",
    eventSectionTitle: "{{userName}} events",
    partnerAgencySectionTitle: "{{userName}} workplaces",
    teacherSectionTitle: "{{userName}} teachers",

    courseSectionEmptyTitle: "{{userName}} hasn't added any courses yet",
    eventSectionEmptyTitle: "{{userName}} hasn't added any events yet",
    partnerAgencySectionEmptyTitle: "{{userName}} hasn't added a workplace yet",
    teacherSectionEmptyTitle: "{{userName}} hasn't added teachers yet",
  },
  LayoutUserInfo: {
    infoEmpty: "{{userName}} doesn't have a description yet",
    infoTitle: "Info about {{userName}}",
    topicsTitle: "Topics",
    reviewsTitle: "Course reviews",
    linksTitle: "Links",
  },
};
