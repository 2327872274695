export const StreamIT = {
  back: "Torna alla pagina evento",
  downloadSlides: "Scarica le slides",
  answers: "Risposte",
  streaming: {
    key: "Chiave streaming",
    server: "Server streaming",
    setup: "Imposta lo streaming",
    setupMSG:
      "Puoi impostare lo streaming attraverso un software di streaming come OpenBroadcasterSoftware (OBS) oppure collegando una chiamata zoom.us",
  },
  QA: {
    close: "Chiudi sezione Q/A",
    reOpen: "Ri-apri sezione Q/A",
    topQuestions: "Top questions",
    newQuestion: "Nuova domanda",
    questions: "Domande",
    askQuestion: "Rendi significativa la live, fai una domanda!",
    answerQs: "Rispondi alle domande e partecipa",
    closeDetail: "Chiudi dettaglio",
  },
  chat: {
    open: "Apri la chat",
    close: "Chiudi la chat",
  },
  subscribe: {
    welevel: "Abbonati a welevel!",
    unlimited: "Abbonati a Unlimited",
    buyToWatch:
      "Per vedere questa e tutte le altre sessioni acquista l'evento o abbonati",
    toWatch:
      "Per vedere questa sessione e accedere a tutta la formazione di welevel, abbonati subito!",
    reservedForPro:
      "L'accesso a questo seminario è riservato agli utenti Pro. Per vedere questo seminario abbonati a Welevel Unlimited",
  },
  session: {
    name: "Sessione",
    private: "Questa è una sessione di formazione privata.",
    joinMSG: "Per partecipare a questa sessione clicca il pulsante qui sotto",
    ended: "La sessione è terminata",
    notStartedYet: "La sessione deve ancora iniziare",
    liveNow: "Live ora",
    watchWebinar: "Guarda il webinar!",
    logInSignUpWatch: "Accedi o registrati a Welevel e inizia a vedere",
    logInSignUp: "Accedi o Registrati",
    insertPsw: "Inserisci la password",
    enterZoomRoom: "Entra nella sala zoom!",
    rewatchNavigate:
      "Rivedila subito! Oppure continua a formarti con i nostri corsi!",
    rewatch: "Rivedi",
    moreAboutEvent: "Altro in questo evento",
  },
  explore: {
    name: "Esplora",
    otherContent: "Esplora altri contenuti",
    otherMeantime: "Nel frattempo esplora tutti gli altri contenuti",
  },
  interact: {
    join: "Partecipa",
    send: "Invia",
    buy: "Acquista",
    buyTicket: "Acquista il biglietto!",
  },
  privacyPolicy: {
    forEvents: "Privacy policy per Eventi",
    accept:
      "Acconsento alla cessione dei miei dati personali ai partner dell’evento, per loro autonomi usi commerciali come specificato nella",
  },
  form: {
    optionalField: "Campo opzionale",
    fill: "Compila il form",
    mandatoryField: "Campo obbligatorio",
  },
};
