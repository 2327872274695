import SvgIcon from "@material-ui/core/SvgIcon";


export default function HotelIcon(props) {
  return (
    <SvgIcon {...props}>
      <path d="M22.51,21.73l.67.1a1.07,1.07,0,0,1,.81,1,1.06,1.06,0,0,1-.79,1.07,2.6,2.6,0,0,1-.56.07H1.35a1.24,1.24,0,0,1-1.14-.5,1.11,1.11,0,0,1,.94-1.73h.33V2.25h-.3A1.15,1.15,0,0,1,0,1.13,1.15,1.15,0,0,1,1.16,0H22.55A2.17,2.17,0,0,1,23,0a1.07,1.07,0,0,1,1,1.15,1.1,1.1,0,0,1-1.14,1.05c-.1,0-.2,0-.33,0Zm-2.27,0V2.26H3.75V21.74h6V18H9.36C9,18,8.65,18,8.3,18a.67.67,0,0,1-.72-.83,4.5,4.5,0,0,1,6.18-3.27,4.39,4.39,0,0,1,2.65,3.23.65.65,0,0,1-.63.87,3.65,3.65,0,0,1-.47,0H14.26v3.75Z"/>
      <path d="M9,6c0,.23,0,.45,0,.68,0,.54-.26.79-.79.81H6.81A.72.72,0,0,1,6,6.72,13.83,13.83,0,0,1,6,5.28a.74.74,0,0,1,.83-.77H8.15A.74.74,0,0,1,9,5.34Z"/>
      <path d="M13.48,6c0,.24,0,.48,0,.72,0,.5-.25.76-.74.78a14.42,14.42,0,0,1-1.47,0,.67.67,0,0,1-.74-.69,10.55,10.55,0,0,1,0-1.59.7.7,0,0,1,.77-.69c.46,0,.93,0,1.4,0s.77.28.78.82C13.49,5.54,13.48,5.76,13.48,6Z"/>
      <path d="M18,6c0,.23,0,.46,0,.68,0,.52-.24.78-.76.8s-.95,0-1.43,0A.7.7,0,0,1,15,6.72a11.53,11.53,0,0,1,0-1.44.73.73,0,0,1,.82-.77h1.32c.56,0,.81.27.83.83Z"/>
      <path d="M9,10.5c0,.23,0,.47,0,.71a.71.71,0,0,1-.76.78c-.48,0-1,0-1.43,0A.72.72,0,0,1,6,11.2q0-.7,0-1.41A.74.74,0,0,1,6.83,9H8.18a.73.73,0,0,1,.8.83v.66Z"/>
      <path d="M13.48,10.49c0,.24,0,.48,0,.72a.71.71,0,0,1-.75.78c-.48,0-1,0-1.46,0a.68.68,0,0,1-.74-.69,10.55,10.55,0,0,1,0-1.59A.7.7,0,0,1,11.3,9c.47,0,.94,0,1.4,0a.72.72,0,0,1,.78.82C13.49,10.05,13.48,10.27,13.48,10.49Z"/>
      <path d="M18,10.5c0,.23,0,.46,0,.69a.71.71,0,0,1-.76.8c-.47,0-1,0-1.43,0a.71.71,0,0,1-.77-.77,11.53,11.53,0,0,1,0-1.44A.73.73,0,0,1,15.84,9h1.32c.56,0,.81.27.83.83Z"/>
    </SvgIcon>
  );
}