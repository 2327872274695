import React from "react";
import { Box, Container, Grid, makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import AddressBox from "./sections/AddressBox";
import FormBox from "./sections/FormBox";
import HeadBox from "./sections/HeadBox";
import Helmet from "react-helmet";
import SocialBox from "./sections/social";

const useClasses = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(6),
  },
}));

const ContactUs = () => {
  const classes = useClasses();

  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>{t("route.contactUs.seo.title")} | universo corsi</title>

        <meta property="og:site_name" content="Universo Corsi" />

        <meta
          property="og:title"
          content={`${t("route.contactUs.seo.title")} | universo corsi`}
        />

        <meta property="og:url" content={window.location.href} />

        <link rel="canonical" href={`https://welevel.academy/contattaci`} />
      </Helmet>
      <Box className={classes.root}>
        <Container>
          <Grid container spacing={4}>
            {/* Title + img */}
            <Grid item xs={12} sm={12} md={6} className={classes.gridItem}>
              <HeadBox />
            </Grid>
            {/* Form  */}
            <Grid item xs={12} sm={12} md={6} className={classes.gridItem}>
              <FormBox />
            </Grid>
            {/* Address  */}
            <Grid item xs={12} sm={12} md={6} className={classes.gridItem}>
              <AddressBox />
            </Grid>
            {/* Social  */}
            <Grid item xs={12} sm={12} md={6} className={classes.gridItem}>
              <SocialBox />
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default ContactUs;
