import { LayoutIT } from "./layout";
import { GeneralIT } from "./general";
import { HomeIT } from "./routes/homepage";
import { EventIT } from "./routes/event";
import { EventsIT } from "./routes/events";
import { CheckoutIT } from "./checkout";
import { MeIT } from "./routes/me";
import { BillingIT } from "./routes/billing";
import { SettingsIT } from "./routes/settings";
import { UpsellBannerIT } from "./upsellBanner";
import { StreamIT } from "./routes/stream";
import { passwordResetIT } from "./routes/passwordReset";
import { PlanAssetIT } from "./planAsset";
import { Cards } from "./cards";
import { NavigatorIT } from "./navigator";
import { CourseBoxIT } from "./courseBox";
import { EmailConfirmIT } from "./routes/emailConfirm";
import { PasswordRecoveryIT } from "./passwordRecovery";
import { SignUpIT } from "./signUp";
import { LoginIT } from "./login";
import { CourseIT } from "./routes/course";
import { PlaylistIT } from "./routes/playlist";
import { LiveChatIT } from "./liveChat";
import { forgotPasswordIT } from "./routes/forgotPassword";
import { DashboardIT } from "./routes/dashboard";
import { VideoDropzoneIT } from "./videoDropzone";
import { SearchIT } from "./search";
import { DialogAddToPlaylistIT } from "./dialogAddToPlaylist";
import { QuestionBannerIT } from "./questionBanner";
import { PartnerIT } from "./routes/partner";
import { PartnersIT } from "./routes/partners";
import { SupportIT } from "./routes/support";
import { TeachersIT } from "./routes/teachers";
import { UserIT } from "./routes/user";
import { AskQuestionModalIT } from "./askQuestionModal";
import { YupLocaleIT } from "./yup";
import { ErrorsIT } from "./errors";
import { ContactUsIT } from "./routes/contactUs";
import { AvatarIT } from "./components/avatar";
import { CopyToClipboardIT } from "./components/copyToClipboard";
import { ImageDropzoneIT } from "./components/imageDropzone";
import { DropzoneIT } from "./components/dropzone";
import { ImageEditorIT } from "./components/imageEditor";
import { ReviewFormIT } from "./components/reviewForm";
import { ExploreIT } from "./routes/explore";
import { ErrorBoundaryIT } from "./components/errorBoundary";
import { LessonIT } from "./routes/lesson";
import { AcceptInvitationIT } from "./routes/acceptInvitation";
import { RequestConfirmEmailIT } from "./routes/requestConfirmationEmail";
import { completeSubscriptionFormIT } from "./fragments/completeSubscriptionForm";
import { TRANSLATIONS_EN } from "../en/translations";
import { CompleteRegistrationIT } from "./routes/completeRegistration";
import { ProfileLockedIT } from "./routes/profileLocked";

export const TRANSLATIONS_IT: typeof TRANSLATIONS_EN = {
  /* Global Strings used in multiple sections */
  general: GeneralIT,
  yup: YupLocaleIT,
  errors: ErrorsIT,
  layout: LayoutIT,
  login: LoginIT,
  signUp: SignUpIT,
  passwordRecovery: PasswordRecoveryIT,
  checkout: CheckoutIT,
  components: {
    avatar: AvatarIT,
    copyClipboard: CopyToClipboardIT,
    dropzone: DropzoneIT,
    errorBoundary: ErrorBoundaryIT,
    imageDropzone: ImageDropzoneIT,
    imageEditor: ImageEditorIT,
    reviewForm: ReviewFormIT,
  },
  fragments: {
    completeSubscriptionForm: completeSubscriptionFormIT,
  },
  route: {
    acceptInvitation: AcceptInvitationIT,
    completeRegistration: CompleteRegistrationIT,
    passwordReset: passwordResetIT,
    forgotPassword: forgotPasswordIT,
    emailConfirm: EmailConfirmIT,
    dashboard: DashboardIT,
    homepage: HomeIT,
    me: MeIT,
    explore: ExploreIT,
    billing: BillingIT,
    settings: SettingsIT,
    course: CourseIT,
    event: EventIT,
    events: EventsIT,
    playlist: PlaylistIT,
    profileLocked: ProfileLockedIT,
    stream: StreamIT,
    support: SupportIT,
    partner: PartnerIT,
    partners: PartnersIT,
    teachers: TeachersIT,
    user: UserIT,
    contactUs: ContactUsIT,
    lesson: LessonIT,
    requestConfirmationEmail: RequestConfirmEmailIT,
  },
  chat: LiveChatIT,
  upsellBanner: UpsellBannerIT,
  questionBanner: QuestionBannerIT,
  cards: Cards,
  navigator: NavigatorIT,
  courseBox: CourseBoxIT,
  plansAsset: PlanAssetIT,
  videoDropzone: VideoDropzoneIT,
  search: SearchIT,
  dialogAddToPlaylist: DialogAddToPlaylistIT,
  askQuestionModal: AskQuestionModalIT,
};
