import { ApolloError } from "@apollo/client";
import { FormikErrors } from "formik";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { useTranslation } from "react-i18next";

// TODO: check if this type is right
type YupError = {
  key: string | null;
  label: string;
  message: string;
  originalValue: string;
  path: string;
  spec: {
    abortEarly: boolean;
    coerce: boolean;
    label: string;
    nullable: boolean;
    optional: boolean;
    recursive: boolean;
    strict: boolean;
    strip: boolean;
  };
  type: string;
  value: string;
};

// This error code is used to identify Yup errors by the backend
export const YUP_ERROR_CODE = "BAD_USER_INPUT";

// This hooks is used to parse Yup errors to Formik errors
// It handles Yup errors and get the right translation that is usually store in the common package
// It also handles generic errors
export function useParseGqlYupErrorToFormik() {
  const { enqueueSnackbar } = useSnackbar();
  const { t, i18n } = useTranslation();

  const [yupErrors, setYupErrors] = useState<FormikErrors<any>>({});

  const getFormikError: (errors: ApolloError) => FormikErrors<any> = (
    errors
  ) => {
    // Handle array of GraphQLErrors
    errors.graphQLErrors.forEach((error) => {
      if (
        error.extensions?.code === YUP_ERROR_CODE &&
        error.extensions?.yup.errors
      ) {
        setYupErrors(
          error.extensions?.yup.errors.reduce(
            (acc: FormikErrors<any>, yupError: YupError) => {
              // If error key is present, return translated error
              if (yupError.key && i18n.exists(yupError.key)) {
                const { key, path, label, ...otherProps } = yupError;
                const translatedLabel = i18n.exists(label)
                  ? t(label as any)
                  : label;
                return {
                  ...acc,
                  [yupError.path]: t(key as any, {
                    path: translatedLabel ?? path,
                    ...otherProps,
                  }),
                };
              }
              // If error key is not present, return generic error
              return {
                ...acc,
                [yupError.path]: t("general.genericFieldError"),
              };
            },
            {}
          )
        );
      } else {
        // TODO: translate and make it more user friendly
        // TODO: pass a custom error message?
        enqueueSnackbar(`Error: ${errors.message}`, {
          variant: "error",
          autoHideDuration: 5000,
        });
      }
    });
    return yupErrors;
  };

  return { getFormikError };
}

// TODO: this is the old implementation, we should remove it (is not in this file)
// TODO: implement the path and prefix logic if is needed!

// function buildFieldString(key, { path, prefix, label, ...props }, { i18n, t }) {
//   if (label || i18n.exists(`${prefix}.${path}`)) {
//     label = t(label ? label : `${prefix}.${path}`);
//   }
//   return t(key, { path: label ? label : path, ...props });
// }

// export const YUP_ERROR_CODE = "BAD_USER_INPUT";

// export function parseGqlYupErrorToFormik(error, { path, prefix }, { i18n, t }) {
//   const errors = error.graphQLErrors || [];
//   return (
//     errors
//       .filter(({ extensions: { code, yup } }) => code === YUP_ERROR_CODE)
//       // TODO - Rebuild path in dot notation (reduce) and compare it to path
//       .filter(({ path: gqlPath }) => (path ? gqlPath[0] === path : true))
//       .map(
//         ({
//           extensions: {
//             yup: { errors },
//           },
//         }) => {
//           return errors;
//         }
//       )
//       .reduce((acc, el) => {
//         console.log(errors);
//         console.log(el);
//         return [...acc, ...el];
//       }, [])
//       .reduce((acc, el) => {
//         if (el.key && i18n.exists(el.key)) {
//           acc[el.path] = buildFieldString(
//             el.key,
//             { prefix, ...el },
//             { i18n, t }
//           );
//         } else {
//           acc[el.path] = el.message;
//         }
//         return acc;
//       }, {})
//   );
// }
