import {
  Button,
  Link,
  Paper,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { GalleryWall, LoadingImage } from "welevel-ui";
import styled from "styled-components";
import { Trans, useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";
import image01 from "../../assets/images/errorPage/404-01.webp";
import image02 from "../../assets/images/errorPage/404-02.webp";
import image03 from "../../assets/images/errorPage/404-03.webp";
import image04 from "../../assets/images/errorPage/404-04.webp";

export const ErrorPageWithoutStyle = () => (
  <div
    style={{
      padding: "32px 16px",
      maxWidth: "420px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      margin: "0 auto",
    }}>
    <div style={{ marginBottom: "72px" }}>
      <h1 style={{ marginBottom: "8px" }}>
        Qualcosa è andato storto o questa pagina non esiste
      </h1>
      <p style={{ marginBottom: "32px" }}>
        Se pensi sia un errore scrivi a{" "}
        <a href="mailto:ciccio@pasticcio.nessuno">ciccio@pasticcio.nessuno</a>
      </p>
      <p>
        <a
          style={{
            textAlign: "center",
            display: "block",
            padding: "8px 32px",
            backgroundColor: "rgba(0,0,0,0.87)",
            color: "rgba(255,255,255, 0.98)",
            fontWeight: 600,
            textDecoration: "none",
            borderRadius: "80px",
            margin: "0 auto",
          }}
          href="https://welevel.academy/explore">
          Trova altri contenuti
        </a>
      </p>
    </div>
    <div style={{ marginBottom: "72px" }}>
      <h1 style={{ marginBottom: "8px" }}>
        Something went wrong or the page doesn't exist
      </h1>
      <p style={{ marginBottom: "32px" }}>
        Reach out to our customer support at{" "}
        <a href="mailto:info@example.com">info@example.com</a>
      </p>
      <p>
        <a
          style={{
            textAlign: "center",
            display: "block",
            padding: "8px 32px",
            backgroundColor: "rgba(0,0,0,0.87)",
            color: "rgba(255,255,255, 0.98)",
            fontWeight: 600,
            textDecoration: "none",
            borderRadius: "80px",
            margin: "0 auto",
          }}
          href="https://welevel.academy/explore">
          Expolore new content
        </a>
      </p>
    </div>
  </div>
);

const Header = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: ${({ theme }) => theme.spacing(0, "auto", 1, "auto")};
  gap: ${({ theme }) => theme.spacing(4)}px;
  ${({ theme }) => theme.mixins.textBox}

  * {
    text-wrap: balance;
  }
`;

const MoreInfoWrapper = styled(Paper)`
  margin: ${({ theme }) => theme.spacing(6, "auto", 0, "auto")};
  padding: ${({ theme }) => theme.spacing(2, 2)};
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(1.5)}px;
`;

interface ErrorPageProperties {
  errorTitle?: string;
  errorDescription?: string;
}

export const ErrorPage: React.FC<ErrorPageProperties> = ({
  errorDescription,
  errorTitle,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const isXs = useMediaQuery(theme.breakpoints.down("xs"));
  const borderRadius = isXs
    ? theme.shape.borderRadius * 0.5
    : theme.shape.borderRadius * 1.5;

  function getRandomElement<T>(arr: T[]): T {
    const randomIndex = Math.floor(Math.random() * arr.length);
    return arr[randomIndex];
  }

  const images = [image01, image02, image03, image04];

  return (
    <GalleryWall
      xRotateMaxValue={32}
      containerProps={{
        maxWidth: "md",
        style: {
          minHeight: "100vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          paddingTop: `${theme.spacing(8)}px`,
          paddingBottom: `${theme.spacing(8)}px`,
        },
      }}
      beforeContent={
        <>
          <Header>
            <div>
              <Typography
                variant="h2"
                align="center"
                style={{ marginBottom: 16 }}>
                {t("components.errorBoundary.title")}
              </Typography>
              <Typography align="center">
                {t("components.errorBoundary.description")}
              </Typography>
            </div>
            {/* <Button variant="outlined" component="a" to="/explore"> */}
            <Button variant="outlined" component={RouterLink} to="/explore">
              {t("components.errorBoundary.cta")}
            </Button>
          </Header>
        </>
      }
      afterContent={
        <MoreInfoWrapper>
          <Typography variant="body2" component="p">
            <Trans
              i18nKey="components.errorBoundary.moreInfo"
              components={{
                a: <Link component="a" underline={"hover"} color="inherit" />,
              }}
            />
          </Typography>
          <div>
            {errorTitle && (
              <Typography component="p" variant="h5">
                {errorTitle}
              </Typography>
            )}
            {errorDescription && (
              <Typography component="p" variant="body2">
                {errorDescription}
              </Typography>
            )}
          </div>
        </MoreInfoWrapper>
      }
      frames={[
        {
          key: "big",
          width: isXs ? "90%" : "80%",
          aspectRatio: 16 / 9,
          top: isXs ? "5%" : "10%",
          left: isXs ? "5%" : "10%",
          position: "relative",
          frameProps: {
            style: {
              borderRadius: borderRadius,
              overflow: "hidden",
            },
          },
          children: <LoadingImage imageUrl={getRandomElement(images)} />,
        },
        // {
        //   key: "top-left",
        //   children: <LoadingImage imageUrl={image01} />,
        //   width: isXs ? "30%" : "25%",
        //   position: "absolute",
        //   top: "35%",
        //   left: "0%",
        //   zIndex: 2,
        //   frameProps: {
        //     style: {
        //       borderRadius: borderRadius,
        //       overflow: "hidden",
        //     },
        //   },
        // },
        // {
        //   key: "bottom-right",
        //   children: <LoadingImage imageUrl={image04} />,
        //   width: isXs ? "40%" : "30%",
        //   position: "absolute",
        //   bottom: "0%",
        //   top: "auto",
        //   left: "auto",
        //   right: "20%",
        //   zIndex: 2,
        //   frameProps: {
        //     style: {
        //       borderRadius: borderRadius,
        //       overflow: "hidden",
        //     },
        //   },
        // },
        // {
        //   key: "top-right",
        //   children: <LoadingImage imageUrl={image02} />,
        //   width: isXs ? "30%" : "25%",
        //   position: "absolute",
        //   top: isXs ? "0%" : "2.5%",
        //   left: "auto",
        //   right: isXs ? "0%" : "2.5%",
        //   zIndex: 2,
        //   frameProps: {
        //     style: {
        //       borderRadius: borderRadius,
        //       overflow: "hidden",
        //     },
        //   },
        // },
      ]}></GalleryWall>
  );
};
