import React, { useEffect, useMemo } from "react";
import { Navigate, Outlet, useLocation } from "react-router";
import { useAuth } from "../AuthProvider";
import { useGTMDispatch } from "@elgorditosalsero/react-gtm-hook";

const TeacherGatingRoute = ({ ...rest }) => {
  let { pathname } = useLocation();

  const { user } = useAuth();
  // const sendDataToGTM = useGTMDispatch();

  const gateResult = useMemo(() => {
    if (!user.isTeacher) {
      return {
        redirectPath: "/me",
      };
    }
    return null;
  }, [pathname, user]);

  // useEffect(() => {
  //   sendDataToGTM({
  //     user_id: user?.id || null,
  //   });
  // }, [user]);

  if (gateResult) {
    const { redirectPath } = gateResult;
    return <Navigate to={redirectPath} state={{ from: pathname }} replace />;
  }
  return <Outlet />;
};

export default TeacherGatingRoute;
