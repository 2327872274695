export const MeIT = {
  seo: {
    title: "Dashboard | universo corsi",
  },

  toolbar: {
    title: "Ciao, {{firstName}}",
    settings: "Impostazioni",
  },

  improveBanner: {
    askOpinion: "Che giudizio complessivo daresti a welevel.academy?",
    surveyButton: "Rispondi al questionario",
  },

  widgets: {
    section: {
      title: {
        authed: ", ecco il tuo Pannello di controllo",
        unAuthed: "Pannello di controllo",
      },
    },
    continue: {
      continueCourse: "Riprendi il corso",
    },
    subscription: {
      subscribeBanner: "<b>Abbonati</b> alla migliore formazione del settore",
    },
    competenceBanner: {
      skillLevels: "Livelli competenze",
    },
    corporateBanner: {
      addPartner: "Aggiungi un collaboratore...",
      theTeam: "IL TEAM: ",
      forCompanies: "Per le aziende",
      trainYourStaff: "Forma il tuo staff",
    },
    competenceLeaderboard: {
      leaderboard: "Classifica",
    },
    studyPlanBanner: {
      title: "FORMAZIONE CERTIFICATA",
      certifiedTrainingPath: "Percorsi formativi certificati",
      description:
        "Non sai da dove iniziare? Scegli uno dei nostri percorsi formativi!",
    },
    explore: {
      title: "ESPLORA",
      certifiedTrainingPath: "I nostri corsi",
      description:
        "Non sai da dove iniziare? Scegli uno dei nostri corsi formativi!",
    },
    liveBanner: {
      title: "Eventi e lezioni LIVE",
      todayAt: "Oggi ore {{time}}",
      booked: "Ti aspettiamo in live!",
      book: "Non perdere questo evento!",
    },
    courseBanner: {
      findCourse: "Trova un corso",
      startLearningNow: "Inizia subito <br />ad imparare",
    },
    teacher: {
      teacher: "Docente",
      teacherDashboard: "Dashboard Docente",
    },
    marketingOnboarding: {
      chooseYourPath: "<b>Personalizza il tuo percorso</b>",
      suggestions: "Ti suggeriremo i corsi più adatti a te",
    },
  },
  studyPlanAndPlaylists: {
    section: { title: "Percorsi certificati e personalizzati" },
    choosePath: "Scegli percorso certificato",
    createCustomizedPath: "Crea percorso personalizzato",
    tipsList: [
      "<b>Non sai da dove iniziare?</b> <br />I percorsi formativi certificati welevel sono strutturati per aiutarti a scegliere i contenuti adatti a migliorare le tue competenze",
      "<b>Troppi corsi interessanti e poco tempo per seguirli? </b> <br /> Organizza i corsi che più ti interessano in delle raccolte per recuperarli quando e dove vuoi!",
    ],
  },
  history: {
    title: "Hai visto di recente",
    noRecentlyWatchedCourse: "Nessun corso visto di recente. ",
    discoverCourses:
      "Scopri i <url><bold>nostri corsi</bold></url> e inizia subito ad imparare",
  },
  purchases: {
    title: "I tuoi acquisti",
    showMore: "Vedi altro",
    noTransactions: "Nessun acquisto recente",
  },
};
