import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import PaymentIcon from "@material-ui/icons/Payment";
import PersonIcon from "@material-ui/icons/Person";
import SettingsIcon from "@material-ui/icons/Settings";
import VisibilityIcon from "@material-ui/icons/Visibility";
import React, { useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import HelpRounded from "@material-ui/icons/HelpRounded";
import DropdownMenu from "../DropDownMenu/DropdownMenu";
import { useTranslation } from "react-i18next";
import i18n from "i18next";

export default function UserMenu({ handleClose, anchorEl, user }) {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const { t } = useTranslation();

  function isUserActive(user) {
    return (
      user &&
      !(
        user.subscriptionStatus === "SOCIAL_REGISTERED" ||
        !user.authHasConfirmedEmail
      )
    );
  }

  const userMenuItems = [
    {
      icon: () => <PersonIcon />,
      label: t("layout.navbar.userMenu.me"),
      to: "/me",
      value: "/me",
      tooltip: ({ disabled }) => {
        if (disabled) return t("layout.navbar.userMenu.lockedTooltip");
        return null;
      },
      disabled: (user) => !isUserActive(user),
    },
    {
      icon: () => <VisibilityIcon />,
      label: t("layout.navbar.userMenu.profile"),
      to: `/teacher/${user.id}`,
      value: `/teacher/${user.id}`,
      disabled: (user) => !isUserActive(user),
      hidden: (user) => {
        return !user.isTeacher;
      },
    },
    {
      icon: () => <PaymentIcon />,
      label: t("layout.navbar.userMenu.billing"),
      to: "/billing",
      value: "/billing",
      tooltip: ({ disabled }) => {
        if (disabled) return t("layout.navbar.userMenu.lockedTooltip");
        return null;
      },
      disabled: (user) => !isUserActive(user),
    },
    {
      icon: () => <SettingsIcon />,
      label: t(t("layout.navbar.userMenu.settings")),
      to: "/settings",
      value: "/settings",
      tooltip: ({ disabled }) => {
        if (disabled) return t("layout.navbar.userMenu.lockedTooltip");
        return null;
      },
      disabled: (user) => !isUserActive(user),
    },
    {
      icon: () => <HelpRounded />,
      label: t(t("layout.navbar.userMenu.help")),
      to: "/support",
      value: "/support",
    },
    {
      icon: () => <ExitToAppIcon />,
      label: t(t("layout.navbar.userMenu.logout")),
      onClick: async () => {
        navigate("/", { state: { logoff: true } });
      },
    },
  ];

  const menuItems = useMemo(() => {
    return userMenuItems
      .filter(({ hidden }) => {
        if (hidden) {
          return !(typeof hidden === "function" ? hidden(user) : hidden);
        }
        return true;
      })
      .map(({ disabled, ...el }) => {
        return {
          ...el,
          disabled: typeof disabled === "function" ? disabled(user) : disabled,
        };
      });
  }, [user, i18n.language]);

  return (
    <DropdownMenu
      items={menuItems ?? []}
      onClose={handleClose}
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      value={pathname}
      keepMounted
      variant="color"
    />
  );
}
