export const PlanAssetIT = [
  {
    abstract:
      "Per professionisti, per chi vuole lavorare nel turismo e nell'ospitalità",
    description: "Il meglio della formazione welevel",
    cta: "Scegli",
    features: [
      {
        name: "Accesso illimitato a tutta la formazione",
      },
      {
        name: "Nuovi video corsi ogni mese",
      },
      {
        name: "Aggiornamenti dei corsi già pubblicati",
      },
      {
        name: "Segui i corsi da PC, Mobile e Tablet",
      },
      {
        name: "Scarica le slide e i materiali extra dei corsi",
      },
      {
        name: "Crea percorsi formativi personalizzati",
      },
      {
        name: "Controlla lo stato di avanzamento dei tuoi percorsi formativi",
      },
      {
        name: "Certificazione dei percorsi formativi completati",
      },
      {
        name: "Le masterclass dei migliori formatori",
      },
      {
        name: "Accesso illimitato a lezioni ed eventi live",
      },
    ],
  },
  {
    abstract: "Per gestori, imprenditori e chi vuole formare il proprio staff",
    description: "Pensato per le aziende",
    cta: "Scopri di più",
    features: [
      {
        name: "Accesso illimitato a tutta la formazione",
      },
      {
        name: "Nuovi video corsi ogni settimana",
      },
      {
        name: "Aggiornamenti dei corsi già pubblicati",
      },
      {
        name: "Segui i corsi da PC, Mobile e Tablet",
      },
      {
        name: "Scarica le slide e i materiali extra dei corsi",
      },
      {
        name: "Crea percorsi formativi personalizzati",
      },
      {
        name: "Controlla lo stato di avanzamento dei tuoi percorsi formativi",
      },
      {
        name: "Certificazione dei percorsi formativi completati",
      },
      {
        name: "Le masterclass dei migliori formatori",
      },
      {
        name: "Accesso illimitato a lezioni ed eventi live",
      },
    ],
    adminFeatures: [
      {
        name: "Scegli quali corsi far seguire ai tuoi collaboratori",
      },
      {
        name:
          "Controlli l’avanzamento della preparazione del tuo staff grazie a una dashboard che vedi solo tu",
      },
      {
        name: "Verifichi periodicamente i miglioramenti di ogni collaboratore",
      },
      {
        name:
          "Dashboard di controllo per monitorare i progressi dei tuoi collaboratori",
      },
      {
        name: "Consulente personalizzato per creare i percorsi formativi",
      },
    ],
  },
  {
    abstract:
      "Per professionisti, per chi vuole lavorare nel turismo e nell'ospitalità",
    description: "Il meglio della formazione welevel",
    cta: "Scegli",
    features: [
      {
        name: "Accesso illimitato a tutta la formazione",
      },
      {
        name: "Nuovi video corsi ogni settimana",
      },
      {
        name: "Aggiornamenti dei corsi già pubblicati",
      },
      {
        name: "Segui i corsi da PC, Mobile e Tablet",
      },
      {
        name: "Scarica le slide e i materiali extra dei corsi",
      },
      {
        name: "Crea percorsi formativi personalizzati",
      },
      {
        name: "Controlla lo stato di avanzamento dei tuoi percorsi formativi",
      },
      {
        name: "Certificazione dei percorsi formativi completati",
      },
      {
        name: "Le masterclass dei migliori formatori",
      },
      {
        name: "Accesso illimitato a lezioni ed eventi live",
      },
    ],
  },
  {
    abstract:
      "Per professionisti, per chi vuole lavorare nel turismo e nell'ospitalità",
    description: "Per cominciare al meglio",
    cta: "Scegli",
    features: [
      { name: "Accesso illimitato a tutta la formazione" },
      { name: "Nuovi video corsi ogni settimana" },
      { name: "Aggiornamenti dei corsi già pubblicati" },
      { name: "Segui i corsi da PC, Mobile e Tablet" },
    ],
  },
  {
    abstract: "Per conoscerci",
    description: "Per conoscerci",
    cta: "Scopri di più",
    features: [
      {
        name: "Scopri contenuti gratuiti come eventi e interviste",
      },
      {
        name: "Visualizza le anteprime dei corsi",
      },
    ],
  },
];
