export const DialogAddToPlaylistIT = {
  title: "Assegna il corso...",
  sectionNew: {
    title: "Crea una nuova playlist",
    form: "Nome playlist",
  },
  sectionTeam: {
    title: "Scegli un membro del tuo Team",
    noTeam: "Nessun membro nel tuo team!"
  },
  sectionPersonalAgency: {
    personal: "A me",
    agency: "Al mio staff",
  },
  sectionStudyplan: {
    title: "Scegli una playlist esistente",
    deleteTooltip: "Elimina percorso",
    noTeam: "No members in your team!"
  },
  snackbar: {
    addToEmployee: {
      success: "Corso aggiunto con successo",
      error: "Errore nell'aggiunta del corso",
    },
    addToUser: {
      success: "Corso aggiunto con successo",
      error: "Errore nell'aggiunta del corso",
    },
    deletePlaylist: {
      success: "Percorso rimosso con successo",
      error: "Errore nella rimozione del percorso",
    },
  },
};
