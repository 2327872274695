export const SupportEN = {
  seo: {
    title: "Support",
  },
  header: {
    title: "Common questions",
    description: `Get answers to top questions on Welevel or reach out to our customer support at <a href="mailto:info@example.com">info@example.com</a>`,
  },
  search: {
    label: "Search",
    placeholder: "Search by keywords...",
  },
  faqs: [
    {
      id: "1",
      question: "What courses can you find on Universo Corsi?",
      answer: `The Universo Corsi courses are aimed at those who work or want to work in the tourism and hospitality sector. 
                They are on-demand online video courses that you can watch wherever and whenever you want from any device: tablet, smartphone, PC. 
                The courses consist of a series of videos with an average duration of 6-7 minutes each which can be viewed several times. The topics covered are marketing and communication, front office and hospitality, food & beverage, revenue management, leadership and human resources development and more...`,
    },
    {
      id: "2",
      question: "Are the course slides downloadable?",
      answer: `Yes, the course slides are downloadable.<br /> You can find them on the course page, in the side card, by clicking on the "Download Slide" button. The slides will be downloaded in pdf format.`,
    },
    {
      id: "3",
      question: "Are the courses certified?",
      answer: `If you complete one of the studyplans and pass the final test, you can request your certificate of participation by writing to <a href="mailto:info@example.com">info@example.com</a>`,
    },
    {
      id: "5",
      question: "How much does the subscription cost?",
      answer: `
      The cost of the subscription depends on the duration of the subscription.<br />
      For information on the costs of the subscription, consult the page <a href="https://welevel.academy/abbonamento" target="_blank">welevel.academy/abbonamento</a><br /> 
      `,
    },
    {
      id: "6",
      question: "What payment methods can I use?",
      answer: `You can pay directly from the site by credit card or PayPal.<br />`,
    },
    {
      id: "7",
      question: "Can I give a Welevel subscription as a gift?",
      answer: `Yes, you can gift a Welevel membership.<br />
      We'll send you a custom card containing the instructions for redeeming the gift.`,
    },
    {
      id: "9",
      question: "Can I request an invoice?",
      answer: `Yes, you can request an invoice.<br />
      You can either enter the billing data at the end of the purchase procedure, or send an email to <a href="mailto:fatturazione@example.com">fatturazione@example.com</a>`,
    },
    {
      id: "10",
      question: "Can I change the password?",
      answer: `Yes, you can change your password.<br />
      To change your password, click on "forgot password" when you log in. You will receive an email in your inbox which will allow you to set a new password.`,
    },
    {
      id: "11",
      question: "Where can I find the courses assigned to me by my manager?",
      answer: `Once logged in with your credentials, scroll down your profile page.<br />
      In the "Studyplans" section you will find the course that has been assigned to you. Click on the studyplan card to start following the courses.`,
    },
  ],
  noResults: {
    id: "no-results",
    question: "No results found :(",
    answer: `If you don't find the answer you are looking for, contact our support team, write to <a href="mailto:info@example.com">info@example.com</a>`,
  },
};
