import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`

  html {
    --webkit-text-size-adjust: auto;
  }
  
  body {
    *::selection {
        background-color: rgba(255, 0, 0, .84);
        color:#fff;
    }
  }

  hr {
    border-color: rgba(0,0,0,.08);
  }
`;
