import React, { useState } from "react";
import copy from "copy-to-clipboard";
import Link from "@material-ui/core/Link";
import { withStyles } from "@material-ui/core";
import { useSnackbar } from "notistack";
import { Trans, useTranslation } from "react-i18next";
import Popover from "@material-ui/core/Popover";
import clsx from "clsx";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";
import Typography from "@material-ui/core/Typography";
import { colors } from "../style/colors";

export const styles = (theme) => ({
  root: {},
  link: {
    "&:hover": {
      cursor: "pointer",
    },
  },
  popover: {
    pointerEvents: "none",
  },
  paper: {
    padding: theme.spacing(1),
    color: colors.textWhitePrimary,
    backgroundColor: colors.darkBackground,
  },
  typography: {
    color: "inherit",
    display: "inline-flex",
    "& svg": {
      marginRight: theme.spacing(3 / 4),
    },
  },
});

const CopyToClipboard = React.forwardRef(function OutlinedInput(props, ref) {
  const { text, onCopy, options, children, className, classes, ...other } =
    props;

  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [anchorEl, setAnchorEl] = useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event) => {
    const result = copy(text, options);

    if (onCopy) {
      onCopy(text, result);
    } else {
      enqueueSnackbar(t("components.copyClipboard.success"), {
        variant: "info",
      });
    }
  };

  const open = Boolean(anchorEl);

  return (
    <>
      <Link
        ref={ref}
        className={clsx(classes.root, className)}
        onClick={handleClick}
        color="inherit"
        underline={"hover"}
        className={classes.link}
        aria-owns={open ? "mouse-over-popover" : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
        {...other}>
        {children}
      </Link>
      <Popover
        className={classes.popover}
        classes={{
          paper: classes.paper,
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus>
        <Typography className={classes.typography}>
          <FileCopyOutlinedIcon />
          <Trans i18nKey={"components.copyClipboard.description"} />
        </Typography>
      </Popover>
    </>
  );
});

export default withStyles(styles, { name: "CopyToClipboard" })(CopyToClipboard);
