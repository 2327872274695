// render
import React, { StrictMode } from "react";
import { render } from "react-dom";
import i18n from "i18next";
// providers
import { GTMProvider } from "@elgorditosalsero/react-gtm-hook";
import { ApolloProvider } from "@apollo/client";
import { SnackbarProvider } from "notistack";
import { Provider } from "outstated";
import { AuthProvider } from "./AuthProvider";
import { IubendaCSProvider } from "./helpers/iubenda/ConsentManagerProvider";
import YupProvider from "./helpers/yupProvider";
import Moment from "@material-ui/pickers/adapter/moment";
import { LocalizationProvider } from "@material-ui/pickers";
// style
import { CssBaseline, withWidth } from "@material-ui/core";
import { createWelevelUITheme, WelevelUIThemeProvider } from "welevel-ui";
import { colors } from "./style/colors";
import GlobalStyle from "./styles/global";
import { client } from "./Apollo";
// routing
import Routes from "./routes";
// stores
import store from "./store";
import AgencyConvertionStore from "./store/AgencyConvertion";
import LoginStore from "./store/LoginStore";
import PlaylistsDialogStore from "./store/PlaylistsDialogStore";
import ErrorBoundary from "./components/ErrorBoundary";
import { ErrorPageWithoutStyle } from "./components/ErrorBoundary/ErrorPages";

const theme = createWelevelUITheme({
  type: "light",
  customLightTheme: {
    palette: {
      primary: {
        light: colors.primaryLight,
        main: colors.primary,
        dark: colors.primaryDark,
        contrastText: colors.textWhitePrimary,
      },
    },
  },
});

const Application = () => (
  <ErrorBoundary customError={<ErrorPageWithoutStyle />}>
    <WelevelUIThemeProvider theme={theme}>
      <CssBaseline />
      <GlobalStyle />
      <LocalizationProvider dateAdapter={Moment}>
        <YupProvider prefix={"general"}>
          <ApolloProvider client={client}>
            <AuthProvider client={client}>
              <Provider
                stores={[
                  store,
                  LoginStore,
                  AgencyConvertionStore,
                  PlaylistsDialogStore,
                ]}>
                <SnackbarProvider>
                  <ErrorBoundary>
                    <Routes />
                  </ErrorBoundary>
                </SnackbarProvider>
              </Provider>
            </AuthProvider>
          </ApolloProvider>
        </YupProvider>
      </LocalizationProvider>
    </WelevelUIThemeProvider>
  </ErrorBoundary>
);

const EnhancedApplication = withWidth()(Application);

const root = document.getElementById("root");

if (root !== null)
  render(
    <StrictMode>
      <EnhancedApplication />
    </StrictMode>,
    root
  );

//serviceWorker.unregister();
