import { GeneralEN } from "../en/general";

export const GeneralIT: typeof GeneralEN = {
  // Actions
  add: "Aggiungi",
  active: "Attivo",
  back: "Indietro",
  cancel: "Annulla",
  close: "Chiudi",
  confirm: "Conferma",
  create: "Crea",
  date: "Data",
  datetime: "Data e Ora",
  default: "Predefinito",
  free: "Gratis",
  login: "Accedi",
  loginOrSubscribe: "Accedi o Iscriviti",
  logout: "Esci",
  new: "Nuova",
  of: "Di",
  or: "Oppure",
  price: "Prezzo",
  register: "Registrati",
  remove: "Rimuovi",
  reopen: "Ri-apri",
  save: "Salva",
  search: "Cerca",
  send: "Invia",
  share: "Condividi",
  shareBy: "da welevel.academy!",
  status: "Stato",
  subscribe: "Iscriviti",
  subscribe_plan: "Abbonati",
  sponsoredBy: "Sponsorizzato da",
  sponsor: "Sponsor",
  settings: "Impostazioni",
  time: "Ora",
  update: "Aggiorna",
  upload: "Carica",

  // Misc
  mostRecents: "Le più recenti",
  recents: "Ultime",
  showMore: "Vedi altri",

  // Forms
  address: "Indirizzo",
  address1: "Indirizzo 1",
  address2: "Indirizzo 2",
  birthPlace: "Luogo di nascita",
  birthday: "Compleanno",
  captcha: "Captcha",
  city: "Città",
  email: "Email",
  emailConfirm: "Conferma Email",
  fiscalCode: "Codice fiscale",
  firstName: "Nome",
  genericFieldError: "Questo campo non è valido",
  lastName: "Cognome",
  marketingAgreementLabel:
    "Acconsento al trattamento dati per finalità di marketing diretto.",
  message: "Messaggio",
  password: "Password",
  passwordConfirm: "Conferma Password",
  phone: "Telefono",
  privacy: "Privacy",
  privacyAgreementLabel:
    "Acconsento al trattamento dati per elaborazione automatica dei dati, per finalità statistiche e per miglioramento del servizio.",
  privacyPolicy:
    "Ho letto l'informativa e acconsento al trattamento dei dati per le finalità previste dall'<url>informativa sulla privacy</url>.",
  subject: "Oggetto",
  termsOfServiceLabel: "Condizioni di utilizzo",
  username: "Username",
  vatCode: "P.IVA",
  zipCode: "CAP",

  // Add pluralization
  account_one: "Account",
  account_other: "Account",

  answer_one: "Risposta",
  answer_other: "Risposte",

  author_one: "Autore",
  author_other: "Autori",

  course_one: "Corso",
  course_other: "Corsi",

  event_one: "Evento",
  event_other: "Eventi",

  partner_one: "Partner",
  partner_other: "Partners",

  payment_one: "Pagamento",
  payment_other: "Pagamenti",

  plan_one: "Piano",
  plan_other: "Piani",

  purchase_one: "Acquisto",
  purchase_other: "Acquisti",

  subscription_one: "Abbonamento",
  subscription_other: "Abbonamenti",

  teacher_one: "Docente",
  teacher_other: "Docenti",
};
