import { Box, Typography } from "@material-ui/core";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import { ErrorMessage, Field } from "formik";
import React from "react";
import { colors } from "../../../../style/colors";
import styled from "styled-components";

export const CheckLabel = styled.label`
  display: block;
  margin-bottom: 4px;
  cursor: pointer;
`;

const StyledCheckbox = ({
  label,
  value,
  name,
  checked,
  onChange,
  color,
  colorDisabled,
  required,
  disabled,
}) => {
  if (!color) color = colors.textPrimary;

  if (!colorDisabled) colorDisabled = colors.textPrimary;

  return (
    <CheckLabel>
      <Box display="flex" alignItems="center" position="relative">
        <Box
          flex="0 0 auto"
          marginRight={1}
          display="flex"
          alignItems="center"
          position="relative">
          <Field
            disabled={disabled}
            required={required}
            style={{ height: 0, width: 0, opacity: 0, margin: 0 }}
            type="checkbox"
            name={name}
            value={value}
            checked={checked}
            onChange={() => onChange(name, value)}
          />
          {checked ? (
            <CheckBoxIcon
              style={{
                color: disabled ? colorDisabled : color,
              }}
            />
          ) : (
            <CheckBoxOutlineBlankIcon
              style={{
                color: disabled ? colorDisabled : color,
              }}
            />
          )}
        </Box>
        <Box flex="1 1 auto">
          <Typography style={{ color: color }}>{label}</Typography>
        </Box>
      </Box>
    </CheckLabel>
  );
};

export default StyledCheckbox;
