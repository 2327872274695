import React from "react";
import { Box, makeStyles, Typography } from "@material-ui/core";
import clsx from "clsx";
import { Trans } from "react-i18next";
import SocialIconsBar from "../../../components/SocialIconsBar";
import { colors } from "../../../style/colors";

const useClasses = makeStyles((theme) => ({
  root: {},
  title: {
    marginBottom: theme.spacing(2),
  },
  socialBar: {
    color: colors.textPrimary,
    justifyContent: "left",
  },
}));

const SocialBox = ({ className, ...props }) => {
  const classes = useClasses();

  return (
    <Box className={clsx(classes.root, className)} {...props}>
      <Typography component="h2" variant="h3" className={classes.title}>
        <Trans i18nKey="route.contactUs.social.title" />
      </Typography>
      <SocialIconsBar className={classes.socialBar} />
    </Box>
  );
};

export default SocialBox;
