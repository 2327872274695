export const CourseIT = {
  ticket: {
    teacherOf: "Docente del corso",

    vat: "IVA Inclusa",
    bought: "Hai accesso a questo corso!",
    unlimited: "Incluso con Welevel Unlimited",

    views: "{{views}} visualizzazioni",
    views_few: "< 1000 visualizzazioni",

    duration_one: "{{count}} lezione · {{duration}}",
    duration_other: "{{count}} lezioni · {{duration}}",

    rating_one: "{{rating}}",
    rating_other: "{{rating}} su {{count}} recensioni",

    exclusive: "Esclusiva Universo Corsi",
    exclusiveInfo:
      "Questo corso lo trovi solo su Universo Corsi e su nessun altro sito",

    difficulties: {
      advanced: "Corso avanzato",
      base: "Corso base",
      masterclass: "Masterclass",
    },

    buttons: {
      playlist: "Salva",
      playlistTooltip: "Aggiungi ad un percorso personalizzato",

      slide: "Scarica Slide",
      slideTooltip: "Scarica le slide del corso",
    },
  },

  watch: {
    firstLesson: "Vai alla prima lezione",
    studyPlan: "Vai al percorso",
    continueMasterclass: "Riprendi la masterclass",
    continueCourse: "Riprendi il corso",
    playlist: {
      percentage: "<s>{{percentage}}%</s> completato",
      certificate:
        "Questo corso fa parte di un percorso certificato da welevel.",
    },
  },

  checkout: {
    title: "Acquista Corso",
    subscription: "Guarda tutti i corsi Welevel - Abbonati",
    buy: "Acquista questo corso a {{amount}}",
    free: "Guarda ora - Accedi/Registrati",
  },

  description: {
    title: "Descrizione",
  },

  lessons: {
    title: "Lezioni",
  },

  reviews: {
    title: "Recensioni",
    button: "Vedi tutte",
  },

  author: {
    title: "Docente",
  },

  neighbors: {
    topic: "Corsi più recenti in {{topic}}",
    recent: "Corsi in {{topic}}",
    button: "Vedi tutti i corsi in {{topic}}",
  },
};
