export const TeachersIT = {
  seo: {
    title: "I nostri formatori",
  },
  header: {
    title: "Ti presentiamo i nostri formatori",
    description:
      "Abbiamo unito le nostre conoscenze di docenti esperti, la professionalita di consulenti e formatori specializzati e l'esperienza di professionisti allo scopo di mettere il loro sapere a disposizione di tutti.",
  },
};
