export const Cards = {
  event: {
    pro: "unlimited",
    reserved: "Riservato",
    free: "Gratis",
    premium: "Extra",
    liveNow: "LIVE ora",
    rewatchEvent: "Rivedi l'evento",
  },
};
