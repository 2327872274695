import gql from "graphql-tag";

export const topicsQuery = gql`
  query topicsWithoutImage {
    topics {
      id
      slug
      name
      color
    }
  }
`;
