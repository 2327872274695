export const SupportIT = {
  seo: {
    title: "Supporto",
  },
  header: {
    title: "Domande frequenti",
    description: `Trova le risposte alle domande più frequenti su Welevel, o scrivi al nostro team di assistenza <a href="mailto:info@example.com">info@example.com</a>`,
  },
  search: {
    label: "Cerca",
    placeholder: "Cerca per parole chiave...",
  },
  faqs: [
    {
      id: "1",
      question: "Che corsi ci sono su Universo Corsi?",
      answer: `I corsi di Universo Corsi sono rivolti a chi lavora o desidera lavorare nel settore del turismo e dell’ospitalità.
            Sono video corsi online on demand che puoi guardare dove vuoi e quando vuoi da qualsiasi dispositivo: tablet, smartphone, pc.
            I corsi sono composti da un serie di video con una durata media di 6-7 minuti ciascuno che possono essere rivisti più volte.
            Gli argomenti trattati sono marketing e comunicazione, front office e accoglienza, food&beverage, revenue management, leadership e human resources development e altri.`,
    },
    {
      id: "2",
      question: "Le slide dei corsi sono scaricabili?",
      answer: `Sì, le slide dei corsi sono scaricabili.<br />
            Le trovi nella pagina di presentazione del corso, nella side card del corso, cliccando sul tasto “Scarica Slide”. Le slide verranno scaricate in formato pdf.`,
    },
    {
      id: "3",
      question: "I corsi sono certificati?",
      answer: `Se completi al 100% uno dei percorsi formativi e superi il test finale, puoi richiedere il tuo attestato di partecipazione scrivendo a <a href="mailto:info@example.com">info@example.com</a>`,
    },
    {
      id: "5",
      question: "Quanto costa l’abbonamento?",
      answer: `
                Il costo dell’abbonamento dipende dalla durata dell’abbonamento.
                Per informazioni sui costi dell’abbonamento consulta la pagina <a href="https://welevel.academy/abbonamento" target="_blank">welevel.academy/abbonamento</a>
            `,
    },
    {
      id: "6",
      question: "Quali metodi di pagamento posso usare?",
      answer: `Puoi pagare direttamente dal sito con carta di credito o PayPal.<br />`,
    },
    {
      id: "7",
      question: "Posso regalare un abbonamento a Welevel?",
      answer: `Sì, puoi regalare un abbonamento a Welevel.<br />
      Ti invieremo un biglietto personalizzato con le istruzioni per usufruire del regalo.`,
    },
    {
      id: "9",
      question: "Posso richiedere fattura?",
      answer: `Sì, puoi richiedere fattura. Al termine  della procedura di acquisto potrai inserire i dati per la fatturazione oppure inviare un’email a <a href="mailto:fatturazione@example.com">fatturazione@example.com</a>`,
    },
    {
      id: "10",
      question: "Posso cambiare la password?",
      answer: `Sì, puoi cambiare la password.<br />
      Per cambiare la password, clicca su “password dimenticata” quando fai il login. Riceverai un’email nella tua casella di posta elettronica che ti permetterà di impostare una nuova  password.
      `,
    },
    {
      id: "11",
      question:
        "Dove trovo i corsi che mi sono stati assegnati dal mio responsabile?",
      answer: `Una volta effettuato il login con le tue credenziali, scorri la pagina del tuo profilo verso il basso.<br />
      Nella sezione “percorsi formativi” troverai il percorso formativo che ti è stato assegnato. Clicca sulla card del percorso formativo per iniziare a seguire  i corsi.`,
    },
  ],
  noResults: {
    id: "no-results",
    question: "Mi dispiace, non ho trovato nulla :(",
    answer: `Se non trovi la risposta che stai cercando contatta il nostro team di assistenza, scrivi a <a href="mailto:info@example.com">info@example.com</a>`,
  },
};
