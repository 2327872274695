import React, { useEffect, useState } from "react";
import { Box, darken, lighten, withStyles } from "@material-ui/core";
import Alert from "../../../Alert";
import AlertTitle from "../../../Alert/AlertTitle";
import clsx from "clsx";
import { Trans } from "react-i18next";
import { useNavigate } from "react-router";

export const styles = (theme) => {
  const getColor = theme.palette.type === "light" ? darken : lighten;

  return {
    /* Styles applied to the root element. */
    root: {},
    message: {
      cursor: "pointer",
    },
    title: {
      color: getColor(theme.palette.error.main, 0.6),
    },
  };
};

const AuthAlerts = React.forwardRef(function ConfirmEmailAlert(props, ref) {
  const { classes, className, user, ...other } = props;

  const navigate = useNavigate();

  const [openEmail, setOpenEmail] = useState(false);
  const [openPassword, setOpenPassword] = useState(false);

  useEffect(() => {
    if (user) setOpenEmail(!user.authHasConfirmedEmail);
    if (user) setOpenPassword(!user.authHasPassword);
  }, [user]);

  const handleClickEmail = () => {
    navigate("/send-confirm-email");
  };

  const handleClickComplete = () => {
    navigate("/register/complete");
  };

  if (!user) return null;

  return (
    <>
      <Alert
        open={openEmail}
        onClose={() => setOpenEmail(false)}
        severity="error"
        ref={ref}
        className={clsx(classes.root, className)}
        {...other}>
        <Box className={classes.message} onClick={handleClickEmail}>
          <AlertTitle className={classes.title}>
            <Trans i18nKey="layout.authAlert.email.title" />
          </AlertTitle>
          <Trans
            i18nKey="layout.authAlert.email.body"
            components={{ strong: <strong /> }}
          />
        </Box>
      </Alert>

      <Alert
        open={openPassword}
        onClose={() => setOpenPassword(false)}
        severity="warning"
        ref={ref}
        className={clsx(classes.root, className)}
        {...other}>
        <Box className={classes.message} onClick={handleClickComplete}>
          <AlertTitle className={classes.title}>
            <Trans i18nKey="layout.authAlert.password.title" />
          </AlertTitle>
          <Trans
            i18nKey="layout.authAlert.password.body"
            components={{ strong: <strong /> }}
          />
        </Box>
      </Alert>
    </>
  );
});

export default withStyles(styles, { name: "ConfirmEmailAlert" })(AuthAlerts);
