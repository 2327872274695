import { Component, ErrorInfo, ReactNode } from "react";
import { ErrorPage } from "./ErrorPages";

interface ErrorBoundaryState {
  hasError: boolean;
  error: Error | null;
  errorInfo: ErrorInfo | null;
  prevPath: string | null;
}

interface ErrorBoundaryProperties {
  children: ReactNode;
  customError: ReactNode;
}

// This has to be a class component
class ErrorBoundary extends Component<
  ErrorBoundaryProperties,
  ErrorBoundaryState
> {
  constructor(props: ErrorBoundaryProperties) {
    super(props);
    this.state = {
      hasError: false,
      error: null,
      errorInfo: null,
      prevPath: null,
    };
  }

  static getDerivedStateFromError(error: Error): ErrorBoundaryState {
    return {
      hasError: true,
      error: error,
      errorInfo: null,
      prevPath: window.location.pathname,
    };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    console.log("Error: ", error, errorInfo);
    // TODO: Synch with a log system
  }

  // Handle page change
  componentDidMount() {
    this.setState({ prevPath: window.location.pathname });
  }

  // Handle page change
  componentDidUpdate() {
    if (window.location.pathname !== this.state.prevPath) {
      this.setState({ hasError: false, error: null, errorInfo: null });
      this.setState({ prevPath: window.location.pathname });
    }
  }

  render(): ReactNode {
    if (this.state.hasError) {
      return (
        this.props.customError || (
          <ErrorPage
            errorTitle={this.state.error?.name ?? undefined}
            errorDescription={this.state.error?.message ?? undefined}
          />
        )
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
