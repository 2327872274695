export const SignUpEN = {
  title: "Sign Up",
  subtitle:
    "Discover hundreds of video lectures, tutorials and readings from top professionals and teachers in the field.",

  form: {
    // Form
    confirmationEmail: "Confirm email",
    stats:
      "I consent to data processing for automatic data processing, statistical purposes and service improvement",
    marketing: "I consent to data processing for direct marketing purposes",
    privacy:
      "I have read the policy and consent to the processing of data for the purposes set forth in the<url>Privacy Policy</url>",

    action: "Sign Up",

    // Validation
    errors: {
      emailMatch: "The two emails do not match",
      passwordMatch: "The two passwords do not match",
      marketing:
        "You must first consent to the processing of data for statistical purposes",
      tos: "You must accept the terms of service and privacy",
    },
  },

  alternative: "Or",
  signIn: "Do you already have an account? <b>Sign In</b>",
};
