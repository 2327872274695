import { InMemoryCache } from "@apollo/client/cache";
import { ApolloClient, ApolloLink, HttpLink, split } from "@apollo/client";

import { WebSocketLink } from "@apollo/client/link/ws";
import { onError } from "@apollo/client/link/error";
import { getMainDefinition } from "@apollo/client/utilities";
import omitDeep from "omit-deep-lodash";
import { baseUrl, gqlPath, isHTTPS } from "../config";


/**
 * Cache
 */

export const cache = new InMemoryCache({
  //addTypename: false
});

/**
 * Links
 */

function buildHttpLink(baseURl) {
  const uri = new URL(gqlPath, baseURl);
  return new HttpLink({
    uri: uri.href,
    credentials: "include",
  });
}

function buildWsLink(baseURl) {
  const uri = new URL(gqlPath, baseURl);
  uri.protocol = isHTTPS ? "wss" : "ws";

  return new WebSocketLink({
    uri: uri.href,
    options: {
      reconnect: true,
    },
  });
}

const cleanTypenameLink = new ApolloLink((operation, forward) => {
  const keysToOmit = ["__typename"]; // more keys like timestamps could be included here

  const def = getMainDefinition(operation.query);
  if (def && def.operation === "mutation") {
    operation.variables = omitDeep(operation.variables, keysToOmit);
  }
  return forward ? forward(operation) : null;
});

/**
 * The actual client
 */
export const client = new ApolloClient({
  link: ApolloLink.from([
    onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors)
        graphQLErrors.forEach(({ message, locations, path }) =>
          console.warn(
            `[GraphQL error]: Message: ${message}, Location: ${JSON.stringify(
              locations,
              null,
              2
            )}, Path: ${path}`
          )
        );
      if (networkError) console.log(`[Network error]: ${networkError}`);
    }),
    split(
      // split based on operation type
      ({ query }) => {
        const definition = getMainDefinition(query);
        return (
          definition.kind === "OperationDefinition" &&
          definition.operation === "subscription"
        );
      },
      buildWsLink(baseUrl),
      ApolloLink.from([cleanTypenameLink, buildHttpLink(baseUrl)])
    ),
  ]),
  cache,
});
