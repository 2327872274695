export const LoginIT = {
  title: "Accedi",

  // Social Login
  google: "Accedi con Google",
  facebook: "Accedi con Facebook",
  linkedin: "Accedi con Linkedin",

  // Form
  forgotPassword: "Password dimenticata?",
  recoverPassword: "Recupera la password",
  button: "Accedi",

  // Validation
  errors: {
    missing: "Dato necessario",
    email: "Formato email errato",
    password: "Password troppo corta",
  },

  alternative: "O",
  signUp: "Sei nuovo? <b>Registrati</b>",
};
