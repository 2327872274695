export const ExploreEN = {
  seo: {
    title: "Discover our online hotel training courses",
    description:
      "Universo Corsi is the first online platform for tourism training courses with over 1500 training contents from the best Italian trainers",
  },

  carousel: {
    topics: {
      title: "Topics",
    },

    featuredEvents: {
      title: "Live events and lessons",
      description:
        "Real virtual classroom lessons held by professionals in the hospitality sector",
    },

    mostViewedCourses: {
      title: "Most popular courses",
      description: "Most viewed and clicked by users",
    },

    mostRecentCourses: {
      title: "News",
      description:
        "Every week new video courses and constantly updated content",
    },

    masterclasses: {
      title: "Masterclass",
      description:
        "The experiences and lessons of highly successful professionals",
    },

    playlist: {
      title: "Certified studyplans",
      description: "A set of selected courses to improve your work skills",
    },
  },
};
