import { Button } from "@material-ui/core";
import styled, { css } from "styled-components";

export const DashboardLayoutWrapper = styled.div`
  height: 100vh;
  overflow: hidden;
  background: #efefef;
  display: flex;
  flex-direction: column;
`;

export const DashboardLayoutBody = styled.div`
  padding-bottom: 24px;
  min-height: 100vh;

  h5 {
    margin-bottom: 24px;

    &:not(:first-child) {
      margin-top: 48px;
    }
  }
`;

export const DashboardLayoutMask = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  padding: 16px;
  box-sizing: border-box;

  .MuiTypography-root {
    max-width: 420px;
    margin-bottom: 32px;

    &.MuiTypography-h2 {
      margin-bottom: 16px;
    }
  }

  .MuiGrid-container {
    max-width: 320px;
  }
`;

export const NavbarWrapper = styled.div`
  z-index: 2;
`;
