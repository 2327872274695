import { withStyles } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import clsx from "clsx";
import React from "react";
import { Outlet } from "react-router";
import ErrorBoundary from "../../ErrorBoundary";
import Footer from "../../Footer";
import Navbar from "./components/Navbar";

export const styles = (theme) => ({
  /* Styles applied to the root element. */
  root: {},
  navbar: {},
  body: {
    marginTop: 56,
    [`${theme.breakpoints.up("xs")} and (orientation: landscape)`]: {
      marginTop: 48,
    },
    [theme.breakpoints.up("sm")]: {
      marginTop: 64,
    },
  },
  footer: {},
});

const WelevelLayout = React.forwardRef(function WelevelLayout(props, ref) {
  const { classes, className, hiddenNav, hideFooter, children, ...other } =
    props;

  return (
    <Box className={clsx(classes.root, className)}>
      {hiddenNav || <Navbar className={classes.navbar} {...other} />}

      <Box className={classes.body} {...other}>
        <ErrorBoundary>{children ? children : <Outlet />}</ErrorBoundary>
      </Box>
      {hideFooter || <Footer className={classes.footer} {...other} />}
    </Box>
  );
});

export default withStyles(styles, { name: "WelevelLayout" })(WelevelLayout);
