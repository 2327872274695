export const HomeEN = {
  seo: {
    title: "Universo Corsi: Video Courses for happier life!",
  },
  index: {
    section: {
      title: [
        "Discover the largest online training platform dedicated to ",
        "tourism",
        " and ",
        "hospitality",
        " professionals!",
      ],
    },
    startLearningButton: "Start learning",
  },
  cover: {
    mainHero: {
      title: ["Lighting Up", "Your Life", "a course at time!"],
      subtitle: "Online Courses for the Truly Distracted Enlightened One!",
      listLines: [
        "Original Spiritual Journeys",
        "Learning with a Smile",
        "Suitable for Everyone",
      ],
    },
    discoverCoursesButton: "Discover the courses",
    startNowButton: "Start now",
  },
  topics: {
    section: {
      title: "Topics",
      subtitle: "All departments of each facility at 360 degrees",
    },
    showMoreButton: "Show more topics",
  },
  agenciesBanner: {
    section: {
      title: "Train your staff with Welevel Business",
      description:
        "Your staff will have access to Italy's <b>largest library</b> of courses on the world of hospitality with over 2,500 pieces of training content always available and up-to-date and over 120 trainers and industry professionals.",
    },
    advantageList: {
      title: "The benefits of Welevel Business:",
      lines: [
        "Your employees will have a mountain of material at their disposal: hands-on exercises, learning tests, slides, case histories, and video lectures.",
        "Control dashboard to assign each employee a personalized route and monitor their progress.",
        "It is always faster and cheaper than traditional classroom training, and you will have a mentor by your side at all times who will provide assistance and periodic monitoring.",
        "Your staff will be more rewarded and motivated therefore more productive!",
      ],
    },
    introductoryTitleQuestion: "Do you run an accommodation business?",
    discoverMoreButton: "Discover more",
  },
  socialProof: {
    section: {
      title: "The say about us",
      subtitle:
        "Listen to the voices of directors and professionals who use Welevel Business",
    },
  },
  agencies: {
    section: {
      title: "For your staff",
    },
    advantageList: {
      title: "Training your staff has never been easier:",
      list: [
        [
          "You can monitor",
          " the learning level of your employees in real time",
        ],
        [
          "Your staff",
          " will have available ",
          "the biggest",
          " italian library of courses on the world of hospitality with ",
          "over 1500 pieces of training content ",
          "always available and up-to-date",
          " and over 120 ",
          "trainers",
          " and industry professionals",
        ],
        [
          "No more excuses",
          ": courses are always accessible 7/7 24/h from home or work and ",
          "reviewable",
          " as many times as you want, from smartphone or desktop",
        ],
        [
          "It is ",
          "faster",
          " and ",
          "cheaper",
          " than traditional classroom training",
        ],
        [
          "There is ",
          "a mentor always by your side",
          " who will provide ",
          "assistance",
          " and periodic monitoring",
        ],
        [
          "Your collaborators will have a mountain of material at their disposal:",
          "practical exercises",
          ",",
          "tests",
          " to verify the learning, ",
          "slide",
          ", e-book, case history and video lectures",
        ],
        [
          "Your staff will be more ",
          "gratified",
          " and ",
          "motivated",
          " therefore more productive",
        ],
      ],
    },
    corporate: "CORPORATE",
  },
  promoBanner: {
    section: {
      title: "Welevel-Companies promo",
    },
  },
  companies: {
    section: {
      title: "They have also used Welevel Business...",
    },
  },
  courses: {
    mostRecentSection: {
      title: "News",
      subtitle: "New video courses and constantly updated content every week",
    },
    mostViewedSection: {
      title: "Most popular",
      subtitle: "The most viewed and clicked video courses by users",
    },
  },
  teachers: {
    section: {
      title: "Some of our teachers",
    },
    seeAll: "See all...",
  },
  features: {
    list: [
      "Online training courses",
      "Satisfaction level based on more than -1 reviews",
      "People enrolled",
    ],
  },
};
