export const BillingIT = {
  seo: {
    title: "Pagamenti | universo corsi",
  },

  planViewer: {
    title: "Il mio piano",

    alertCanceled: {
      title: "Abbonamento annullato",
      body: "Il tuo abbonamento non si rinnoverà. Potrai continuare ad accedere a tutti i contenuti fino al <strong>{{expireDate}}</strong>. Al termine, perderai l'accesso ai tuoi benefici Welevel.",
    },
    alertExpired: {
      title: "Abbonamento terminato",
      body: "Per accedere a tutti i contenuti Welevel attiva un nuovo abbonamento.",
    },
    alertPastDue: {
      title: "Impossibile rinnovare",
      body: "Il tuo abbonamento non è riuscito a rinnovarsi. Potrai continuare ad accedere a tutti i contenuti fino al <strong>{{expireDate}}</strong>. Al termine, perderai l'accesso ai tuoi benefici Welevel.",
    },
    alertNoRenew: {
      title: "Abbonamento senza rinnovo",
      body: "Il tuo abbonamento non si rinnoverà. Potrai continuare ad accedere a tutti i contenuti fino al <strong>{{expireDate}}</strong>. Al termine, perderai l'accesso ai tuoi benefici Welevel.",
    },

    messages: {
      success: "Piano cambiato con successo! Attendi il refresh della pagina.",
      error: "Errore nel cambio del piano",
    },
  },

  paymentMethods: {
    title: "Metodi di pagamento",

    actions: {
      new: "Nuovo metodo di pagamento",
      default: "Impostazione metodo di pagamento predefinito",
      delete: "Elimina metodo",
    },

    dialogAdd: {
      title: "Aggiungi metodo di pagamento",
    },

    dialogDefault: {
      title: "Stai cambiando il metodo di pagamento predefinito",
      body: "Vuoi continuare?",
    },

    dialogDelete: {
      title: "Stai cancellando un metodo di pagamento",
      body:
        "<bold>ATTENZIONE!</bold>" +
        "<br />" +
        "<bold>Stai eliminando un metodo di pagamento.</bold>" +
        "<br />Se questo metodo di pagamento è associato ad un abbonamento, esso verrà interrotto subito." +
        "<br />" +
        "<br />" +
        "<bold>Vuoi modificare il metodo di pagamento?</bold>" +
        "<br />" +
        'Aggiungi un nuovo metodo di pagamento cliccando il bottone "+" e impostalo come preferito.',
      confirm: "Elimina metodo di pagamento",
    },
  },

  subscriptions: {
    title: "Abbonamenti",
    stillNot: "Non hai ancora nessun abbonamento",
  },

  transactions: {
    title: "Acquisti",
    stillNot: "Nessun acquisto effettuato",
  },

  planCardV2: {
    plan: {
      current: "Piano attuale",
      notAvailableAnymore: "Piano non più disponibile",
    },
    vatExcluded: "iva esclusa",

    instalment: {
      body: "Rateizzato in <strong>{{instalmentCount}}</strong> rate da <strong>{{instalmentPrice}}</strong>",

      monthly: " al mese",
      yearly: " all'anno",
      monthCycle: " ogni {{monthCycle}} mesi",
    },

    method: "Metodo: ",
    nextRenewal: "Data prossimo rinnovo: ",
    cancelSubscription: "Annulla abbonamento",

    buttons: {
      choose: "Scegli questo piano",
      needHelp: "Hai bisogno di aiuto?",
      contactUs: "Contattaci",
    },
  },

  paymentMethod: {
    holder: "<strong>Titolare</strong>",
    expireDate: "<strong>Scadenza</strong>",
    setAsDefault: "Imposta come preferito",
    delete: "Elimina",
  },
};
