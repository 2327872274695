import * as React from "react";
import { withStyles } from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import clsx from "clsx";
import IconButton from "@material-ui/core/IconButton";
import SupervisedIcon from "@material-ui/icons/SupervisedUserCircle";

export const styles = (theme) => ({
  root: {},
  button: {
    padding: theme.spacing(1),
  },
  avatar: {
    height: theme.spacing(4) + "px!important",
    width: theme.spacing(4) + "px!important",
  },
});

const NavImpersonateButton = React.forwardRef(function NavImpersonateButton(
  props,
  ref
) {
  const { classes, className, onClick, ...other } = props;

  return (
    <>
      <Tooltip title="Stop impersonation" aria-label="stop impersonation">
        <IconButton
          ref={ref}
          onClick={onClick}
          className={clsx(classes.button, className)}
          {...other}>
          <SupervisedIcon color="primary" className={clsx(classes.avatar)} />
        </IconButton>
      </Tooltip>
    </>
  );
});

export default withStyles(styles, { name: "NavImpersonateButton" })(
  NavImpersonateButton
);
