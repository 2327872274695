import { completeSubscriptionFormEN } from "../../en/fragments/completeSubscriptionForm";

export const completeSubscriptionFormIT: typeof completeSubscriptionFormEN = {
  header: {
    title: "Completa la tua registrazione",
    description:
      "Abbiamo bisogno di qualche altro dato per poter attivare il tuo Account",
  },
  firstName: {
    errorRequired: "Il nome è obbligatorio",
  },
  lastName: {
    errorRequired: "Il cognome è obbligatorio",
  },
  email: {
    errorRequired: "L'email è obbligatoria",
    errorInvalid: "L'email non è valida",
  },
  password: {
    errorRequired: "La password è obbligatoria",
    errorTooShort: "La password è troppo corta",
  },
  submit: "Completa registrazione",
  completeSubscriptionMutationError:
    "Qualcosa è andato storto. Controlla se l'email è già in uso o contatta il nostro supporto all'indirizzo info@example.com",
};
