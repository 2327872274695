export const StreamEN = {
  back: "Back to event page",
  downloadSlides: "Download the slides",
  answers: "Answers",
  streaming: {
    key: "Streaming key",
    server: "Streaming server",
    setup: "Set up streaming",
    setupMSG:
      "You can set up the streaming through a streaming software like OpenBroadcasterSoftware (OBS) or by connecting a zoom call",
  },
  QA: {
    close: "Close Q/A section",
    reOpen: "Reopen Q/A section",
    topQuestions: "Top questions",
    newQuestion: "New question",
    questions: "Questions",
    askQuestion: "Make this live meaningful, ask a question!",
    answerQs: "Answer the questions and join",
    closeDetail: "Close detail",
  },
  chat: {
    open: "Open chat",
    close: "Close chat",
  },
  subscribe: {
    welevel: "Subscribe to welevel!",
    unlimited: "Subscribe to Unlimited",
    buyToWatch: "Buy the event or subscribe to see this and all other sessions",
    toWatch:
      "To see this session and access all the welevel training, subscribe now!",
    reservedForPro:
      "Access to this seminar is reserved for Pro users. To see this seminar subscribe to Welevel Unlimited",
  },
  session: {
    name: "Session",
    private: "This is a private training session.",
    joinMSG: "To join this session click the button below",
    ended: "This session has ended",
    notStartedYet: "This session hasn't started yet",
    liveNow: "Live now",
    watchWebinar: "Watch the webinar!",
    logInSignUpWatch: "Log in or sign up and start watching",
    logInSignUp: "Log in or sign up",
    insertPsw: "Insert password",
    enterZoomRoom: "Enter the zoom room!",
    rewatchNavigate: "Watch it now! Or keep training with our courses!",
    rewatch: "Rewatch",
    moreAboutEvent: "More about this event",
  },
  explore: {
    name: "Explore",
    otherContent: "Explore other contents",
    otherMeantime: "Explore other contents in the meantime",
  },
  interact: {
    join: "Join",
    send: "Send",
    buy: "Buy",
    buyTicket: "Buy a ticket!",
  },
  privacyPolicy: {
    forEvents: "Privacy policy for Events",
    accept:
      "I consent to the transfer of my personal data to the partners of the event, for their independent commercial use as specified in the",
  },
  form: {
    optionalField: "Optional field",
    fill: "Fill the form",
    mandatoryField: "Mandatory field",
  },
};
