import { Link as MuiLink, LinkProps } from "@material-ui/core";
import { forwardRef } from "react";
import type { To } from "react-router-dom";
import { Link as RouterLink } from "react-router-dom";
import {
  HashLink as RouterHashLink,
  HashLinkProps,
} from "react-router-hash-link";
import styled from "styled-components";

import { colors } from "../../style/colors";

function urlParserExternal(url: To): { url: To; isExt: boolean } {
  const stringUrl = url.toString();

  if (/^https?:\/\//.test(stringUrl) || stringUrl.includes("mailto:")) {
    const hrefURL = new URL(url.toString());
    const external = hrefURL.hostname !== window.location.hostname;

    return {
      url: external
        ? url
        : `${hrefURL.pathname}${hrefURL.search}${hrefURL.hash}`,
      isExt: external,
    };
  }

  return {
    url: url,
    isExt: false,
  };
}

// TODO: is this components and its iterations necessary?

type TypePossibleExternalLink = LinkProps & {
  to: To;
};

type TypeHashLink = LinkProps &
  HashLinkProps & {
    to: To;
  };

const PossibleExternalLink = forwardRef<
  HTMLAnchorElement,
  TypePossibleExternalLink
>((props, ref) => {
  // <RouterLink {...props} ref={ref} style={{ cursor: "pointer" }} />

  const { to, children, ...otherProps } = props;

  if (props.to) {
    const { url, isExt } = urlParserExternal(to);

    return (
      <MuiLink
        {...otherProps}
        href={isExt ? url.toString() : undefined}
        to={!isExt ? url.toString() : undefined}
        component={isExt ? "a" : RouterLink}
        ref={ref}
        underline={"none"}
        color="inherit">
        {children}
      </MuiLink>
    );
  }

  return (
    <>
      <a {...otherProps} ref={ref}>
        {children}
      </a>
    </>
  );
});
PossibleExternalLink.displayName = "PossibleExternalLink";

export const HashLink = forwardRef<HTMLAnchorElement, TypeHashLink>(
  (props, ref) => {
    const { to, children, ...otherProps } = props;
    return (
      <MuiLink
        to={to}
        component={RouterHashLink}
        underline={"hover"}
        color="inherit"
        ref={ref}
        {...otherProps}>
        {children}
      </MuiLink>
    );
  }
);

HashLink.displayName = "StyledHashLink";

export const CardsLink = styled(PossibleExternalLink)`
  color: inherit;
  cursor: pointer;
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  text-decoration: none;
`;

export const TextLink = styled(PossibleExternalLink)`
  transition: 0.3s;
  &:hover {
    color: ${colors.textLink};
  }
`;

const Link = forwardRef<HTMLAnchorElement, TypePossibleExternalLink>(
  (props, ref) => <PossibleExternalLink {...props} ref={ref} />
);
Link.displayName = "StyledLink";

export default Link;
