export const VideoDropzoneIT = {
  dropper: {
    description: "Trascina qui il video, oppure clicca per selezionarlo.",
    release: "Rilascia il file qui...",
  },

  loader: {
    encoding: "Elaborazione del video in corso",
    uploading:
      "<span /><span>{{percentage}}%</span><span>{{uploaded}}MB su {{size}}MB</span>",
  },

  message: {
    success:
      "Video preso in carico, continua pure oppure attendi per l'anteprima!",
    uploading: "Upload in corso",
    error: "C'è stato un errore nel caricamento, riprova",
  },
};
