export const ContactUsIT = {
  seo: {
    title: "Contattaci",
  },
  head: {
    title: "Contattaci, siamo pronti ad aiutarti!",
    subtitle: "Hai domande?",
    body: "Invia un messaggio, ti risponderemo il prima possibile.",
  },
  form: {
    requestType: {
      support: "Richiesta di supporto",
      general: "Informazioni generali",
      other: "Altra richiesta",
    },
    button: "Invia",
  },
  address: {
    title: "Dove ci trovi?",
    subtitle: "Welevel srl",
    address: "Via Macanno, 38Q <br/> 47923 - Rimini (RN)",
    phone: "<url>0541 57474</url>",
    mobile: "<url>331 6209442</url> (anche su  <wa>Whatsapp</wa>)",
    email: "<url>info@example.com</url>",
  },
  social: {
    title: "I nostri canali social",
  },
};
