export const DialogAddToPlaylistEN = {
  title: "Add course to...",
  sectionNew: {
    title: "Create a new playlist",
    form: "Studyplan Name",
  },
  sectionPersonalAgency: {
    personal: "To me",
    agency: "To my team",
  },
  sectionTeam: {
    title: "Choose a team member",
    noTeam: "No members in your team!"
  },
  sectionStudyplan: {
    title: "Choose a team member",
    noTeam: "No members in your team!"
  },
  snackbar: {
    addToEmployee: {
      success: "Course successfully added",
      error: "Error while adding the course",
    },
    addToUser: {
      success: "Course successfully added",
      error: "Error while adding the course",
    },
    deletePlaylist: {
      success: "Studyplan removed successfully",
      error: "Error while deleting the studyplan",
    },
  },
};
