import React from "react";
import styled from "styled-components";
import { colors } from "../../style/colors";

const LogoSvg = styled.svg`
  max-width: 100%;
  max-height: 100%;
`;

const Logo = ({
  size = "md",
  color = colors.textPrimary,
  dotColor = colors.primary,
  ...props
}) => {
  switch (size) {
    case "sm":
      return (
        <LogoSvg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 167 62"
          width={167}
          height={62}
          {...props}
          preserveAspectRatio="xMinYMid meet">
          {/*<path*/}
          {/*  fill={color}*/}
          {/*  d="M84.433,68.224q-2.144,8.441-4.552,16.769T74.8,101.762H62.372Q60.44,97.1,58.184,90.657T53.512,76.609Q51.1,69,48.571,60.506T43.685,43.349H60.5q.861,4,1.884,8.717t2.155,9.717q1.132,5,2.372,10.051t2.531,9.717q1.391-4.887,2.731-10t2.57-10.05q1.23-4.941,2.356-9.551t1.981-8.606H90.644q.856,4,1.927,8.606t2.249,9.551Q96,66.447,97.283,71.556t2.678,10q1.291-4.665,2.584-9.717t2.425-10.051q1.13-5,2.154-9.717t1.886-8.717h16.6q-2.364,8.663-4.888,17.157t-4.939,16.1q-2.417,7.608-4.725,14.048t-4.242,11.105H94.392q-2.677-8.439-5.247-16.769T84.433,68.224Z"*/}
          {/*/>*/}
          {/*<path*/}
          {/*  fill={color}*/}
          {/*  d="M130.107,73a36.878,36.878,0,0,1,2.3-13.6,29.287,29.287,0,0,1,6.051-9.717,25.093,25.093,0,0,1,8.62-5.886,26.145,26.145,0,0,1,10.013-2q11.993,0,18.955,7.607t6.961,22.377q0,1.444-.107,3.165T182.687,78h-36.2a11.075,11.075,0,0,0,4.6,8.107q4.07,3,10.923,3a44.549,44.549,0,0,0,8.621-.833,31.645,31.645,0,0,0,6.908-2.054l2.142,13.437a21.612,21.612,0,0,1-3.428,1.333,43.576,43.576,0,0,1-4.765,1.166q-2.627.5-5.622.833a54.318,54.318,0,0,1-6,.333,34.38,34.38,0,0,1-13.226-2.332,24.883,24.883,0,0,1-14.778-15.992A39.339,39.339,0,0,1,130.107,73Zm37.481-6.33a17.728,17.728,0,0,0-.7-4.109,10.424,10.424,0,0,0-1.82-3.554,9.3,9.3,0,0,0-7.765-3.553,10.389,10.389,0,0,0-4.6.943,9.579,9.579,0,0,0-3.213,2.5,11.286,11.286,0,0,0-1.981,3.609,24.445,24.445,0,0,0-1.018,4.165Z"*/}
          {/*/>*/}
          {/*<path*/}
          {/*  fill={dotColor}*/}
          {/*  d="M210.315,93.989q0,5-3.052,7.607a10.446,10.446,0,0,1-13.815,0q-3.051-2.609-3.051-7.607t3.051-7.607a10.446,10.446,0,0,1,13.815,0Q210.316,88.992,210.315,93.989Z"*/}
          {/*/>*/}

          <path
            fill={color}
            d="m40.748,26.427q-2.144,8.441 -4.552,16.769t-5.081,16.769l-12.428,0q-1.932,-4.662 -4.188,-11.105t-4.672,-14.048q-2.412,-7.609 -4.941,-16.103t-4.886,-17.157l16.815,0q0.861,4 1.884,8.717t2.155,9.717q1.132,5 2.372,10.051t2.531,9.717q1.391,-4.887 2.731,-10t2.57,-10.05q1.23,-4.941 2.356,-9.551t1.981,-8.606l11.564,0q0.856,4 1.927,8.606t2.249,9.551q1.18,4.946 2.463,10.055t2.678,10q1.291,-4.665 2.584,-9.717t2.425,-10.051q1.13,-5 2.154,-9.717t1.886,-8.717l16.6,0q-2.364,8.663 -4.888,17.157t-4.939,16.1q-2.417,7.608 -4.725,14.048t-4.242,11.105l-12.424,0q-2.677,-8.439 -5.247,-16.769t-4.712,-16.771z"
            fill="rgba(0,0,0,0.87)"
          />
          <path
            fill={color}
            d="m86.422,31.203a36.878,36.878 0 0 1 2.3,-13.6a29.287,29.287 0 0 1 6.051,-9.717a25.093,25.093 0 0 1 8.62,-5.886a26.145,26.145 0 0 1 10.013,-2q11.993,0 18.955,7.607t6.961,22.377q0,1.444 -0.107,3.165t-0.213,3.054l-36.2,0a11.075,11.075 0 0 0 4.6,8.107q4.07,3 10.923,3a44.549,44.549 0 0 0 8.621,-0.833a31.645,31.645 0 0 0 6.908,-2.054l2.142,13.437a21.612,21.612 0 0 1 -3.428,1.333a43.576,43.576 0 0 1 -4.765,1.166q-2.627,0.5 -5.622,0.833a54.318,54.318 0 0 1 -6,0.333a34.38,34.38 0 0 1 -13.226,-2.332a24.883,24.883 0 0 1 -14.778,-15.992a39.339,39.339 0 0 1 -1.755,-11.998zm37.481,-6.33a17.728,17.728 0 0 0 -0.7,-4.109a10.424,10.424 0 0 0 -1.82,-3.554a9.3,9.3 0 0 0 -7.765,-3.553a10.389,10.389 0 0 0 -4.6,0.943a9.579,9.579 0 0 0 -3.213,2.5a11.286,11.286 0 0 0 -1.981,3.609a24.445,24.445 0 0 0 -1.018,4.165l21.097,-0.001z"
            fill="rgba(0,0,0,0.87)"
          />
          <path
            fill={dotColor}
            d="m166.63,52.192q0,5 -3.052,7.607a10.446,10.446 0 0 1 -13.815,0q-3.051,-2.609 -3.051,-7.607t3.051,-7.607a10.446,10.446 0 0 1 13.815,0q3.053,2.61 3.052,7.607z"
            fill="#E13029"
          />
        </LogoSvg>
      );
    case "md":
      return (
        <LogoSvg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 500 160"
          width={250}
          height={80}
          {...props}
          preserveAspectRatio="xMinYMid meet">
          <path
            fill={color}
            d="M80.748,86.735Q78.6,95.177,76.2,103.5t-5.087,16.769H58.687q-1.932-4.663-4.188-11.1T49.827,95.12q-2.418-7.605-4.942-16.1T40,61.86H56.813q.859,4,1.883,8.717T60.852,80.3q1.133,5,2.371,10.05t2.532,9.717q1.391-4.887,2.731-10t2.57-10.05q1.23-4.941,2.356-9.551t1.981-8.606H86.958q.856,4,1.928,8.606t2.249,9.551q1.176,4.942,2.463,10.05t2.677,10q1.293-4.665,2.585-9.717t2.425-10.05q1.13-5,2.153-9.718t1.886-8.717h16.6q-2.364,8.663-4.888,17.157T112.1,95.12q-2.418,7.608-4.725,14.048t-4.242,11.1H90.707q-2.679-8.439-5.248-16.769T80.748,86.735Z"
          />
          <path
            fill={color}
            d="M126.422,91.511a36.878,36.878,0,0,1,2.3-13.6,29.3,29.3,0,0,1,6.05-9.717A25.106,25.106,0,0,1,143.4,62.3a26.142,26.142,0,0,1,10.013-2q11.993,0,18.955,7.607t6.961,22.377q0,1.444-.108,3.165T179,96.508h-36.2a11.073,11.073,0,0,0,4.6,8.107q4.07,3,10.923,3a44.549,44.549,0,0,0,8.621-.833,31.634,31.634,0,0,0,6.907-2.054L176,118.163a21.6,21.6,0,0,1-3.427,1.333,43.6,43.6,0,0,1-4.766,1.166q-2.625.5-5.621.833a54.336,54.336,0,0,1-6,.333,34.375,34.375,0,0,1-13.225-2.332A24.876,24.876,0,0,1,128.189,103.5,39.339,39.339,0,0,1,126.422,91.511Zm37.481-6.33a17.781,17.781,0,0,0-.7-4.109,10.453,10.453,0,0,0-1.821-3.554,9.3,9.3,0,0,0-7.764-3.553,10.386,10.386,0,0,0-4.6.943,9.579,9.579,0,0,0-3.213,2.5,11.308,11.308,0,0,0-1.982,3.609,24.441,24.441,0,0,0-1.017,4.165Z"
          />
          <path
            fill={color}
            d="M222.8,91.511a36.878,36.878,0,0,1,2.3-13.6,29.269,29.269,0,0,1,6.05-9.717,25.087,25.087,0,0,1,8.621-5.886,26.137,26.137,0,0,1,10.012-2q11.994,0,18.956,7.607T275.7,90.289q0,1.444-.108,3.165t-.214,3.054h-36.2a11.07,11.07,0,0,0,4.6,8.107q4.068,3,10.923,3a44.564,44.564,0,0,0,8.621-.833,31.651,31.651,0,0,0,6.907-2.054l2.142,13.437a21.6,21.6,0,0,1-3.427,1.333,43.6,43.6,0,0,1-4.766,1.166q-2.625.5-5.622.833a54.3,54.3,0,0,1-6,.333,34.38,34.38,0,0,1-13.226-2.332A24.878,24.878,0,0,1,224.568,103.5,39.339,39.339,0,0,1,222.8,91.511Zm37.48-6.33a17.727,17.727,0,0,0-.695-4.109,10.453,10.453,0,0,0-1.821-3.554A9.3,9.3,0,0,0,250,73.965a10.393,10.393,0,0,0-4.605.943,9.589,9.589,0,0,0-3.213,2.5,11.305,11.305,0,0,0-1.981,3.609,24.348,24.348,0,0,0-1.017,4.165Z"
          />
          <path
            fill={color}
            d="M303.412,120.273q-5.482-11.1-11.126-25.986T281.592,61.86h16.921q1.074,4.331,2.528,9.384t3.011,10.216q1.56,5.165,3.12,10.051t3.067,8.884q1.4-4,3.008-8.884t3.171-10.051q1.558-5.163,3.01-10.216t2.527-9.384h16.5q-5.051,17.549-10.688,32.427t-11.115,25.986Z"
          />
          <path
            fill={color}
            d="M342.74,91.511a36.878,36.878,0,0,1,2.3-13.6,29.287,29.287,0,0,1,6.051-9.717,25.093,25.093,0,0,1,8.62-5.886,26.145,26.145,0,0,1,10.013-2q11.993,0,18.955,7.607t6.961,22.377q0,1.444-.107,3.165t-.215,3.054h-36.2a11.076,11.076,0,0,0,4.6,8.107q4.07,3,10.923,3a44.566,44.566,0,0,0,8.622-.833,31.651,31.651,0,0,0,6.907-2.054l2.142,13.437A21.612,21.612,0,0,1,388.9,119.5a43.576,43.576,0,0,1-4.765,1.166q-2.626.5-5.622.833a54.318,54.318,0,0,1-6,.333,34.38,34.38,0,0,1-13.226-2.332A24.883,24.883,0,0,1,344.507,103.5,39.339,39.339,0,0,1,342.74,91.511Zm37.481-6.33a17.728,17.728,0,0,0-.7-4.109,10.433,10.433,0,0,0-1.82-3.554,9.3,9.3,0,0,0-7.765-3.553,10.389,10.389,0,0,0-4.6.943,9.579,9.579,0,0,0-3.213,2.5,11.286,11.286,0,0,0-1.981,3.609,24.445,24.445,0,0,0-1.018,4.165Z"
          />
          <path
            fill={color}
            d="M209.148,104.726a7.04,7.04,0,0,1-1.232-3,22.6,22.6,0,0,1-.321-4V37.283L191.639,40.1v60.964a35.355,35.355,0,0,0,.91,8.385,13.558,13.558,0,0,0,3.427,6.33,16.572,16.572,0,0,0,6.854,4.053,38.574,38.574,0,0,0,11.3,1.555l2.248-13.66a13.912,13.912,0,0,1-4.6-1A6.534,6.534,0,0,1,209.148,104.726Z"
          />
          <path
            fill={color}
            d="M425.466,104.726a7.027,7.027,0,0,1-1.231-3,22.511,22.511,0,0,1-.321-4V37.283L407.957,40.1v60.964a35.355,35.355,0,0,0,.91,8.385,13.567,13.567,0,0,0,3.427,6.33,16.579,16.579,0,0,0,6.854,4.053,38.574,38.574,0,0,0,11.3,1.555l2.249-13.66a13.913,13.913,0,0,1-4.605-1A6.534,6.534,0,0,1,425.466,104.726Z"
          />
          <path
            fill={dotColor}
            d="M460,112.5q0,5-3.052,7.607a10.444,10.444,0,0,1-13.814,0q-3.053-2.609-3.052-7.607t3.052-7.607a10.444,10.444,0,0,1,13.814,0Q460,107.5,460,112.5Z"
          />
        </LogoSvg>
      );
    case "lg":
      return (
        <LogoSvg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 500 210"
          width={250}
          height={105}
          {...props}
          preserveAspectRatio="xMinYMid meet">
          <path
            fill={color}
            d="M140.072,172.263h-4.063l11.75-29.091h4l11.75,29.091h-4.062l-9.563-24.489h-.25Zm1.5-11.364h16.375v3.125H141.572Z"
          />
          <path
            fill={color}
            d="M194.079,152.263H190.2a7.046,7.046,0,0,0-1.2-2.671,7.919,7.919,0,0,0-2.062-1.939,9.4,9.4,0,0,0-2.68-1.186,11.67,11.67,0,0,0-3.062-.4,10.469,10.469,0,0,0-5.258,1.335,9.558,9.558,0,0,0-3.735,3.935,15.408,15.408,0,0,0,0,12.756,9.537,9.537,0,0,0,3.735,3.934,10.448,10.448,0,0,0,5.258,1.335,11.628,11.628,0,0,0,3.062-.4,9.343,9.343,0,0,0,2.68-1.186,7.862,7.862,0,0,0,2.062-1.946,7.108,7.108,0,0,0,1.2-2.663h3.875a11.1,11.1,0,0,1-1.594,3.991,11.219,11.219,0,0,1-2.875,2.99,12.95,12.95,0,0,1-3.852,1.868,15.792,15.792,0,0,1-4.554.639,14.425,14.425,0,0,1-7.282-1.818,12.945,12.945,0,0,1-5.015-5.17,18.221,18.221,0,0,1,0-15.909,12.941,12.941,0,0,1,5.015-5.171,14.435,14.435,0,0,1,7.282-1.818,15.792,15.792,0,0,1,4.554.639,12.974,12.974,0,0,1,3.852,1.868,11.282,11.282,0,0,1,2.875,2.983A11.04,11.04,0,0,1,194.079,152.263Z"
          />
          <path
            fill={color}
            d="M202.961,172.263H198.9l11.75-29.091h4l11.75,29.091h-4.063l-9.562-24.489h-.25Zm1.5-11.364h16.375v3.125H204.461Z"
          />
          <path
            fill={color}
            d="M242.593,172.263h-9.875V143.172h10.313A16.951,16.951,0,0,1,251,144.911a12,12,0,0,1,5.078,4.987,18,18,0,0,1-.016,15.582,12.016,12.016,0,0,1-5.187,5.028A17.913,17.913,0,0,1,242.593,172.263Zm-6-3.125h5.75a13.866,13.866,0,0,0,6.578-1.393,8.913,8.913,0,0,0,3.891-3.963,15.237,15.237,0,0,0,.016-12.194,8.853,8.853,0,0,0-3.782-3.921,12.974,12.974,0,0,0-6.265-1.37h-6.188Z"
          />
          <path
            fill={color}
            d="M265.664,172.263V143.172h19.312V146.3H269.539v9.829h14.437v3.125H269.539v9.887h15.687v3.125Z"
          />
          <path
            fill={color}
            d="M293.3,143.172h4.625L308.8,167.319h.375l10.875-24.147h4.625v29.091h-3.625v-22.1h-.312l-10,22.1h-3.5l-10-22.1h-.313v22.1H293.3Z"
          />
          <path
            fill={color}
            d="M330.991,143.172h4.437l8.875,13.579h.375l8.875-13.579h4.438l-11.563,17.1v11.989h-3.875V160.274Z"
          />
          <path
            fill={color}
            d="M80.748,86.792Q78.6,95.232,76.2,103.561T71.109,120.33H58.687q-1.932-4.665-4.188-11.106T49.827,95.176q-2.418-7.605-4.942-16.1T40,61.916H56.813q.859,4,1.883,8.718t2.156,9.717q1.133,5,2.371,10.051t2.532,9.716q1.391-4.885,2.731-9.994t2.57-10.051q1.23-4.939,2.356-9.55t1.981-8.607H86.958q.856,4,1.928,8.607t2.249,9.55q1.176,4.943,2.463,10.051t2.677,9.994q1.293-4.663,2.585-9.716t2.425-10.051q1.13-5,2.153-9.717t1.886-8.718h16.6q-2.364,8.663-4.888,17.158t-4.939,16.1q-2.418,7.609-4.725,14.048t-4.242,11.106H90.707q-2.679-8.439-5.248-16.769T80.748,86.792Z"
          />
          <path
            fill={color}
            d="M126.422,91.567a36.877,36.877,0,0,1,2.3-13.6,29.316,29.316,0,0,1,6.05-9.718,25.117,25.117,0,0,1,8.621-5.885,26.142,26.142,0,0,1,10.013-2q11.993,0,18.955,7.606t6.961,22.378q0,1.444-.108,3.164c-.072,1.15-.143,2.166-.214,3.054h-36.2a11.075,11.075,0,0,0,4.6,8.108q4.07,3,10.923,3a44.549,44.549,0,0,0,8.621-.833,31.575,31.575,0,0,0,6.907-2.055L176,118.22a21.693,21.693,0,0,1-3.427,1.333,43.377,43.377,0,0,1-4.766,1.165q-2.625.5-5.621.833a54.093,54.093,0,0,1-6,.333,34.375,34.375,0,0,1-13.225-2.331,24.876,24.876,0,0,1-14.778-15.992A39.341,39.341,0,0,1,126.422,91.567Zm37.481-6.33a17.79,17.79,0,0,0-.7-4.109,10.453,10.453,0,0,0-1.821-3.554,9.3,9.3,0,0,0-7.764-3.553,10.386,10.386,0,0,0-4.6.944,9.554,9.554,0,0,0-3.213,2.5,11.308,11.308,0,0,0-1.982,3.609,24.431,24.431,0,0,0-1.017,4.164Z"
          />
          <path
            fill={color}
            d="M222.8,91.567a36.877,36.877,0,0,1,2.3-13.6,29.284,29.284,0,0,1,6.05-9.718,25.1,25.1,0,0,1,8.621-5.885,26.137,26.137,0,0,1,10.012-2q11.994,0,18.956,7.606T275.7,90.346q0,1.444-.108,3.164c-.072,1.15-.144,2.166-.214,3.054h-36.2a11.072,11.072,0,0,0,4.6,8.108q4.068,3,10.923,3a44.564,44.564,0,0,0,8.621-.833,31.592,31.592,0,0,0,6.907-2.055l2.142,13.438a21.693,21.693,0,0,1-3.427,1.333,43.377,43.377,0,0,1-4.766,1.165q-2.625.5-5.622.833a54.062,54.062,0,0,1-6,.333,34.38,34.38,0,0,1-13.226-2.331,24.878,24.878,0,0,1-14.777-15.992A39.341,39.341,0,0,1,222.8,91.567Zm37.48-6.33a17.736,17.736,0,0,0-.695-4.109,10.453,10.453,0,0,0-1.821-3.554A9.3,9.3,0,0,0,250,74.021a10.393,10.393,0,0,0-4.605.944,9.563,9.563,0,0,0-3.213,2.5,11.305,11.305,0,0,0-1.981,3.609,24.337,24.337,0,0,0-1.017,4.164Z"
          />
          <path
            fill={color}
            d="M303.412,120.33q-5.482-11.1-11.126-25.987T281.592,61.916h16.921q1.074,4.332,2.528,9.384t3.011,10.217q1.56,5.165,3.12,10.05t3.067,8.884q1.4-4,3.008-8.884t3.171-10.05q1.558-5.163,3.01-10.217t2.527-9.384h16.5q-5.051,17.548-10.688,32.427T316.654,120.33Z"
          />
          <path
            fill={color}
            d="M342.74,91.567a36.877,36.877,0,0,1,2.3-13.6,29.3,29.3,0,0,1,6.051-9.718,25.1,25.1,0,0,1,8.62-5.885,26.145,26.145,0,0,1,10.013-2q11.993,0,18.955,7.606t6.961,22.378q0,1.444-.107,3.164c-.072,1.15-.144,2.166-.215,3.054h-36.2a11.078,11.078,0,0,0,4.6,8.108q4.07,3,10.923,3a44.566,44.566,0,0,0,8.622-.833,31.592,31.592,0,0,0,6.907-2.055l2.142,13.438a21.709,21.709,0,0,1-3.428,1.333,43.357,43.357,0,0,1-4.765,1.165q-2.626.5-5.622.833a54.075,54.075,0,0,1-6,.333,34.38,34.38,0,0,1-13.226-2.331,24.883,24.883,0,0,1-14.778-15.992A39.341,39.341,0,0,1,342.74,91.567Zm37.481-6.33a17.737,17.737,0,0,0-.7-4.109,10.433,10.433,0,0,0-1.82-3.554,9.308,9.308,0,0,0-7.765-3.553,10.389,10.389,0,0,0-4.6.944,9.554,9.554,0,0,0-3.213,2.5,11.286,11.286,0,0,0-1.981,3.609,24.434,24.434,0,0,0-1.018,4.164Z"
          />
          <path
            fill={dotColor}
            d="M460,112.557q0,5-3.052,7.607a10.444,10.444,0,0,1-13.814,0q-3.053-2.609-3.052-7.607t3.052-7.608a10.447,10.447,0,0,1,13.814,0Q460,107.56,460,112.557Z"
          />
          <path
            fill={color}
            d="M209.148,104.782a7.029,7.029,0,0,1-1.232-3,22.585,22.585,0,0,1-.321-4V37.34l-15.956,2.813v60.965a35.346,35.346,0,0,0,.91,8.384,13.553,13.553,0,0,0,3.427,6.33,16.562,16.562,0,0,0,6.854,4.053,38.6,38.6,0,0,0,11.3,1.556l2.248-13.66a13.885,13.885,0,0,1-4.6-1A6.517,6.517,0,0,1,209.148,104.782Z"
          />
          <path
            fill={color}
            d="M425.466,104.782a7.016,7.016,0,0,1-1.231-3,22.491,22.491,0,0,1-.321-4V37.34l-15.957,2.813v60.965a35.346,35.346,0,0,0,.91,8.384,13.562,13.562,0,0,0,3.427,6.33,16.568,16.568,0,0,0,6.854,4.053,38.6,38.6,0,0,0,11.3,1.556l2.249-13.66a13.885,13.885,0,0,1-4.605-1A6.517,6.517,0,0,1,425.466,104.782Z"
          />
        </LogoSvg>
      );
    default:
      return (
        <LogoSvg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 500 160"
          width={250}
          height={80}
          {...props}
          preserveAspectRatio="xMinYMid meet">
          <path
            fill={color}
            d="M80.748,86.735Q78.6,95.177,76.2,103.5t-5.087,16.769H58.687q-1.932-4.663-4.188-11.1T49.827,95.12q-2.418-7.605-4.942-16.1T40,61.86H56.813q.859,4,1.883,8.717T60.852,80.3q1.133,5,2.371,10.05t2.532,9.717q1.391-4.887,2.731-10t2.57-10.05q1.23-4.941,2.356-9.551t1.981-8.606H86.958q.856,4,1.928,8.606t2.249,9.551q1.176,4.942,2.463,10.05t2.677,10q1.293-4.665,2.585-9.717t2.425-10.05q1.13-5,2.153-9.718t1.886-8.717h16.6q-2.364,8.663-4.888,17.157T112.1,95.12q-2.418,7.608-4.725,14.048t-4.242,11.1H90.707q-2.679-8.439-5.248-16.769T80.748,86.735Z"
          />
          <path
            fill={color}
            d="M126.422,91.511a36.878,36.878,0,0,1,2.3-13.6,29.3,29.3,0,0,1,6.05-9.717A25.106,25.106,0,0,1,143.4,62.3a26.142,26.142,0,0,1,10.013-2q11.993,0,18.955,7.607t6.961,22.377q0,1.444-.108,3.165T179,96.508h-36.2a11.073,11.073,0,0,0,4.6,8.107q4.07,3,10.923,3a44.549,44.549,0,0,0,8.621-.833,31.634,31.634,0,0,0,6.907-2.054L176,118.163a21.6,21.6,0,0,1-3.427,1.333,43.6,43.6,0,0,1-4.766,1.166q-2.625.5-5.621.833a54.336,54.336,0,0,1-6,.333,34.375,34.375,0,0,1-13.225-2.332A24.876,24.876,0,0,1,128.189,103.5,39.339,39.339,0,0,1,126.422,91.511Zm37.481-6.33a17.781,17.781,0,0,0-.7-4.109,10.453,10.453,0,0,0-1.821-3.554,9.3,9.3,0,0,0-7.764-3.553,10.386,10.386,0,0,0-4.6.943,9.579,9.579,0,0,0-3.213,2.5,11.308,11.308,0,0,0-1.982,3.609,24.441,24.441,0,0,0-1.017,4.165Z"
          />
          <path
            fill={color}
            d="M222.8,91.511a36.878,36.878,0,0,1,2.3-13.6,29.269,29.269,0,0,1,6.05-9.717,25.087,25.087,0,0,1,8.621-5.886,26.137,26.137,0,0,1,10.012-2q11.994,0,18.956,7.607T275.7,90.289q0,1.444-.108,3.165t-.214,3.054h-36.2a11.07,11.07,0,0,0,4.6,8.107q4.068,3,10.923,3a44.564,44.564,0,0,0,8.621-.833,31.651,31.651,0,0,0,6.907-2.054l2.142,13.437a21.6,21.6,0,0,1-3.427,1.333,43.6,43.6,0,0,1-4.766,1.166q-2.625.5-5.622.833a54.3,54.3,0,0,1-6,.333,34.38,34.38,0,0,1-13.226-2.332A24.878,24.878,0,0,1,224.568,103.5,39.339,39.339,0,0,1,222.8,91.511Zm37.48-6.33a17.727,17.727,0,0,0-.695-4.109,10.453,10.453,0,0,0-1.821-3.554A9.3,9.3,0,0,0,250,73.965a10.393,10.393,0,0,0-4.605.943,9.589,9.589,0,0,0-3.213,2.5,11.305,11.305,0,0,0-1.981,3.609,24.348,24.348,0,0,0-1.017,4.165Z"
          />
          <path
            fill={color}
            d="M303.412,120.273q-5.482-11.1-11.126-25.986T281.592,61.86h16.921q1.074,4.331,2.528,9.384t3.011,10.216q1.56,5.165,3.12,10.051t3.067,8.884q1.4-4,3.008-8.884t3.171-10.051q1.558-5.163,3.01-10.216t2.527-9.384h16.5q-5.051,17.549-10.688,32.427t-11.115,25.986Z"
          />
          <path
            fill={color}
            d="M342.74,91.511a36.878,36.878,0,0,1,2.3-13.6,29.287,29.287,0,0,1,6.051-9.717,25.093,25.093,0,0,1,8.62-5.886,26.145,26.145,0,0,1,10.013-2q11.993,0,18.955,7.607t6.961,22.377q0,1.444-.107,3.165t-.215,3.054h-36.2a11.076,11.076,0,0,0,4.6,8.107q4.07,3,10.923,3a44.566,44.566,0,0,0,8.622-.833,31.651,31.651,0,0,0,6.907-2.054l2.142,13.437A21.612,21.612,0,0,1,388.9,119.5a43.576,43.576,0,0,1-4.765,1.166q-2.626.5-5.622.833a54.318,54.318,0,0,1-6,.333,34.38,34.38,0,0,1-13.226-2.332A24.883,24.883,0,0,1,344.507,103.5,39.339,39.339,0,0,1,342.74,91.511Zm37.481-6.33a17.728,17.728,0,0,0-.7-4.109,10.433,10.433,0,0,0-1.82-3.554,9.3,9.3,0,0,0-7.765-3.553,10.389,10.389,0,0,0-4.6.943,9.579,9.579,0,0,0-3.213,2.5,11.286,11.286,0,0,0-1.981,3.609,24.445,24.445,0,0,0-1.018,4.165Z"
          />
          <path
            fill={color}
            d="M209.148,104.726a7.04,7.04,0,0,1-1.232-3,22.6,22.6,0,0,1-.321-4V37.283L191.639,40.1v60.964a35.355,35.355,0,0,0,.91,8.385,13.558,13.558,0,0,0,3.427,6.33,16.572,16.572,0,0,0,6.854,4.053,38.574,38.574,0,0,0,11.3,1.555l2.248-13.66a13.912,13.912,0,0,1-4.6-1A6.534,6.534,0,0,1,209.148,104.726Z"
          />
          <path
            fill={color}
            d="M425.466,104.726a7.027,7.027,0,0,1-1.231-3,22.511,22.511,0,0,1-.321-4V37.283L407.957,40.1v60.964a35.355,35.355,0,0,0,.91,8.385,13.567,13.567,0,0,0,3.427,6.33,16.579,16.579,0,0,0,6.854,4.053,38.574,38.574,0,0,0,11.3,1.555l2.249-13.66a13.913,13.913,0,0,1-4.605-1A6.534,6.534,0,0,1,425.466,104.726Z"
          />
          <path
            fill={dotColor}
            d="M460,112.5q0,5-3.052,7.607a10.444,10.444,0,0,1-13.814,0q-3.053-2.609-3.052-7.607t3.052-7.607a10.444,10.444,0,0,1,13.814,0Q460,107.5,460,112.5Z"
          />
        </LogoSvg>
      );
  }
};

export default Logo;
