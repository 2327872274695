export const DropzoneIT = {
  dialog: {
    title: "Carica un file",
  },
  action: {
    withPreview: "Aggiorna il file",
    noPreview: "Carica un nuovo file",
  },
  dragging: {
    withPreview: "Rilascia il file qui per aggiornarlo",
    noPreview: "Rilascia il file qui per caricarlo",
  },
};
