export const TeachersEN = {
  seo: {
    title: "Our teachers",
  },
  header: {
    title: "Introducing to you our teachers",
    description:
      "We have combined our knowledge of expert teachers, the professionalism of consultants and specialized trainers and the experience of professionals in order to make their knowledge available to everyone.",
  },
};
