import { useMutation } from "@apollo/client";
import { Box, Button, LinearProgress, makeStyles } from "@material-ui/core";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import clsx from "clsx";
import { Form, Formik } from "formik";
import i18next from "i18next";
import { useSnackbar } from "notistack";
import { useMemo } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useAuth } from "../../../AuthProvider";
import FormikCaptcha from "../../../components/Forms/fields/captcha";
import FormikTextInput from "../../../components/Forms/fields/input";
import FormikSelect from "../../../components/Forms/fields/select";
import { useParseGqlYupErrorToFormik } from "../../../helpers/useGqlYupErrorToFormik";
import { contactUsMutation } from "../query";

const useClasses = makeStyles((theme) => ({
  root: {},
  formWrapper: {
    margin: theme.spacing(-1),
  },
  fieldWrapper: {
    padding: theme.spacing(1),
  },
  loader: {
    width: "100%",
    height: "10px",
  },
}));

const FormBox = ({ className, ...props }) => {
  const classes = useClasses();

  const { t } = useTranslation();

  const { user } = useAuth();

  const { enqueueSnackbar } = useSnackbar();

  const [contactMutation] = useMutation(contactUsMutation);
  const { getFormikError } = useParseGqlYupErrorToFormik();

  const subjectOptions = [
    {
      value: "SUPPORT",
      label: t("route.contactUs.form.requestType.support"),
    },
    { value: "GENERAL", label: t("route.contactUs.form.requestType.general") },
    { value: "OTHER", label: t("route.contactUs.form.requestType.other") },
  ];

  const initialFormData = useMemo(() => {
    // firstName: user.firstName,
    //   lastName: user.lastName,
    //   email: user.email,

    return {
      subject: "SUPPORT",
      firstName: "",
      lastName: "",
      message: "",
      email: "",
      phone: "",
      captcha: "",
    };
  }, [user]);

  const handleSubmit = (data, { setSubmitting, setErrors }) => {
    contactMutation({
      variables: {
        data,
      },
    })
      .then((res) => {
        if (res?.data?.contactUs) {
          return true;
        }
        return false;
      })
      .then((success) => {
        if (success)
          enqueueSnackbar(t("route.contactUs.form.success"), {
            variant: "success",
          });
        else return Promise.reject();
      })
      .catch((error) => {
        enqueueSnackbar(t(error?.message || "route.contactUs.form.error"), {
          variant: "error",
        });

        if (error) setErrors(getFormikError());
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <Box className={clsx(classes.root, className)} {...props}>
      <Formik
        enableReinitialize
        initialValues={initialFormData}
        // FIXME - re-enable once error handler test are done
        // validationSchema={validationSchema}
        onSubmit={handleSubmit}>
        {({ handleSubmit, isSubmitting, isValid }) => (
          <Form>
            <Box className={classes.formWrapper}>
              <Box className={classes.fieldWrapper}>
                <FormikSelect
                  className={classes.field}
                  variant="outlined"
                  name="subject"
                  label={t("general.subject")}
                  placeholder={t("general.subject")}
                  options={subjectOptions}
                  // hiddenLabel
                  native
                  required
                  fullWidth
                />
              </Box>
              <Box className={classes.fieldWrapper}>
                <FormikTextInput
                  className={classes.field}
                  variant="outlined"
                  name="message"
                  label={t("general.message")}
                  rows={8}
                  inputProps={{ maxLength: 300 }}
                  multiline
                  required
                  fullWidth
                />
              </Box>
              <Box className={classes.fieldWrapper}>
                <FormikTextInput
                  className={classes.field}
                  variant="outlined"
                  name="firstName"
                  label={t("general.firstName")}
                  fullWidth
                  required
                />
              </Box>
              <Box className={classes.fieldWrapper}>
                <FormikTextInput
                  className={classes.field}
                  variant="outlined"
                  name="lastName"
                  label={t("general.lastName")}
                  fullWidth
                  required
                />
              </Box>
              <Box className={classes.fieldWrapper}>
                <FormikTextInput
                  className={classes.field}
                  variant="outlined"
                  name="email"
                  label={t("general.email")}
                  fullWidth
                  required
                />
              </Box>
              <Box className={classes.fieldWrapper}>
                <FormikTextInput
                  className={classes.field}
                  variant="outlined"
                  name="phone"
                  label={t("general.phone")}
                  fullWidth
                />
              </Box>
              <Box className={classes.fieldWrapper}>
                <FormikCaptcha
                  className={classes.field}
                  name="captcha"
                  badge="inline"
                  size="normal"
                  hl={i18next.language}
                  fullWidth
                />
              </Box>
              <Box className={classes.fieldWrapper}>
                <div className={classes.loader}>
                  {isSubmitting && <LinearProgress />}
                </div>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={handleSubmit}
                  fullWidth
                  disabled={!isValid}
                  endIcon={<MailOutlineIcon />}>
                  <Trans i18nKey="route.contactUs.form.button" />
                </Button>
              </Box>
            </Box>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default FormBox;
