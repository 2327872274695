import { makeStyles } from "@material-ui/core";
import clsx from "clsx";
import React from "react";

import Link from "../../Link";

const useClasses = makeStyles((theme) => ({
  /* Styles applied to the root element. */
  root: {},
  list: {
    paddingLeft: 0,
    listStyle: "none",
  },
  item: {
    marginBottom: theme.spacing(0.75),
  },
  link: {},
}));

const FooterLink = ({ className, linkClass, children, ...props }) => {
  const classes = useClasses();

  return (
    <li className={clsx(classes.root, classes.item, className)}>
      <Link
        className={clsx(classes.link, linkClass)}
        underline={"hover"}
        {...props}>
        {children}
      </Link>
    </li>
  );
};

const FooterMenu = ({ children, className, ...props }) => {
  const classes = useClasses();

  return (
    <ul className={clsx(classes.root, classes.list, className)} {...props}>
      {children}
    </ul>
  );
};

export { FooterMenu, FooterLink };
