import {
  Button,
  InputBase,
  useMediaQuery,
  useTheme,
  withStyles,
} from "@material-ui/core";
import ClearRoundedIcon from "@material-ui/icons/ClearRounded";
import SearchIcon from "@material-ui/icons/Search";
import qs from "qs";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useDebounce from "../../helpers/useDebounce";
import clsx from "clsx";
import { colors } from "../../style/colors";
import Box from "@material-ui/core/Box";
import { Trans, useTranslation } from "react-i18next";

export const styles = (theme) => ({
  /* Styles applied to the root element. */
  root: {
    padding: "0 12px",
    "&$small": {
      padding: "0",
    },
    "&$hidden": {
      display: "none",
    },
  },
  wrapper: {
    display: "flex",
    borderRadius: "8px",
    background: colors.softDarkBackground,
    height: "40px",
    transition: "0.3s",
  },
  input: {
    width: "100%",
    paddingLeft: "8px",
  },
  /* Pseudo-class applied to the root element if `small={false}` and not selected. */
  small: {},
  /* Pseudo-class applied to the root element if `hide={true}` */
  hidden: {},
});

const SearchBar = React.forwardRef(function SearchBar(props, ref) {
  const { classes, className, hide, onClose, onChange, ...other } = props;

  // routing
  const { pathname, search } = useLocation();
  const navigate = useNavigate();

  // dimensions
  const {
    breakpoints: { down },
  } = useTheme();
  const isSm = useMediaQuery(down("sm"));

  // query
  const [searching, setSearching] = useState(pathname === "/search");
  const [query, setQuery] = useState(
    qs.parse(search, { ignoreQueryPrefix: true, comma: true })?.data?.[0]
      .value || ""
  );
  const debouncedQuery = useDebounce(query, 300);

  const handleSubmit = (query) => {
    navigate(
      `/search?${qs.stringify({
        data: [{ type: "TEXT", value: query }],
      })}`
    );
  };

  const handleButton = () => {
    if (isSm) {
      if (onClose) onClose();
    } else {
      handleSubmit(debouncedQuery);
    }
  };

  const { t } = useTranslation();

  useEffect(() => {
    if (onChange) onChange(searching);
  }, [searching]);

  useEffect(() => {
    setSearching(pathname === "/search");
    // We are not searching, reset field
    if (pathname !== "/search") {
      setQuery("");
    }
  }, [pathname]);

  useEffect(() => {
    if (debouncedQuery.length > 0) {
      setSearching(true);
    }
    if (searching || debouncedQuery.length > 0) {
      handleSubmit(debouncedQuery);
    }
  }, [debouncedQuery]);

  return (
    <Box
      className={clsx(
        classes.root,
        {
          [classes.small]: isSm,
          [classes.hidden]: hide,
        },
        className
      )}>
      <Box className={clsx(classes.wrapper)}>
        <InputBase
          className={clsx(classes.input)}
          placeholder={t("general.search")}
          value={query}
          onChange={(e) => {
            setQuery(e.target.value);
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              handleSubmit();
            }
          }}
          inputProps={{ "aria-label": "search" }}
        />
        <Button
          style={{
            height: 40,
            ...(isSm ? { maxWidth: 40, minWidth: 40 } : {}),
          }}
          disabled={isSm ? false : query === ""}
          onClick={handleButton}>
          {isSm ? <ClearRoundedIcon /> : <SearchIcon />}
        </Button>
      </Box>
    </Box>
  );
});

export default withStyles(styles, { name: "SearchBar" })(SearchBar);
