import { Menu, withStyles } from "@material-ui/core";
import React from "react";
import clsx from "clsx";
import DropDownItem from "./DropDownItem";

export const styles = (theme) => ({
  /* Styles applied to the root element. */
  root: {},
});

const DropdownMenu = React.forwardRef(function DropdownMenu(props, ref) {
  const {
    classes,
    className,
    open,
    anchorEl,
    onClose,
    value,
    items,
    anchorOrigin,
    paperProps,
    transformOrigin,
    variant,
    ...other
  } = props;

  return (
    <Menu
      ref={ref}
      className={clsx(classes.root, className)}
      anchorEl={anchorEl}
      getContentAnchorEl={null}
      open={open}
      onClose={onClose}
      PaperProps={{
        style: {
          marginTop: 16,
          borderRadius: 24,
          ...paperProps,
        },
      }}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
        anchorOrigin,
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
        ...transformOrigin,
      }}
      {...other}>
      {items.map(({ onClick, value: itemValue, ...props }, index) => {
        if (!itemValue) itemValue = index;
        return (
          <DropDownItem
            key={index}
            onClick={onClick || onClose}
            selected={itemValue === value}
            {...props}
          />
        );
      })}
    </Menu>
  );
});

export default withStyles(styles, { name: "DropdownMenu" })(DropdownMenu);
