export const LiveChatIT = {
  title: "Live chat",

  closed: "La chat della sessione è chiusa",
  loginNeeded: "Per chattare esegui il login",
  greetings: "Che bello averti tra noi. Partecipa alla conversazione...",
  newMessages: "Ci sono nuovi messaggi!",

  actions: {
    open: "Apri la chat",
    close: "Chiudi la chat",
    sendMessage: "Invia un messaggio",
    deleteMessage: "Elimina il messaggio",
  },
};
