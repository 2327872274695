import React from "react";
import clsx from "clsx";
import { withStyles } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";

export const styles = (theme) => ({
  /* Styles applied to the root element. */
  root: {
    fontWeight: theme.typography.fontWeightMedium,
    marginTop: -2,
  },
});

const AlertTitle = React.forwardRef(function AlertTitle(props, ref) {
  const { classes, className, ...other } = props;

  return (
    <Typography
      gutterBottom
      component="div"
      ref={ref}
      className={clsx(classes.root, className)}
      {...other}
    />
  );
});

export default withStyles(styles, { name: "AlertTitle" })(AlertTitle);
