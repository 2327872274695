import deepmerge from "deepmerge";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import moment from "moment";
import { welevelUII18nResources } from "welevel-ui";

import { TRANSLATIONS_IT } from "./it/translations";
import { TRANSLATIONS_EN } from "./en/translations";

export const i18nDefaultNs = "translation";

// Get saved language on local storage
const savedLanguage = localStorage.getItem("i18nextLng");

// TODO: using only one ns (translation) means longer loading time.
// But splitting in single components is mad
export const i18nResources = {
  "en-US": {
    translation: deepmerge(
      TRANSLATIONS_EN,
      welevelUII18nResources.en.translation
    ),
  },
  "it-IT": {
    translation: deepmerge(
      TRANSLATIONS_IT,
      welevelUII18nResources.it.translation
    ),
  },
};

i18n
  .use(initReactI18next)
  .init({
    resources: i18nResources,
    lng: savedLanguage ?? "it-IT",
    fallbackLng: "en-US",
    defaultNS: i18nDefaultNs,
    interpolation: {
      escapeValue: false,
    },
    debug: true,
  })
  .then(() => {
    switch (i18n.language) {
      case "it":
      case "it-IT":
        moment.locale("it-IT");
        break;
      default:
        moment.locale("en-US");
    }
  });
