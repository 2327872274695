import * as React from "react";
import clsx from "clsx";
import { useTheme, withStyles } from "@material-ui/styles";
import { ListItemIcon, MenuItem, useMediaQuery } from "@material-ui/core";
import { Link } from "react-router-dom";
import ListItemText from "@material-ui/core/ListItemText";
import Tooltip from "@material-ui/core/Tooltip";

export const styles = (theme) => ({
  /* Styles applied to the root element. */
  root: {
    "&.Mui-disabled": {
      pointerEvents: "auto",
    },
    "&$selected": {
      color: theme.palette.primary.main,
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.primary.main,
      },
    },
  },
  /* Pseudo-class applied to the root element if selected. */
  selected: {},
});

const DropDownItem = React.forwardRef(function DropDownItem(props, ref) {
  const {
    classes,
    className,
    icon,
    label,
    tooltip,
    onClick,

    selected,
    disabled,
    ...other
  } = props;

  const {
    breakpoints: { down },
  } = useTheme();
  const isXs = useMediaQuery(down("xs"));

  return (
    <Tooltip
      title={tooltip ? tooltip({ active: selected, disabled }) : ""}
      aria-label={tooltip ? tooltip({ active: selected, disabled }) : ""}
      disableFocusListener={
        !tooltip || !tooltip({ active: selected, disabled }) || !isXs
      }
      disableHoverListener={
        !tooltip || !tooltip({ active: selected, disabled }) || !isXs
      }
      disableTouchListener={
        !tooltip || !tooltip({ active: selected, disabled }) || !isXs
      }>
      <MenuItem
        ref={ref}
        onClick={onClick}
        component={other.to ? Link : undefined}
        className={clsx(
          classes.root,
          {
            [classes.selected]: selected,
          },
          className
        )}
        disabled={disabled}
        {...other}>
        {icon && <ListItemIcon>{icon({ active: selected })}</ListItemIcon>}
        <ListItemText primary={label} />
      </MenuItem>
    </Tooltip>
  );
});
export default withStyles(styles, { name: "DropDownItem" })(DropDownItem);
