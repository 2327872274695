export const SettingsIT = {
  seo: {
    title: "Impostazioni | universo corsi",
  },

  toolbar: {
    dashboard: "Dashboard",
    removeCover: "Rimuovi copertina",
  },

  dialog: {
    removeCover: {
      message: "Rimuovi immagine di copertina",
      confirmation: "Sei sicuro di voler rimuovere l'immagine di copertina?",
    },
  },

  anagraficaTitle: "Anagrafica",
  marketingTitle: "Su di te",
  notificationTitle: "Notifiche & Newsletter",
  accountTitle: "Account",

  anagrafica: {
    billingType: "Tipologia",
    billingTypeOptions: {
      label: "Tipologia",
      personal: "Privato ",
      freelancer: "Libero Professionista",
      signlebiz: "Ditta individuale",
      business: "Azienda",
    },

    agencyName: "Regione Sociale",
    sdi: "Codice destinatario (SDI/CUD)",

    address: "Indirizzo",
    billing: "Fatturazione",
  },

  marketing: {
    companyRole: "Ruolo in azienda",
    companyDivision: "Reparto Aziendale",
    companyType: "Tipologia di azienda",
    livingProvince: "Provincia dove vivi",
  },

  notification: {
    improveService: "Migliorare il servizio",
    newContentAndUpdates: "Nuovi contenuti e aggiornamenti",

    improveServiceAcceptance:
      "Acconsento al trattamento dati per elaborazione automatica dei dati, per finalità statistiche e per miglioramento del servizio",
    newContentAndUpdatesAcceptance:
      "Acconsento al trattamento dati per finalità di marketing diretto",
  },

  changeEmail: {
    title: "Agiorna indirizzo email",
    body: "Vuoi modificare l'<b>indirizzo email.</b>?<br />Ti invieremo una mail con un link per confermare il nuovo indirizzo.",
  },

  passwordReset: {
    title: "Cambia password",
    body: "Vuoi cambiare la tua <b>password</b> di accesso? <br />Ti invieremo una mail con un link che ti porterà alla pagina in cui potrai cambiarla.",
    button: "Modifica password",
    confirmationDialog:
      "Riceverai una mail con un link di conferma, sei sicuro di voler continuare?",
  },

  deleteAccount: {
    title: "Zona pericolosa",
    body: "Desideri eliminare <b>tutti</b> i tuoi dati di <b>per sempre</b>?",
    button: "Elimina Account",
    confirmationDialog:
      "Inserisci la password corrente per confermare l'operazione.",
  },

  testVideoPlayer: {
    title: "Test nuovo player video",
    body: "Stiamo testando un nuovo player video per risolvere alcuni problemi di fruizione dei contenuti.<br />Questo player è ancora in prova e <b>potrebbe avere problemi</b> e <b>disattivarsi automaticamente</b>.<br /><b>La tua opinione è importante</b>, facci sapere come ti trovi scrivendo a <a>nessuno@nessuno.nessuno</a>",
    switch: "Prova il nuovo player video",
  },

  updating: {
    error: "Si è verificato un errore durante l'aggiornamento del profilo",
    success: "Profilo aggiornato con successo",
  },
};
