export const PlaylistEN = {
  ticket: {
    vat: "VAT Included",
    bought: "You have access to this studyplan!",
    unlimited: "Included with Welevel Unlimited",

    duration_one: "{{count}} course · {{duration}}",
    duration_other: "{{count}} courses · {{duration}}",

    quiz: "Final Quiz",

    certificate: "Skills certification",

    exclusive: "Welevel Esclusive",
    exclusiveInfo:
      "This Study plan can only be found on Welevel and on no other site",

    buttons: {
      chosen: "You have already chosen this study plan!",
      choose: "Choose the study plan",
    },
  },

  checkout: {
    title: "Buy Study Plan",
    subscription: "View all Welevel courses - Subscribe",
    buy: "Buy this study plan for {{amount}}",
    free: "Watch Now - Login / Register",
  },
};
