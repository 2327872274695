export const ReviewFormIT = {
  newReview: {
    title: "Congratulazioni!",
    description: "Hai completato tutte le lezioni",
    ratingField: {
      label: "Come valuti questo corso?",
      error: "Aggiungi una valutazione in stelle",
    },
    contentField: {
      label: "Breve recensione",
      placeholder: "Scrivi una breve recensione...",
    },
    cancel: "Salta",
    sendReview: "Invia recensione",
    error: "Non è stato possibile inviare la recensione. Riprova più tardi",
  },
  reviewSent: {
    title: "Grazie per l'aiuto!",
    description: "La tua recensione è stata inviata",
    cta: "Continua la formazione",
  },
};
