export const Cards = {
  event: {
    pro: "Pro",
    reserved: "Reserved",
    free: "Free",
    premium: "Extra",
    liveNow: "LIVE now",
    rewatchEvent: "Rewatch the event",
  }
};
