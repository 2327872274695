import * as React from "react";
import { Avatar, Hidden, withStyles } from "@material-ui/core";
import { useLocation } from "react-router-dom";
import Tooltip from "@material-ui/core/Tooltip";
import clsx from "clsx";
import ButtonBase from "@material-ui/core/ButtonBase";
import Box from "@material-ui/core/Box";
import { colors } from "../../../../../style/colors";
import { Trans, useTranslation } from "react-i18next";

export const styles = (theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    [theme.breakpoints.down("xs")]: {
      marginLeft: theme.spacing(0.25),
      marginRight: theme.spacing(0.25),
    },
  },
  button: {
    textAlign: "center",
    flex: "0 0 auto",
    padding: theme.spacing(1),
    borderRadius: "800px",
    overflow: "visible", // Explicitly set the default value to solve a bug on IE 11.
    color: "rgba(0,0,0,0.87)",
    transition: theme.transitions.create("background-color", {
      duration: theme.transitions.duration.shortest,
    }),
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.04)",
    },
    [theme.breakpoints.down("xs")]: {
      borderRadius: "50%",
      padding: theme.spacing(1),
    },
    "&$active": {
      [theme.breakpoints.up("sm")]: {
        backgroundColor: "rgba(0, 0, 0, 0.87)",
        color: "white",
        "&:hover": {
          backgroundColor: "rgba(0, 0, 0, 0.67)",
        },
      },
    },
    "&$guest": {
      border: `1px solid ${colors.textDisabled}`,
      [theme.breakpoints.down("xs")]: {
        border: 0,
      },
    },
    "&$user": {
      [theme.breakpoints.up("sm")]: {
        backgroundColor: colors.softDarkBackground,
        "&:hover": {
          backgroundColor: colors.softDarkBackgroundHover,
        },
      },
    },
  },
  avatar: {
    height: theme.spacing(4) + "px!important",
    width: theme.spacing(4) + "px!important",
    "&$active": {
      [theme.breakpoints.down("xs")]: {
        backgroundColor: "rgba(0, 0, 0, 0.87)",
      },
    },
    "&$user": {
      [theme.breakpoints.down("xs")]: {
        backgroundColor: colors.softDarkBackground,
      },
    },
    "& img": {
      width: "50px",
      height: "50px",
    },
  },
  label: {
    width: "100%",
    display: "flex",
    alignItems: "inherit",
    justifyContent: "inherit",
    fontFamily: "Inter",
    fontWeight: "600",
    fontSize: theme.typography.pxToRem(14),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    whiteSpace: "nowrap",
    textTransform: "none",

    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  /* Pseudo-class applied to the root element if `active={false}` and not selected. */
  active: {},
  /* Pseudo-class applied to the root element if `guest={false}` and not selected. */
  guest: {},
  /* Pseudo-class applied to the root element if `user={false}` and not selected. */
  user: {},
});

const NavAvatarButton = React.forwardRef(function NavAvatarButton(props, ref) {
  const { classes, className, onClick, user, ...other } = props;
  const { pathname } = useLocation();

  const isGuest = !Boolean(user);
  const isActive = pathname === "/me";

  const { t } = useTranslation();

  return (
    <Box
      className={clsx(
        classes.root,
        {
          [classes.active]: pathname === "/me",
          [classes.guest]: isGuest,
        },
        className
      )}>
      <Tooltip
        title={
          isGuest
            ? t("layout.navbar.avatar.tooltip.guest")
            : t("layout.navbar.avatar.tooltip.account")
        }>
        <ButtonBase
          onClick={onClick}
          className={clsx(
            classes.button,
            {
              [classes.active]: isActive,
              [classes.guest]: isGuest,
              [classes.user]: !isGuest && !isActive,
            },
            className
          )}
          ref={ref}
          centerRipple
          {...other}>
          <Avatar
            className={clsx(
              classes.avatar,
              {
                [classes.active]: isActive,
                [classes.user]: !isGuest && !isActive,
              },
              className
            )}
            alt={
              isGuest ? t("layout.navbar.avatar.guest") : user?.firstName || ""
            }
            src={isGuest ? null : user?.image?.cdnUrl || ""}
          />

          <span className={classes.label}>
            {isGuest ? (
              <>
                <Trans i18nKey="layout.navbar.avatar.login" />
                <Hidden smDown>
                  <span>{" / "}</span>
                  <Trans i18nKey="layout.navbar.avatar.register" />
                </Hidden>
              </>
            ) : (
              <>{user?.firstName || ""}</>
            )}
          </span>
        </ButtonBase>
      </Tooltip>
    </Box>
  );
});

export default withStyles(styles, { name: "NavAvatarButton" })(NavAvatarButton);
