export const AskQuestionModalIT = {
  title: "Hai una domanda per <span>{{name}}</span>?",
  subtitle: "Scrivi il tuo messaggio.",
  privacyPolicy:
    "Ho letto l'informativa e acconsento al trattamento dei dati per le finalità previste dall'<url>informativa sulla privacy</url>.",
  form: {
    errors: {
      tooShort: "Troppo corto",
      tooLong: "Troppo lungo",
      required: "Campo necessario",
      invalidEmail: "Email non valida",
      mustAgree: "Devi accettare i termini",
    },
    fields: {
      privacyLabel:
        "Acconsento al trattamento dati per elaborazione automatica dei dati, per finalità statistiche e per miglioramento del servizio.",
      marketingLabel:
        "Acconsento al trattamento dati per finalità di marketing diretto.",
    },
    submit: "Invia Richiesta",
  },
  snackbar: {
    askQuestion: {
      success: "Richiesta inviata con successo",
      error: "C'è stato un errore nel invio della richiesta",
    },
  },
};
