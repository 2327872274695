import { IconButton, Paper, Typography } from "@material-ui/core";
import { CloseRounded, ErrorRounded } from "@material-ui/icons";
import moment from "moment";
import { darken, transparentize } from "polished";
import { useEffect, useState } from "react";
import styled from "styled-components";

type UscreenBannerProperties = {};

const Card = styled(Paper).attrs({
  elevation: 8,
})<{ $isVisible: boolean }>`
  position: fixed;
  bottom: ${({ theme }) => theme.spacing(1.5)}px;
  left: ${({ theme }) => theme.spacing(1.5)}px;
  right: ${({ theme }) => theme.spacing(1.5)}px;
  z-index: ${({ theme }) => theme.zIndex.snackbar};
  padding: ${({ theme }) => theme.spacing(0.5)}px;
  opacity: ${({ $isVisible }) => ($isVisible ? 1 : 0)};
  pointer-events: ${({ $isVisible }) => ($isVisible ? "auto" : "none")};
  transition: opacity 0.3s ease;
  transform: translateY(${({ $isVisible }) => ($isVisible ? "0" : "100%")});
`;

const Wrapper = styled.div`
  display: flex;
  z-index: ${({ theme }) => theme.zIndex.snackbar};
  min-height: 80px;
  align-items: start;
  padding: ${({ theme }) => theme.spacing(2, 2)};
  gap: ${({ theme }) => theme.spacing(2)}px;
  border-radius: ${({ theme }) =>
    theme.shape.borderRadius - theme.spacing(0.5)}px;
  background: linear-gradient(
    to bottom,
    ${({ theme }) => transparentize(0.44, theme.palette.warning.main)},
    ${({ theme }) => transparentize(0.84, theme.palette.warning.main)}
  );
  color: ${({ theme }) => darken(0.4, theme.palette.warning.main)};
`;

const TextWrapper = styled.div`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(0.5)}px;
  margin-right: ${({ theme }) => theme.spacing(1)}px;

  * {
    line-height: 1.3;
  }

  strong {
    font-weight: 600;
  }
`;

const IconWrapper = styled.div`
  flex: 0 0 auto;
  width: 34px;
  height: 34px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.palette.background.paper};
  display: flex;
  color: ${({ theme }) => theme.palette.warning.main};
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 2px 4px 0px
    ${({ theme }) => transparentize(0.44, theme.palette.warning.main)};

  svg {
    display: block;
    width: 32px;
    height: 32px;
  }
`;

const UscreenBanner: React.FC<UscreenBannerProperties> = () => {
  const [isVisible, setIsVisible] = useState<boolean>(false);

  const localStorageName = "uscreen_banner_hide_time";

  const handleHide = () => {
    setIsVisible(false);
    localStorage.setItem(localStorageName, new Date().getTime().toString());
  };

  useEffect(() => {
    const hideTime = localStorage.getItem(localStorageName);
    if (hideTime) {
      const currentTime = moment();
      const timeDifference = currentTime.diff(
        moment(Number(hideTime)),
        "hours"
      );
      // If 24 hours have passed since the component was hidden, show it
      if (timeDifference >= 8) {
        handleHide();
      } else {
        setIsVisible(false);
      }
    } else {
      // If the component has never been hidden, show it
      setIsVisible(true);
    }
  }, []);

  return (
    <Card $isVisible={isVisible}>
      <Wrapper>
        <IconWrapper>
          <ErrorRounded color="inherit" />
        </IconWrapper>
        <TextWrapper>
          <Typography variant="h4" color="inherit">
            Importante: Welevel sta per cambiare volto!
          </Typography>

          <Typography color="inherit">
            Fra qualche settimana riceverai un&apos;<strong>email</strong> con
            la <strong>password</strong> per accedere alla nuova versione di
            welevel.
          </Typography>
        </TextWrapper>
        <IconButton onClick={handleHide} size="small" color="inherit">
          <CloseRounded />
        </IconButton>
      </Wrapper>
    </Card>
  );
};

export default UscreenBanner;
