export const EventIT = {
  allEvents: "Tutti gli eventi",
  backToEvent: "Torna all'Evento",
  speaker_one: "Docente",
  speaker_other: "Docenti",
  ticket: {
    liveEvent: "LIVE adesso",
    pastEvent: "Evento Terminato",
    scheduledEvent: "Evento in programma {{date}}",

    bought: "Hai già accesso a questo evento",
    unlimited: "Gratis per abbonati a Welevel Unlimited",

    partner: "Organizzatore",
  },
  booking: {
    reserved: {
      title: "L'evento è riservato",
      subtitle: "Ti verrà richiesta una password",
    },
    live: {
      title: "Guarda",
      subtitle: "L'evento in corso, entra subito!",
    },
    past: {
      title: "Rivedi l'evento",
      subtitle: "L'evento è terminato ma puoi rivederlo subito",
    },
    form: {
      title: "Rispondi alle domande per prenotare il posto",
      button: "Prenota",
    },
    book: "Prenota il tuo posto",
    booked: "Posto prenotato",
  },
  checkout: {
    title: "Acquista evento",
    subscription: "Guarda tutti gli eventi Welevel - Abbonati",
    buy: "Acquista questo evento a {{amount}}",
    free: "Guarda ora - Accedi/Registrati",
  },
  agenda: {
    filters: {
      rooms: "Filtra le sale",
      topics: "Filtra gli argomenti",
      teachers: "Filtra i docenti",
    },
    noSession: {
      title: "Non trovo nulla programmato per questo giorno.",
      subtitle: "Prova a cambiare i filtri...",
    },
    watchLive: "Guarda in diretta",
  },
};
