export const DropzoneEN = {
  dialog: {
    title: "Upload a file",
  },
  action: {
    withPreview: "Update the file",
    noPreview: "Upload a new file",
  },
  dragging: {
    withPreview: "Release the file here to update it",
    noPreview: "Release the file here to upload it",
  },
};
