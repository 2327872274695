export const LoginEN = {
  title: "Sign In",

  // Social Login
  google: "Sign in with Google",
  facebook: "Sign in with Facebook",
  linkedin: "Sign in with Linkedin",

  // Form
  forgotPassword: "Forgotten password?",
  recoverPassword: "Retrieve password",
  button: "Sign In",

  // Validation
  errors: {
    missing: "Data required",
    email: "Invalid email format",
    password: "Password too short",
  },

  alternative: "Or",
  signUp: "Are you new? <b>Sign Up</b>",
};
