import * as React from "react";
import clsx from "clsx";
import { useTheme, withStyles } from "@material-ui/styles";
import { fade, useMediaQuery } from "@material-ui/core";
import ButtonBase from "@material-ui/core/ButtonBase";
import Box from "@material-ui/core/Box";
import Tooltip from "@material-ui/core/Tooltip";

export const styles = (theme) => ({
  /* Styles applied to the root element. */
  root: {
    display: "inline-flex",
    position: "relative",
    alignItems: "center",
    justifyContent: "center",
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    [theme.breakpoints.down("xs")]: {
      marginLeft: theme.spacing(0.25),
      marginRight: theme.spacing(0.25),
    },
  },
  button: {
    transition: theme.transitions.create(["color"], {
      duration: theme.transitions.duration.short,
    }),
    flex: "1",
    padding: "6px 12px 8px",
    minWidth: 80,
    maxWidth: 168,
    color: theme.palette.text.secondary,
    borderRadius: "12px",
    [theme.breakpoints.down("xs")]: {
      textAlign: "center",
      flex: "0 0 auto",
      fontSize: theme.typography.pxToRem(24),
      padding: 12,
      borderRadius: "50%",
      minWidth: "unset",
      maxWidth: "unset",
      overflow: "visible", // Explicitly set the default value to solve a bug on IE 11.
    },
    "&:hover": {
      backgroundColor: fade(
        theme.palette.action.active,
        theme.palette.action.hoverOpacity
      ),
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        backgroundColor: "transparent",
      },
    },
    "&$disabled": {
      backgroundColor: "transparent",
      color: theme.palette.action.disabled,
    },
  },
  /* Styles applied to the span element that wraps the icon and label. */
  wrapper: {
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    flexDirection: "column",
  },
  /* Styles applied to the label's span element. */
  label: {
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(14),
    opacity: 1,
    transition: "font-size 0.2s, opacity 0.2s",
    transitionDelay: "0.1s",
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
    "&$iconOnly": {
      display: "none",
      transitionDelay: "0s",
    },
    "&$noWrap": {
      whiteSpace: "nowrap",
    },
  },
  /* Pseudo-class applied to the root element if selected. */
  selected: {},
  /* Pseudo-class applied to the root element if `showLabel={false}` and not selected. */
  iconOnly: {},
  /* Pseudo-class applied to the root element if `noWrap={true}`. */
  noWrap: {},
  // Variants
  simple: {},
  color: {
    "&$selected": {
      color: theme.palette.primary.main,
    },
  },
  line: {
    "&$selected": {
      color: theme.palette.primary.main,
      borderColor: theme.palette.primary.main,
      borderBottom: "3px solid",
      paddingTop: "3px",
    },
  },
  simpleButton: {},
  colorButton: {
    "&$selected": {
      color: theme.palette.primary.main,
    },
  },
  lineButton: {
    "&$selected": {
      color: theme.palette.primary.main,
    },
  },
  simpleLabel: {},
  colorLabel: {
    "&$selected": {
      color: theme.palette.primary.main,
    },
  },
  lineLabel: {
    "&$selected": {
      color: theme.palette.primary.main,
    },
  },
});

const ToolbarMenuItem = React.forwardRef(function NavIconItemMenu(props, ref) {
  const {
    classes,
    className,
    variant = "line",
    icon,
    label,
    onClick,
    selected,
    showLabel,
    value,
    href,
    noWrap,
    ...other
  } = props;

  const {
    breakpoints: { down },
  } = useTheme();
  const isXs = useMediaQuery(down("xs"));

  const handleChange = (event) => {
    if (onClick) {
      onClick(event);
    }
  };

  return (
    <Box
      ref={ref}
      className={clsx(
        classes.root,
        classes[variant],
        {
          [classes.selected]: selected,
          [classes.iconOnly]: !showLabel && !selected,
        },
        className
      )}>
      <Tooltip
        title={label}
        aria-label={label}
        disableFocusListener={!isXs}
        disableHoverListener={!isXs}
        disableTouchListener={!isXs}>
        <ButtonBase
          className={clsx(classes.button, classes[`${variant}Button`], {
            [classes.selected]: selected,
            [classes.iconOnly]: !showLabel && !selected,
          })}
          onClick={handleChange}
          {...other}>
          <span className={classes.wrapper}>
            {icon instanceof Function ? icon(selected) : icon}
            <span
              className={clsx(classes.label, classes[`${variant}Label`], {
                [classes.selected]: selected,
                [classes.iconOnly]: !showLabel && !selected,
                [classes.noWrap]: noWrap,
              })}>
              {label}
            </span>
          </span>
        </ButtonBase>
      </Tooltip>
    </Box>
  );
});

export default withStyles(styles, { name: "NavIconItemMenu" })(ToolbarMenuItem);
