export const UserIT = {
  sidecard: {
    question: "Hai una domanda per <span>{{name}}</span>?",
    sendRequest: "Invia richiesta",
  },
  sections: {
    events: {
      title: "Eventi con <span>{{name}}</span>",
      empty: "Nessun evento",
    },
    courses: {
      title: "Corsi di <span>{{name}}</span>",
      empty: "Nessun corso",
    },
  },
};
