import * as React from "react";
import clsx from "clsx";
import { useTheme } from "@material-ui/styles";
import { useMediaQuery, withStyles } from "@material-ui/core";

export const styles = (theme) => ({
  /* Styles applied to the root element. */
  root: {
    display: "flex",
    justifyContent: "center",
    marginLeft: theme.spacing(-1),
    marginRight: theme.spacing(-1),
    height: "100%",
    [theme.breakpoints.down("xs")]: {
      marginLeft: theme.spacing(-0.25),
      marginRight: theme.spacing(-0.25),
    },
  },
});

const NavIconNavigation = React.forwardRef(function NavIconNavigation(
  props,
  ref
) {
  const {
    children,
    classes,
    className,
    component: Component = "div",
    showLabels = false,
    value,
    variant = 'line',
    ...other
  } = props;

  return (
    <Component className={clsx(classes.root, className)} ref={ref} {...other}>
      {React.Children.map(children, (child, childIndex) => {
        if (!React.isValidElement(child)) {
          return null;
        }

        const childValue =
          child.props.value === undefined ? childIndex : child.props.value;

        return React.cloneElement(child, {
          selected: childValue === value,
          showLabel:
            child.props.showLabel !== undefined
              ? child.props.showLabel
              : showLabels,
          value: childValue,
          variant,
        });
      })}
    </Component>
  );
});

export default withStyles(styles, { name: "NavIconNavigation" })(
  NavIconNavigation
);
