import { useState } from "react";

const Store = () => {
  const [user, setUser] = useState(null);

  return {
    user,
    setUser: async newUser => {
      // posting the user to the instant-chat services
      const iframeUser = {};
      if (newUser.id) {
        iframeUser.id = newUser.id;
      }
      if (Object.keys(newUser).includes("isTeacher")) {
        iframeUser.role = newUser.isTeacher ? "docente" : "studente";
      }
      if (
        Object.keys(iframeUser).length > 0 &&
        document.getElementById("vaIframe")
      ) {
        const frame = document.getElementById("vaIframe").contentWindow;
        await frame.postMessage(iframeUser, "*");
      }

      setUser(newUser);
    }
  };
};

export default Store;
