import React, { useState } from "react";
import { Box, Button, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Form, Formik } from "formik";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import { useMutation } from "@apollo/client";
import { subscribeMutation } from "./query";
import { colors, darkColors } from "../../style/colors";
import StyledCheckbox from "../Forms/Components/StyledCheckbox";
import { TextLink } from "../Link";
import { Trans, useTranslation } from "react-i18next";
import { useGTMDispatch } from "@elgorditosalsero/react-gtm-hook";

const useStyles = makeStyles({
  textField: {
    color: colors.textWhitePrimary,
    "& .MuiInput-underline:after": {
      borderBottomColor: colors.textWhitePrimary,
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: colors.textWhitePrimary,
        // borderBottomRightRadius: 0,
        // borderTopRightRadius: 0
      },
      "&:hover fieldset": {
        borderColor: colors.textWhitePrimary,
      },
      "&.Mui-focused fieldset": {
        borderColor: colors.textWhitePrimary,
      },
    },
  },
  input: {
    color: colors.textWhitePrimary,
  },
  InputLabel: {
    color: colors.textWhitePrimary,
    "&.Mui-focused": {
      color: colors.textWhitePrimary,
    },
  },
});

export const MailchimpSubscribe = ({ tags = [], id }) => {
  const classes = useStyles();
  const [subscribe] = useMutation(subscribeMutation);

  const [success, setSuccess] = useState(false);
  // const sendDataToGTM = useGTMDispatch();

  const { t, i18n } = useTranslation();

  return (
    <Formik
      initialValues={{
        FNAME: "",
        LNAME: "",
        email: "",
        privacy: false,
      }}
      validate={(values) => {
        const errors = {};
        if (!values.FNAME) {
          errors.FNAME = t("layout.footer.newsletter.errors.missing");
        }
        if (!values.LNAME) {
          errors.LNAME = t("layout.footer.newsletter.errors.missing");
        }
        if (!values.privacy) {
          errors.privacy = t("layout.footer.newsletter.errors.privacy");
        }
        if (!values.email) {
          errors.email = t("layout.footer.newsletter.errors.missing");
        } else if (
          !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
        ) {
          errors.email = t("layout.footer.newsletter.errors.email");
        }
        return errors;
      }}
      onSubmit={async ({ email, privacy, ...values }, { setSubmitting }) => {
        setSubmitting(true);
        const { data } = await subscribe({
          variables: {
            id,
            email,
            merge_fields: Object.keys(values).length ? values : null,
            tags: tags.length ? tags : null,
            language: i18n.language.split("-")[0],
          },
        });
        // data && sendDataToGTM({ event: "iscrizione_newsletter" });
        setSuccess(data);
      }}>
      {({
        isSubmitting,
        errors,
        handleChange,
        handleBlur,
        touched,
        values,
        setValues,
      }) => (
        <Form>
          <Box marginBottom={2}>
            <TextField
              type="text"
              name="FNAME"
              variant="outlined"
              label={t("general.firstName")}
              placeholder="Marco"
              onChange={handleChange}
              onBlur={handleBlur}
              fullWidth
              className={classes.textField}
              InputProps={{
                className: classes.input,
              }}
              InputLabelProps={{
                className: classes.InputLabel,
              }}
            />
            {errors.FNAME && touched.FNAME && (
              <Box
                paddingTop={0.5}
                paddingLeft={0.25}
                style={{
                  fontSize: "0.785rem",
                }}>
                <div
                  style={{
                    color: darkColors.error,
                    marginBottom: 4,
                  }}>
                  {errors.FNAME}
                </div>
              </Box>
            )}
          </Box>
          <Box marginBottom={2}>
            <TextField
              type="text"
              name="LNAME"
              variant="outlined"
              label={t("general.lastName")}
              placeholder="Rossi"
              onChange={handleChange}
              onBlur={handleBlur}
              fullWidth
              className={classes.textField}
              InputProps={{
                className: classes.input,
              }}
              InputLabelProps={{
                className: classes.InputLabel,
              }}
            />
            {errors.LNAME && touched.LNAME && (
              <Box
                paddingTop={0.5}
                paddingLeft={0.25}
                style={{
                  fontSize: "0.785rem",
                }}>
                <div
                  style={{
                    color: darkColors.error,
                    marginBottom: 4,
                  }}>
                  {errors.LNAME}
                </div>
              </Box>
            )}
          </Box>
          <Box marginBottom={2}>
            <TextField
              type="text"
              name="email"
              variant="outlined"
              label={t("general.email")}
              placeholder="marco.rossi@email.com"
              onChange={handleChange}
              onBlur={handleBlur}
              fullWidth
              className={classes.textField}
              InputProps={{
                className: classes.input,
              }}
              InputLabelProps={{
                className: classes.InputLabel,
              }}
            />
            {errors.email && touched.email && (
              <Box
                paddingTop={0.5}
                paddingLeft={0.25}
                style={{
                  fontSize: "0.785rem",
                }}>
                <div
                  style={{
                    color: darkColors.error,
                    marginBottom: 4,
                  }}>
                  {errors.email}
                </div>
              </Box>
            )}
          </Box>
          <Box marginBottom={2}>
            <StyledCheckbox
              color="#fff"
              label={
                <Trans
                  i18nKey="layout.footer.newsletter.labels.privacy"
                  components={{
                    url: (
                      <TextLink
                        target="_blank"
                        to="https://welevel.s3.eu-central-1.amazonaws.com/website/privacy-cookies-policies.pdf"
                      />
                    ),
                  }}
                />
              }
              value={values.privacy}
              name="privacy"
              checked={values.privacy}
              onChange={(name, privacy) => {
                setValues({ ...values, privacy: !privacy });
              }}
            />
          </Box>
          <Button
            disabled={isSubmitting || Object.keys(errors).length > 0}
            type="submit"
            fullWidth
            endIcon={<MailOutlineIcon />}
            style={{
              backgroundColor: colors.textWhitePrimary,
            }}>
            <Trans i18nKey="general.subscribe" />
          </Button>

          {isSubmitting && (
            <Box
              paddingTop={0.5}
              paddingLeft={0.25}
              style={{
                fontSize: "0.785rem",
              }}>
              {isSubmitting && (
                <div
                  style={{
                    color: colors.textWhiteSecondary,
                    marginBottom: 4,
                  }}>
                  <Trans i18nKey="layout.footer.newsletter.processing" />
                </div>
              )}
              {!success ? (
                <div
                  style={{
                    color: darkColors.error,
                    marginBottom: 4,
                  }}>
                  <Trans i18nKey="layout.footer.newsletter.error" />
                </div>
              ) : (
                <div
                  style={{
                    color: colors.success,
                    marginBottom: 4,
                  }}>
                  <Trans i18nKey="layout.footer.newsletter.success" />
                </div>
              )}
            </Box>
          )}
        </Form>
      )}
    </Formik>
  );
};
