import gql from "graphql-tag";

export const subscribeMutation = gql`
  mutation subscribeMutation(
    $id: ID!
    $merge_fields: MergeFields!
    $email: String!
    $tags: [String]
    $language: String!
  ) {
    subscribeToNewsletter(
      id: $id
      tags: $tags
      merge_fields: $merge_fields
      email: $email
      language: $language
    )
  }
`;
