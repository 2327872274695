export const ExploreIT = {
  seo: {
    title: "Scopri i nostri corsi online di formazione alberghiera",
    description:
      "Universo Corsi è la prima piattaforma online di corsi di formazione sul Turismo con oltre 1500 contenuti formativi dei migliori formatori italiani",
  },

  carousel: {
    topics: {
      title: "Argomenti",
    },

    featuredEvents: {
      title: "Eventi e lezioni live",
      description:
        "Vere e proprie lezioni d'aula virtuali tenute da professionisti del settore hospitality",
    },

    mostViewedCourses: {
      title: "Corsi più popolari",
      description: "I più visti e cliccati dagli utenti",
    },

    mostRecentCourses: {
      title: "Novità",
      description:
        "Ogni settimana nuovi video corsi e contenuti sempre aggiornati",
    },

    masterclasses: {
      title: "Masterclass",
      description:
        "Le esperienze e gli insegnamenti dei professionisti di grande successo",
    },

    playlist: {
      title: "Percorsi formativi certificati",
      description:
        "Un insieme di corsi selezionati per migliorare le tue competenze lavorative",
    },
  },
};
