export const PasswordRecoveryEN = {
  title: "Retrieve Password",
  button: "Send recovery email",

  // Validation
  errors: {
    missing: "Data required",
    email: "Invalid email format",
    notRegistered: "Email not registered",
    error: "There was a problem",
  },

  success: "Request sent!",

  signIn: "Did you retrieve the password? <b>Sign In</b>",
};
