export const AskQuestionModalEN = {
  title: "Do you have a question for <span>{{name}}</span>?",
  subtitle: "Write a message.",
  privacyPolicy:
    "I have read the notice and consent to the processing of data for the purposes set forth in the <url>privacy policy</url>.",
  form: {
    errors: {
      tooShort: "Too short",
      tooLong: "Too long",
      required: "Field required",
      invalidEmail: "Invalid Email",
      mustAgree: "Must agree to the terms",
    },
    fields: {
      privacyLabel:
        "I consent to data processing for automatic data processing, statistical purposes and service improvement.",
      marketingLabel:
        "I consent to data processing for direct marketing purposes.",
    },
    submit: "Send Request",
  },
  snackbar: {
    askQuestion: {
      success: "Request sent successfully",
      error: "There was an error sending the request",
    },
  },
};
