import { useState } from "react";

// Our hook
export default function LoginStore() {
  const [open, setOpen] = useState<false | "login" | "register" | "password">(
    false
  );
  // case where redirect after login or registration is required
  const [redirectForLogin, setRedirectForLogin] = useState("");
  const [redirectForRegistration, setRedirectForRegistration] = useState("");
  // case where payment modal must open after registration
  const [isRegistrationOver, setIsRegistrationOver] = useState(false);
  const [isPaymentNeeded, setIsPaymentNeeded] = useState(false);
  return {
    open,
    setOpen,
    redirectForLogin,
    setRedirectForLogin,
    redirectForRegistration,
    setRedirectForRegistration,
    isRegistrationOver,
    setIsRegistrationOver,
    isPaymentNeeded,
    setIsPaymentNeeded,
  };
}
