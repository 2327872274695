import React from "react";
import { Box, makeStyles, Typography } from "@material-ui/core";
import { Trans } from "react-i18next";
import Svg from "../../../assets/images/illustrations/bamboccino_contatti.svg";
import clsx from "clsx";

const useClasses = makeStyles((theme) => ({
  root: {
    height: "100%",
  },
  title: {
    marginBottom: theme.spacing(2),
  },
  row: {
    marginBottom: theme.spacing(1),
  },
  imageWrapper: {
    display: "flex",
    flexGrow: 1,
    justifyContent: "center",
    alignItems: "end",
  },
  img: {
    maxWidth: "75%",
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(-2),
  },
}));

const HeadBox = ({ className, ...props }) => {
  const classes = useClasses();

  return (
    <Box className={clsx(classes.root, className)} {...props}>
      <Box>
        <Typography variant="h3" className={classes.title}>
          <Trans i18nKey="route.contactUs.head.title" />
        </Typography>
        <Typography variant="h4" className={classes.row}>
          <Trans i18nKey="route.contactUs.head.subtitle" />
        </Typography>
      </Box>
      <Typography className={classes.row}>
        <Trans i18nKey="route.contactUs.head.body" />
      </Typography>
      <Box className={classes.imageWrapper}>
        <img src={Svg} alt="Contact us img" className={classes.img} />
      </Box>
    </Box>
  );
};

export default HeadBox;
