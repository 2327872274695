export const RequestConfirmEmailEN = {
  seo: {
    title: "Confirm email",
  },
  title: "Confirm your email",
  description:
    "We have sent you a confirmation email to the mailbox you set up when you registered." +
    "<br /><br />" +
    "<b>Check SPAM, some mailboxes marks our email as spam</b>" +
    "<br /><br />" +
    "Click on the link you find in the email, or request a new email to be sent by clicking the button below.",
  btn: "Send new link",
  dialog: {
    title: "Request a new confirmation email",
    body: "To request a new verification email, enter your password here.",
    error: "There was some error in sending the email, please try again",
    notRegistered: "Error, email is not registered",
    success: "Email sent successfully, log in to your inbox to verify.",
  },
};
