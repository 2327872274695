import React from "react";
import {
  Box,
  capitalize,
  Collapse,
  darken,
  IconButton,
  lighten,
  Paper,
  withStyles
} from "@material-ui/core";
import SuccessIcon from "./icons/SuccessIcon";
import ReportProblemIcon from "./icons/ReportProblemIcon";
import ErrorIcon from "./icons/ErrorIcon";
import InfoIcon from "./icons/InfoIcon";
import CloseIcon from "./icons/CloseIcon";
import clsx from "clsx";

export const styles = (theme) => {
  const getColor = theme.palette.type === "light" ? darken : lighten;
  const getBackgroundColor = theme.palette.type === "light" ? lighten : darken;

  return {
    /* Styles applied to the root element. */
    root: {
      ...theme.typography.body2,
      borderRadius: theme.shape.borderRadius,
      backgroundColor: "transparent",
      display: "flex",
      padding: "6px 16px",
    },
    /* Styles applied to the root element if `variant="standard"` and `color="success"`. */
    standardSuccess: {
      color: getColor(theme.palette.success.main, 0.6),
      backgroundColor: getBackgroundColor(theme.palette.success.main, 0.9),
      "& $icon": {
        color: theme.palette.success.main,
      },
    },
    /* Styles applied to the root element if `variant="standard"` and `color="info"`. */
    standardInfo: {
      color: getColor(theme.palette.info.main, 0.6),
      backgroundColor: getBackgroundColor(theme.palette.info.main, 0.9),
      "& $icon": {
        color: theme.palette.info.main,
      },
    },
    /* Styles applied to the root element if `variant="standard"` and `color="warning"`. */
    standardWarning: {
      color: getColor(theme.palette.warning.main, 0.6),
      backgroundColor: getBackgroundColor(theme.palette.warning.main, 0.9),
      "& $icon": {
        color: theme.palette.warning.main,
      },
    },
    /* Styles applied to the root element if `variant="standard"` and `color="error"`. */
    standardError: {
      color: getColor(theme.palette.error.main, 0.6),
      backgroundColor: getBackgroundColor(theme.palette.error.main, 0.9),
      "& $icon": {
        color: theme.palette.error.main,
      },
    },
    /* Styles applied to the root element if `variant="outlined"` and `color="success"`. */
    outlinedSuccess: {
      color: getColor(theme.palette.success.main, 0.6),
      border: `1px solid ${theme.palette.success.main}`,
      "& $icon": {
        color: theme.palette.success.main,
      },
    },
    /* Styles applied to the root element if `variant="outlined"` and `color="info"`. */
    outlinedInfo: {
      color: getColor(theme.palette.info.main, 0.6),
      border: `1px solid ${theme.palette.info.main}`,
      "& $icon": {
        color: theme.palette.info.main,
      },
    },
    /* Styles applied to the root element if `variant="outlined"` and `color="warning"`. */
    outlinedWarning: {
      color: getColor(theme.palette.warning.main, 0.6),
      border: `1px solid ${theme.palette.warning.main}`,
      "& $icon": {
        color: theme.palette.warning.main,
      },
    },
    /* Styles applied to the root element if `variant="outlined"` and `color="error"`. */
    outlinedError: {
      color: getColor(theme.palette.error.main, 0.6),
      border: `1px solid ${theme.palette.error.main}`,
      "& $icon": {
        color: theme.palette.error.main,
      },
    },
    /* Styles applied to the root element if `variant="filled"` and `color="success"`. */
    filledSuccess: {
      color: "#fff",
      fontWeight: theme.typography.fontWeightMedium,
      backgroundColor: theme.palette.success.main,
    },
    /* Styles applied to the root element if `variant="filled"` and `color="info"`. */
    filledInfo: {
      color: "#fff",
      fontWeight: theme.typography.fontWeightMedium,
      backgroundColor: theme.palette.info.main,
    },
    /* Styles applied to the root element if `variant="filled"` and `color="warning"`. */
    filledWarning: {
      color: "#fff",
      fontWeight: theme.typography.fontWeightMedium,
      backgroundColor: theme.palette.warning.main,
    },
    /* Styles applied to the root element if `variant="filled"` and `color="error"`. */
    filledError: {
      color: "#fff",
      fontWeight: theme.typography.fontWeightMedium,
      backgroundColor: theme.palette.error.main,
    },
    /* Styles applied to the icon wrapper element. */
    icon: {
      marginRight: 12,
      padding: "7px 0",
      display: "flex",
      fontSize: 22,
      opacity: 0.9,
    },
    /* Styles applied to the message wrapper element. */
    message: {
      padding: "8px 0",
    },
    /* Styles applied to the action wrapper element if `action` is provided. */
    action: {
      display: "flex",
      alignItems: "center",
      marginLeft: "auto",
      paddingLeft: 16,
      marginRight: -8,
    },
  };
};

const defaultIconMapping = {
  success: <SuccessIcon fontSize="inherit" />,
  warning: <ReportProblemIcon fontSize="inherit" />,
  error: <ErrorIcon fontSize="inherit" />,
  info: <InfoIcon fontSize="inherit" />,
};

const Alert = React.forwardRef(function Alert(props, ref) {
  const {
    open,
    action,
    children,
    classes,
    className,
    closeText = "Close",
    color,
    icon,
    iconMapping = defaultIconMapping,
    onClose,
    role = "alert",
    severity = "success",
    variant = "standard",
    ...other
  } = props;

  return (
    <>
      <Collapse in={open}>
        <Paper
          role={role}
          square
          elevation={0}
          className={clsx(
            classes.root,
            classes[`${variant}${capitalize(color || severity)}`],
            className
          )}
          ref={ref}
          {...other}>
          {icon !== false ? (
            <Box className={classes.icon}>
              {icon || iconMapping[severity] || defaultIconMapping[severity]}
            </Box>
          ) : null}
          <Box className={classes.message}>{children}</Box>
          {action != null ? <Box className={classes.action}>{action}</Box> : null}
          {action == null && onClose ? (
            <div className={classes.action}>
              <IconButton
                size="small"
                aria-label={closeText}
                title={closeText}
                color="inherit"
                onClick={onClose}>
                <CloseIcon fontSize="small" />
              </IconButton>
            </div>
          ) : null}
        </Paper>
      </Collapse>
    </>
  );
});

export default withStyles(styles, { name: "Alert" })(Alert);
