export const YupLocaleIT = {
  mixed: {
    default: "{{path} non è valido",
    required: "{{path}} è un campo obbligatorio",
    defined: "{{path}} deve essere definito",
    notNull: "{{path}} non può essere nullo",
    oneOf: "{{path}} deve essere uno dei seguenti valori: {{values}}",
    notOneOf: "{{path}} non deve essere uno dei seguenti valori: {{values}}",
    // notType: ({ path, type, value, originalValue }}) => {
    //   const castMsg =
    //     originalValue != null && originalValue !== value
    //       ? ` (cast from the value \`{{printValue(originalValue, true)}}\`).`
    //       : '.';
    //
    //   return type !== 'mixed'
    //     ? `{{path}} must be a \`{{type}}\` type, ` +
    //     `but the final value was: \`{{printValue(value, true)}}\`` +
    //     castMsg
    //     : `{{path}} must match the configured type. ` +
    //     `The validated value was: \`{{printValue(value, true)}}\`` +
    //     castMsg;
    // },
  },

  string: {
    length: "{{path}} deve essere esattamente {{length}} caratteri",
    min: "{{path}} deve contenere almeno {{min}} caratteri",
    max: "{{path}} deve contenere al massimo {{max}} caratteri",
    matches: '{{path}} deve corrispondere a quanto segue: "{{regex}}"',
    email: "{{path}} deve essere un indirizzo email valido",
    url: "{{path}} deve essere un URL valido",
    uuid: "{{path}} deve essere un UUID valido",
    trim: "{{path}} deve essere una stringa tagliata",
    lowercase: "{{path}} deve essere una stringa minuscola",
    uppercase: "{{path}} deve essere una stringa maiuscola",
  },
  number: {
    min: "{{path}} deve essere maggiore o uguale a {{min}}",
    max: "{{path}} deve essere minore o uguale a {{max}}",
    lessThan: "{{path}} deve essere minore di {{less}}",
    moreThan: "{{path}} deve essere maggiore di {{more}}",
    positive: "{{path}} deve essere un numero positivo",
    negative: "{{path}} deve essere un numero negativo",
    integer: "{{path}} deve essere un numero intero",
  },
  date: {
    min: "{{path}} deve essere successivo a {{min}}",
    max: "{{path}} deve essere precedente a {{max}}",
  },
  boolean: {
    isValue: "{{path}} deve essere {{value}}",
  },
  object: {
    noUnknown: "{{path}} ha chiavi non specificate: {{unknown}}",
  },
  array: {
    min: "{{path}} deve contenere almeno {{min}} elementi",
    max: "{{path}} deve contenere un numero inferiore o uguale a {{max}} elementi",
    length: "{{path}} deve contenere {{length}} elementi",
  },
};
