import { useQuery } from "@apollo/client";
import { Box, Menu, MenuItem } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { topicsQuery } from "./query";
import FiberNewIcon from "@material-ui/icons/FiberNew";
import { colors } from "../../../../../style/colors";
import Divider from "@material-ui/core/Divider";
import { Link } from "react-router-dom";

export default function ExploreMenu({ handleClose, anchorEl }) {
  const [topics, setTopics] = useState([
    {
      id: null,
      name: "Novità e più visti",
    },
    {
      id: null,
      name: null,
    },
  ]);
  const { data } = useQuery(topicsQuery, {
    fetchPolicy: "cache-first",
  });

  useEffect(() => {
    if (data && data?.topics?.length) {
      setTopics([
        {
          id: null,
          name: "Novità e più visti",
          icon: <FiberNewIcon style={{ color: colors.primary }} />,
        },
        {
          id: null,
          name: null,
        },
        ...data.topics,
      ]);
    }
  }, [data]);

  return (
    <Menu
      id="simple-menu"
      anchorEl={anchorEl}
      getContentAnchorEl={null}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      PaperProps={{
        style: {
          marginTop: 16,
          borderRadius: 24,
        },
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}>
      {topics.map((topic, i) =>
        i === 1 ? (
          <Divider variant="middle" key={i} />
        ) : (
          <MenuItem
            style={{
              padding: "8px 16px",
            }}
            value={topic.id}
            key={topic.id}
            component={Link}
            to={topic.slug ? `/topic/${topic.slug}` : `/explore`}
            onClick={handleClose}>
            {topic.icon ? (
              <Box
                style={{
                  paddingTop: "inherit",
                }}
                marginRight={1}>
                {topic.icon}
              </Box>
            ) : (
              <Box padding="2px" marginRight={1}>
                <Box
                  style={{
                    height: 20,
                    width: 20,
                    borderRadius: 10,
                    backgroundColor: topic.color,
                    flex: "0 0 auto",
                  }}
                />
              </Box>
            )}

            {i === 0 ? (
              <strong>{topic.name}</strong>
            ) : (
              <span>{topic.name}</span>
            )}
          </MenuItem>
        )
      )}
    </Menu>
  );
}
