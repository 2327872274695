export const UserEN = {
  sidecard: {
    question: "Do you have a question for <span>{{name}}</span>?",
    sendRequest: "Send request",
  },
  sections: {
    events: {
      title: "Events with <span>{{name}}</span>",
      empty: "No events yet",
    },
    courses: {
      title: "Courses of <span>{{name}}</span>",
      empty: "No courses yet",
    },
  },
};
