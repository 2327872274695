import React from "react";
import { Field, getIn } from "formik";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import ReCAPTCHA from "react-google-recaptcha";
import { makeStyles } from "@material-ui/core";
import clsx from "clsx";
import { reCaptchaKey } from "../../../config";

const useClasses = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
  },
}));

const CaptchaWithHelperText = ({
  className,
  helperText,
  field,
  form,
  children,
  ...props
}) => {
  const classes = useClasses();

  const fieldError = getIn(form.errors, field.name);
  const showError = getIn(form.touched, field.name) && !!fieldError;

  return (
    <>
      <FormControl className={clsx(classes.root, className)} error={showError}>
        <ReCAPTCHA
          sitekey={reCaptchaKey || "google-sitekey"}
          onChange={(value) => {
            form.setFieldValue(field.name, value);
          }}
          {...props}
        />

        {(showError || helperText) && (
          <FormHelperText>{showError ? fieldError : helperText}</FormHelperText>
        )}
      </FormControl>
    </>
  );
};

const FormikCaptcha = ({ name, label, ...props }) => {
  return (
    <Field
      name={name}
      label={label}
      component={CaptchaWithHelperText}
      {...props}
    />
  );
};

export default FormikCaptcha;
