import { useLocation } from "react-router-dom";
import { useEffect } from "react";

export default function ScrollToTop(): null {
  const { hash, pathname } = useLocation();

  useEffect(() => {
    // "document.documentElement.scrollTo" is the magic for React Router Dom v6
    // TODO: this if block is not helping with past and oncoming events sections because they are two different routes
    // We can hardcode the scrollBehavior on the component maybe?
    if (!hash) {
      document.documentElement.scrollTo({
        top: 0,
        left: 0,
        behavior: "auto", // Optional if you want to skip the scrolling animation
      });
    }
  }, [hash, pathname]);

  return null;
}
