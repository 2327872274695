import React from "react";
import { getUserQuery } from "./Apollo/queries/user";
import { ApolloClient, useMutation } from "@apollo/client";
import { loginMutation } from "./components/Login/query";
import gql from "graphql-tag";
import { backOfficeUrl } from "./config";

interface AuthLoginResult {
  user: any;
  errors: any[] | null;
}

interface AuthContextType {
  user: any;
  check: () => Promise<object | null>;
  login: (data: any) => Promise<AuthLoginResult>;
  signout: () => Promise<boolean>;
  endImpersonate: () => Promise<boolean>;
}

let AuthContext = React.createContext<AuthContextType>({
  user: null,
  check: () => Promise.reject(),
  login: (data) => Promise.reject(),
  signout: () => Promise.reject(),
  endImpersonate: () => Promise.reject(),
});

export function useAuth() {
  return React.useContext(AuthContext);
}

const logoutMutation = gql`
  mutation logout {
    logout
  }
`;

const endImpersonationMutation = gql`
  mutation endImpersonation {
    endImpersonation {
      sessionId
    }
  }
`;

export function AuthProvider({
  client,
  children,
}: {
  client: ApolloClient<any>;
  children: any;
}) {
  const [userLogin] = useMutation(loginMutation);
  const [userLogout] = useMutation(logoutMutation);

  const [endImpersonation] = useMutation<{
    endImpersonation: {
      sessionId: string;
    };
  }>(endImpersonationMutation);

  let [sid, setSid] = React.useState(window.localStorage.getItem("sid"));
  let [user, setUser] = React.useState(null);

  function login(data: any): Promise<AuthLoginResult> {
    return userLogin({
      variables: data,
      refetchQueries: [{ query: getUserQuery }],
    }).then((response) => {
      const {
        data: {
          login: { user, sessionId, errors },
        },
      } = response;

      if (errors) {
        const er: any[] = [];
        errors.forEach((e: any) => {
          er[e.path] = e.message;
        });
        return { user: null, errors: er };
      }

      if (sid !== sessionId) {
        setSid(sessionId);

        if (sessionId) window.localStorage.setItem("sid", sessionId);
        else window.localStorage.removeItem("sid");
      }

      setUser(user);
      return { user, errors: null };
    });
  }

  function signout(): Promise<boolean> {
    return userLogout()
      .then(() => {
        window.localStorage.removeItem("sid");
        window.localStorage.removeItem("user");
        window.localStorage.removeItem("impersonationAdminId");
        // window.location.reload();

        setUser(null);
        setSid(null);

        return true;
      })
      .catch((e) => false);
  }

  function endImpersonate(): Promise<boolean> {
    return endImpersonation()
      .then((response) => {
        const sessionId = response.data?.endImpersonation.sessionId;

        setUser(null);
        window.localStorage.removeItem("impersonationAdminId");
        window.localStorage.setItem("sid", sessionId ?? "");
        window.location.href = new URL("/console/users", backOfficeUrl).href;

        return true;
      })
      .catch((e) => false);
  }

  function check(): Promise<any | null> {
    console.log("AuthProvider check()");
    // setLoading(true);
    return client
      .query<{
        me: {
          user: any;
          errors: any[];
          sessionId: string;
          impersonatedBy: any;
        };
      }>({
        query: getUserQuery,
        fetchPolicy: "no-cache",
      })
      .then((response) => response.data.me)
      .then(({ user, errors, sessionId, impersonatedBy }) => {
        if (Boolean(errors) || !Boolean(sessionId) || user === null) {
          return null;
        }

        return {
          sessionId,
          user: user,
          isAdmin: user?.isAdmin || false,
          impersonatedBy: impersonatedBy?.id || null,
        };
      })
      .catch((e) => {
        console.error(e);
        return null;
      })
      .then(async (data) => {
        if (data?.user) {
          const { sessionId, user, isAdmin, impersonatedBy } = data;

          if (sid !== sessionId) {
            setSid(sessionId);

            if (sessionId) window.localStorage.setItem("sid", sessionId);
            else window.localStorage.removeItem("sid");
          }
          setUser(user);
          if (impersonatedBy) {
            window.localStorage.setItem("impersonationAdminId", impersonatedBy);
          } else {
            window.localStorage.removeItem("impersonationAdminId");
          }
          return user;
        } else {
          setUser(null);
          setSid(null);
          return null;
        }
        // setLoading(false);
      });
  }

  let value = { user, check, login, signout, endImpersonate };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
