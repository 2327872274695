export const PasswordRecoveryIT = {
  title: "Recupera la password",
  button: "Invia email di recupero",

  // Validation
  errors: {
    missing: "Dato necessario",
    email: "Formato email errato",
    notRegistered: "Errore, email non registrata",
    error: `Errore: C'è stato qualche errore nell'invio della mail, riprova`,
  },

  success: "Request sent!",

  signIn: "Hai recuperato la password? <b>Accedi</b>",
};
