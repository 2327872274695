// @flow

import React, { useState } from "react";
import {
  AppBar,
  Button,
  Grid,
  Hidden,
  Toolbar,
  Typography,
} from "@material-ui/core";
import {
  DashboardLayoutBody,
  DashboardLayoutMask,
  NavbarWrapper,
} from "./styles";
import Link from "../../Link";
import { useLocation } from "react-router-dom";
import { Outlet } from "react-router";
import NavButton from "./components/NavbarButton";

const TeacherNavbar = () => {
  const { pathname } = useLocation();

  return (
    <NavbarWrapper>
      <AppBar
        position="static"
        color="default"
        style={{ backgroundColor: "#fff" }}>
        <Toolbar variant="dense" style={{ marginTop: "10px" }}>
          <div style={{ flexGrow: 1 }} />
          {[
            { name: "Corsi", to: "/dashboard/corsi" },
            { name: "Revenue", to: "/dashboard/utile" },
          ].map(({ name, to }) => (
            <NavButton
              component={Link}
              to={to}
              key={name}
              selected={pathname.indexOf(to) >= 0}>
              {name}
            </NavButton>
          ))}
        </Toolbar>
      </AppBar>
    </NavbarWrapper>
  );
};

const DashboardLayout = ({ children }) => {
  const [showMask, setShowMask] = useState(true);

  return (
    <>
      <TeacherNavbar />
      <DashboardLayoutBody>
        {children ? children : <Outlet />}
      </DashboardLayoutBody>

      {showMask && (
        <Hidden mdUp>
          <DashboardLayoutMask>
            <Typography variant="h2">Esperienza desktop</Typography>
            <Typography>
              Per usufruire degli strumenti di gestione del corso nella loro
              forma più ottimizzata, utilizza un laptop, un tablet dallo schermo
              sufficentemente grande o un pc fisso.
            </Typography>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Link to="/">
                  <Button fullWidth variant="contained" color="primary">
                    Torna alla home
                  </Button>
                </Link>
              </Grid>
              <Grid item xs={12}>
                <Button
                  fullWidth
                  variant="outlined"
                  onClick={() => setShowMask(false)}>
                  Continua lo stesso
                </Button>
              </Grid>
            </Grid>
          </DashboardLayoutMask>
        </Hidden>
      )}
    </>
  );
};

export default DashboardLayout;
