export const PlaylistIT = {
  ticket: {
    vat: "IVA Inclusa",
    bought: "Hai accesso a questo percorso!",
    unlimited: "Incluso con Welevel Unlimited",

    duration_one: "{{count}} corso · {{duration}}",
    duration_other: "{{count}} corsi · {{duration}}",

    quiz: "Quiz Finale",

    certificate: "Certificazione delle competenze",

    exclusive: "Esclusiva Welevel",
    exclusiveInfo:
      "Questo percorso lo trovi solo su Welevel e su nessun altro sito",


    buttons: {
      chosen: "Hai già scelto questo percorso!",
      choose: "Scegli il percorso formativo",
    },
  },

  checkout: {
    title: "Acquista Percorso Formativo",
    subscription: "Guarda tutti i corsi Welevel - Abbonati",
    buy: "Acquista questo percorso a {{amount}}",
    free: "Guarda ora - Accedi/Registrati",
  },
}