export const GeneralEN = {
  // Actions
  add: "Add",
  active: "Active",
  back: "Back",
  cancel: "Cancel",
  close: "Close",
  confirm: "Confirm",
  create: "Create",
  date: "Date",
  datetime: "Date and Time",
  default: "Default",
  free: "Free",
  login: "Sign In",
  loginOrSubscribe: "Login or Subscribe",
  logout: "Sign Out",
  new: "New",
  of: "Of",
  or: "Or",
  price: "Price",
  register: "Sign Up",
  remove: "Remove",
  reopen: "Reopen",
  save: "Save",
  search: "Search",
  send: "Send",
  settings: "Settings",
  share: "Share",
  shareBy: "by welevel.academy!",
  sponsoredBy: "Sponsored By",
  sponsor: "Sponsor",
  status: "Status",
  subscribe: "Subscribe",
  subscribe_plan: "Subscribe",
  time: "Time",
  update: "Update",
  upload: "Upload",

  // Misc
  mostRecents: "Latests",
  recents: "Recents",
  showMore: "View more",

  // Forms
  address: "Address",
  address1: "Street Address 1",
  address2: "Street Address 2",
  birthPlace: "Place of birth",
  birthday: "Date of birth",
  captcha: "Captcha",
  city: "City",
  email: "Email",
  emailConfirm: "Confirm Email",
  fiscalCode: "Tax ID code",
  firstName: "Name",
  genericFieldError: "This field is invalid",
  lastName: "Last name",
  marketingAgreementLabel:
    "I consent to data processing for direct marketing purposes.",
  message: "Message",
  password: "Password",
  passwordConfirm: "Confirm Password",
  phone: "Phone",
  privacy: "Privacy",
  privacyAgreementLabel:
    "I consent to data processing for automatic data processing, statistical purposes and service improvement.",
  privacyPolicy:
    "I have read the notice and consent to the processing of data for the purposes set forth in the <url>privacy policy</url>.",
  subject: "Subject",
  termsOfServiceLabel: "Terms of Service",
  username: "Username",
  vatCode: "VAT Code",
  zipCode: "ZIP Code",

  // Add pluralization
  account_one: "Account",
  account_other: "Accounts",

  answer_one: "Answer",
  answer_other: "Answers",

  author_one: "Author",
  author_other: "Authors",

  course_one: "Course",
  course_other: "Courses",

  event_one: "Event",
  event_other: "Events",

  partner_one: "Partner",
  partner_other: "Partners",

  payment_one: "Payment",
  payment_other: "Payments",

  plan_one: "Plan",
  plan_other: "Plans",

  purchase_one: "Purchase",
  purchase_other: "Purchases",

  subscription_one: "Subscription",
  subscription_other: "Subscriptions",

  teacher_one: "Teacher",
  teacher_other: "Teachers",
};
