export const CheckoutEN = {
  coupon: {
    label: "Set a discount code",
    helperText: "Enter a DISCOUNT code if you have one.",
    successPercentage: "Discount of {{percentage}}% applied.",
    successAmount: "Discount of {{amount}} applied.",
    expired: "Coupon INVALID or EXPIRED",
  },

  paymentMethod: {
    title: "Payment method",
    new: "Add a payment method",

    buttons: {
      add: "Add",
      processing: "Processing...",
      success: "Added new payment method",
      reload: "Reload",
    },

    types: {
      creditCard: "Credit Card",
      payPal: "PayPal",
    },

    errors: {
      general: "Error adding a new payment method",
      gateway: "Gateway error, retry later.",
      auth3D: "3D Secure 2 authorization failed, retry",
    },

    success: "New payment method stored!",
  },

  buttons: {
    pay: "Pay {{amount}}",
    continue: "Continue",
  },

  successModal: {
    title: "Purchase completed",
    body: "Thank you for choosing welevel.academy for your training.",
    invoice: "If you need an invoice, request it now.",
    actions: {
      invoice: "Request invoice",
      continue: "Close",
    },
  },

  policy:
    "By purchasing you adhere to the <url>Terms and Conditions</url> of the platform",

  help: [
    "Some payment methods require a <strong>pre-authorization</strong> to verify their validity. There will be no charge.",
    "Do you have a <strong>discount code</strong>? You will be able to enter it after adding a payment method.",
    "Can't add the payment method or complete the purchase? Contact us at <url>info@example.com</url> to receive assistance.",
  ],

  steps: {
    confirm: "Summary",
    chooseMethod: "Choice of payment method",
    newMethod: "Add a payment method",
  },

  summary: {
    title: "Summary",
    order: "Order",
    amount: "Original price",
    discount: "Discount",
    total: "Total",
  },

  invoiceForm: {
    compileFields: "Fill in the following fields",
    success: "Request sent successfully",
    sending: "I am sending the request",
    error:
      "There was a problem sending the request. Contact us at fatturazione@example.com",
  },

  errors: {
    general: "Something went wrong, cannot continue.",
  },
};
