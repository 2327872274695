export const CourseEN = {
  ticket: {
    teacherOf: "Course teacher",

    vat: "VAT Included",
    bought: "You have access to this course!",
    unlimited: "Included with Welevel Unlimited",

    views: "{{views}} views",
    views_few: "< 1000 views",

    duration_one: "{{count}} lesson · {{duration}}",
    duration_other: "{{count}} lessons · {{duration}}",

    rating_one: "{{rating}}",
    rating_other: "{{rating}} out of {{count}} reviews",

    exclusive: "Welevel Exclusive",
    exclusiveInfo:
      "This course can only be found on Welevel and on no other site",

    difficulties: {
      advanced: "Advanced course",
      base: "Basic course",
      masterclass: "Masterclass",
    },

    buttons: {
      playlist: "Save",
      playlistTooltip: "Add to a custom plan",

      slide: "Download Slide",
      slideTooltip: "Download the slides of the course",
    },
  },

  watch: {
    firstLesson: "Go to the first lesson",
    studyPlan: "Go to the study plan",
    continueMasterclass: "Resume the masterclass",
    continueCourse: "Resume the course",
    playlist: {
      percentage: "<s>{{percentage}}%</s> completed",
      certificate: "This course is part of a Welevel certified study plan."
    }
  },

  checkout: {
    title: "Buy Course",
    subscription: "View all Welevel courses - Subscribe",
    buy: "Buy this course for {{amount}}",
    free: "Watch Now - Login / Register",
  },

  description: {
    title: "Description",
  },

  lessons: {
    title: "Lessons",
  },

  reviews: {
    title: "Reviews",
    button: "See all"
  },

  author: {
    title: "Teacher",
  },

  neighbors: {
    topic: "Latest {{topic}} courses",
    recent: "{{topic}} courses",
    button: "See all courses in {{topic}}"
  },
};
