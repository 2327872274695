export const YupLocaleEN = {
  mixed: {
    default: "{{path} is invalid",
    required: "{{path}} is a required field",
    defined: "{{path}} must be defined",
    notNull: "{{path}} cannot be null",
    oneOf: "{{path}} must be one of the following values: {{values}}",
    notOneOf: "{{path}} must not be one of the following values: {{values}}",
    // notType: ({ path, type, value, originalValue }}) => {
    // const castMsg =
    // originalValue != null && originalValue !== value
    // ? ` (cast from the value \`{{printValue(originalValue, true)}}\`).`
    // : '.';
    //
    // return type !== 'mixed'
    // ? `{{path}} must be a \`{{type}}\` type, ` +
    // `but the final value was: \`{{printValue(value, true)}}\`` +
    // castMsg
    // : `{{path}} must match the configured type. ` +
    // `The validated value was: \`{{printValue(value, true)}}\`` +
    // castMsg;
    // },
  },

  string: {
    length: "{{path}} must be exactly {{length}} characters",
    min: "{{path}} must contain at least {{min}} characters",
    max: "{{path}} must contain at most {{max}} characters",
    matches: '{{path}} must match the following: "{{regex}}"',
    email: "{{path}} must be a valid email address",
    url: "{{path}} must be a valid URL",
    uuid: "{{path}} must be a valid UUID",
    trim: "{{path}} must be a trimmed string",
    lowercase: "{{path}} must be a lowercase string",
    uppercase: "{{path}} must be an uppercase string",
  },
  number: {
    min: "{{path}} must be greater than or equal to {{min}}",
    max: "{{path}} must be less than or equal to {{max}}",
    lessThan: "{{path}} must be less than {{less}}",
    moreThan: "{{path}} must be greater than {{more}}",
    positive: "{{path}} must be a positive number",
    negative: "{{path}} must be a negative number",
    integer: "{{path}} must be an integer",
  },
  date: {
    min: "{{path}} must be after {{min}}",
    max: "{{path}} must be before {{max}}",
  },
  boolean: {
    isValue: "{{path}} must be {{value}}",
  },
  object: {
    noUnknown: "{{path}} has unspecified keys: {{unknown}}",
  },
  array: {
    min: "{{path}} must contain at least {{min}} elements",
    max: "{{path}} must contain less than or equal to {{max}} elements",
    length: "{{path}} must contain {{length}} elements",
  },
};