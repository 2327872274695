import React from "react";
import { Box, makeStyles, Typography } from "@material-ui/core";
import { Trans } from "react-i18next";
import clsx from "clsx";
import Link from "@material-ui/core/Link";
import { isMobile } from "react-device-detect";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import PhoneIcon from "@material-ui/icons/Phone";
import PhoneAndroidIcon from "@material-ui/icons/PhoneAndroidOutlined";
import CopyToClipboard from "../../../components/CopytoClipboard";

const useClasses = makeStyles((theme) => ({
  root: {},
  title: {
    marginBottom: theme.spacing(2),
  },
  list: {
    padding: 0,
    margin: 0,
  },
  row: {
    display: "inline-flex",
    marginBottom: theme.spacing(1),
    width: "100%",

    "& > svg": {
      marginRight: theme.spacing(1),
    },
  },
}));

const AddressBox = ({ className, ...props }) => {
  const classes = useClasses();

  return (
    <Box className={clsx(classes.root, className)} {...props}>
      <Box>
        <Typography component="h2" variant="h3" className={classes.title}>
          <Trans i18nKey="route.contactUs.address.title" />
        </Typography>
        <Typography component="span" variant="h4" className={classes.row}>
          <Trans i18nKey="route.contactUs.address.subtitle" />
        </Typography>
      </Box>
      <ul className={classes.list}>
        <li className={classes.row}>
          <Typography className={classes.row}>
            <Trans i18nKey="route.contactUs.address.address">
              Via Macanno, 38Q
              <br />
              47923 - Rimini (RN)
            </Trans>
          </Typography>
        </li>
        <li className={classes.row}>
          <PhoneIcon />
          <Typography>
            <Trans
              i18nKey="route.contactUs.address.phone"
              components={{
                url: isMobile ? (
                  <Link
                    href={"tel:054157474"}
                    color="inherit"
                    underline={"hover"}
                  />
                ) : (
                  <CopyToClipboard text={"054157474"} />
                ),
              }}
            />
          </Typography>
        </li>
        <li className={classes.row}>
          <PhoneAndroidIcon />
          <Typography>
            <Trans
              i18nKey="route.contactUs.address.mobile"
              components={{
                url: isMobile ? (
                  <Link
                    href={"tel:3316209442"}
                    color="inherit"
                    underline={"hover"}
                  />
                ) : (
                  <CopyToClipboard text={"3316209442"} />
                ),
                wa: (
                  <Link
                    href={"https://wa.me/393316209442"}
                    color="inherit"
                    underline={"hover"}
                  />
                ),
              }}
            />
          </Typography>
        </li>
        <li className={classes.row}>
          <MailOutlineIcon />
          <Typography>
            <Trans
              i18nKey="route.contactUs.address.email"
              components={{
                url: isMobile ? (
                  <Link
                    href={"mailto:info@example.com"}
                    color="inherit"
                    underline={"hover"}
                  />
                ) : (
                  <CopyToClipboard text={"info@example.com"} />
                ),
              }}
            />
          </Typography>
        </li>
      </ul>
    </Box>
  );
};

export default AddressBox;
