export const VideoDropzoneEN = {
  dropper: {
    description: "Drag & Drop the file here, or click to choose a file.",
    release: "Release the file here...",
  },

  loader: {
    encoding: "Encoding of the video",
    uploading:
      "<span /><span>{{percentage}}%</span><span>{{uploaded}}MB of {{size}}MB</span>",
  },

  message: {
    success:
      "Video uploaded, click 'Save' or wait for the preview!",
    uploading: "Uploading...",
    error: "There was an error uploading, please try again.",
  },
};

