export const EventEN = {
  allEvents: "All events",
  speaker_one: "Speakers",
  speaker_other: "Speakers",
  backToEvent: "Back to the event",
  ticket: {
    liveEvent: "now LIVE",
    pastEvent: "Event ended",
    scheduledEvent: "Scheduled event {{date}}",

    bought: "You already have access to this event",
    unlimited: "Free for Welevel Unlimited subscribers",
    partner: "Partner",
  },
  booking: {
    reserved: {
      title: "This event is reserved",
      subtitle: "You'll be asked your password",
    },
    live: {
      title: "Watch",
      subtitle: "The current event, enter now!",
    },
    past: {
      title: "Rewatch event",
      subtitle: "The event is over but you can rewatch it now",
    },
    form: {
      title: "Answer these questions to book your seat",
      button: "Book",
    },
    book: "Book your seat",
    booked: "Seat reserved",
  },
  checkout: {
    title: "Buy this event",
    subscription: "Watch all Welevel events - Subscribe",
    buy: "Buy this event for {{amount}}",
    free: "Watch now - Login/Signup",
  },
  agenda: {
    filters: {
      rooms: "Filter rooms",
      topics: "Filter topics",
      teachers: "Filter by speaker",
    },
    noSession: {
      title: "There is nothing planned for this day.",
      subtitle: "Try to change filters",
    },
    watchLive: "Watch Live",
  },
};
