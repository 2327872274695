import { Box, CircularProgress } from "@material-ui/core";
import React, { useEffect, useMemo, useState } from "react";
import { Navigate, Outlet, useLocation } from "react-router";
import { useAuth } from "../AuthProvider";
import { useStore } from "outstated";
import LoginStore from "../store/LoginStore";

const AuthRoute = ({ ...rest }) => {
  let { pathname } = useLocation();

  const { user, check } = useAuth();
  const [authLoading, setAuthLoading] = useState(true);

  const {
    setOpen: setOpenLogin,
    setRedirectForLogin,
    setRedirectForRegistration,
  } = useStore(LoginStore);

  if (typeof pathname === "undefined") {
    pathname = "/search";
  }

  const gateResult = useMemo(() => {
    const defRes = {
      openLogin: !Boolean(user),
      redirectPath: "/",
    };

    return Boolean(user) ? null : defRes;
  }, [authLoading, user]);

  useEffect(async () => {
    await check()
      .catch((e) => {
        console.error(e);
      })
      .finally(() => {
        setAuthLoading(false);
      });
  }, []);

  if (authLoading) {
    return (
      <Box
        style={{
          height: "100vh",
        }}>
        <CircularProgress
          variant="indeterminate"
          color="primary"
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translateXY(-50%)",
          }}
        />
      </Box>
    );
  }

  if (gateResult) {
    const { openLogin, redirectPath } = gateResult;

    // if necessary open login
    if (openLogin) {
      // Show Login form
      setRedirectForLogin(pathname);
      setRedirectForRegistration(pathname);
      // Open Login
      setOpenLogin("login");
    }

    // Redirect to specified path
    return <Navigate to={redirectPath} state={{ from: pathname }} replace />;
  }

  // common authenticated route
  return <Outlet />;
};

export default AuthRoute;
