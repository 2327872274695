export const MeEN = {
  seo: {
    title: "Dashboard | universo corsi",
  },

  toolbar: {
    title: "Welcome, {{firstName}}",
    settings: "Settings",
  },
  improveBanner: {
    askOpinion: "What overall rating would you give to welevel.academy?",
    surveyButton: "Answer the survey",
  },
  widgets: {
    section: {
      title: {
        authed: ", Here's your Control Panel",
        unAuthed: "Control Panel",
      },
    },
    continue: {
      continueCourse: "Resume course",
    },
    subscription: {
      subscribeBanner: "<b>Subscribe</b> to the industry's best training",
    },
    competenceBanner: {
      skillLevels: "Skill levels",
    },
    corporateBanner: {
      addPartner: "Add a collaborator...",
      theTeam: "THE TEAM: ",
      forCompanies: "For companies",
      trainYourStaff: "Train your staff",
    },
    competenceLeaderboard: {
      leaderboard: "Leaderboard",
    },
    studyPlanBanner: {
      title: "CERTIFIED TRAINING",
      certifiedTrainingPath: "Certified training paths",
      description: "Not sure where to start? Choose one of our training paths!",
    },
    explore: {
      title: "Explore",
      certifiedTrainingPath: "Certified training paths",
      description:
        "Not sure where to start? Choose one of our training courses!",
    },
    liveBanner: {
      title: "LIVE events and lectures",
      todayAt: "Today at {{time}}",
      booked: "We are waiting for you at the webinar!",
      book: "Don't miss this event!",
    },
    courseBanner: {
      findCourse: "Find a course",
      startLearningNow: "Start learning <br />right now",
    },
    teacher: {
      teacherDashboard: "Teacher Dashboard",
    },
    marketingOnboarding: {
      chooseYourPath: "<b>Customize your path</b>",
      suggestions: "We will suggest the most suitable courses for you",
    },
  },
  studyPlanAndPlaylists: {
    section: {
      title: "Certified and customized studyplans",
    },
    choosePath: "Choose a certified path",
    createCustomizedPath: "Create custom path",
    tipsList: [
      "<b>Not sure where to start?</b> <br />Certified welevel training paths are structured to help you choose the right content to improve your skills",
      "<b>Too many interesting courses with no time to follow? </b> <br /> Organize the courses that interest you most into collections to retrieve them whenever and wherever you want!",
    ],
  },
  history: {
    title: "You have recently seen",
    noRecentlyWatchedCourse: "No courses seen recently. ",
    discoverCourses:
      "Discover <url><bold>our courses</bold></url> and start learning now",
  },
  purchases: {
    title: "Your purchases",
    showMore: "View more",
    noTransactions: "No recent purchases",
  },
};
