export const PlanAssetEN = [
  {
    abstract:
      "For professionals, for those who want to work in tourism and hospitality",
    description: "The best of welevel training",
    cta: "Choose",
    features: [
      {
        name: "Unlimited access to all training",
      },
      {
        name: "New video courses every month",
      },
      {
        name: "Updates to previously published courses",
      },
      {
        name: "Take courses from PC, Mobile and Tablet",
      },
      {
        name: "Download slides and extra course materials",
      },
      {
        name: "Create customized training paths",
      },
      {
        name: "Check the progress of your training tracks",
      },
      {
        name: "Certification of completed training courses",
      },
      {
        name: "Masterclasses by top trainers",
      },
      {
        name: "Unlimited access to classes and live events",
      },
    ],
  },
  {
    abstract:
      "For managers, entrepreneurs and those who want to train their staff",
    description: "Designed for businesses",
    cta: "Learn more",
    features: [
      {
        name: "Unlimited access to all training",
      },
      {
        name: "New video courses every week",
      },
      {
        name: "Updates to previously published courses",
      },
      {
        name: "Take courses from PC, Mobile and Tablet",
      },
      {
        name: "Download slides and extra course materials",
      },
      {
        name: "Create customized training paths",
      },
      {
        name: "Check the progress of your training tracks",
      },
      {
        name: "Certification of completed training courses",
      },
      {
        name: "Masterclasses by top trainers",
      },
      {
        name: "Unlimited access to classes and live events",
      },
    ],
    adminFeatures: [
      {
        name: "Choose which courses to have your employees take",
      },
      {
        name:
          "Monitor the progress of your staff's preparation through a dashboard that only you see",
      },
      {
        name: "Periodically check the improvements of each staff member",
      },
      {
        name: "Control dashboard to monitor the progress of your employees",
      },
      {
        name: "Customized consultant to create the training paths",
      },
    ],
  },
  {
    abstract:
      "For professionals, for those who want to work in tourism and hospitality",
    description: "The best of welevel training",
    cta: "Choose",
    features: [
      {
        name: "Unlimited access to all training",
      },
      {
        name: "New video courses every week",
      },
      {
        name: "Updates to previously published courses",
      },
      {
        name: "Take courses from PC, Mobile and Tablet",
      },
      {
        name: "Download slides and extra course materials",
      },
      {
        name: "Create customized training paths",
      },
      {
        name: "Check the progress of your training tracks",
      },
      {
        name: "Certification of completed training courses",
      },
      {
        name: "Masterclasses by top trainers",
      },
      {
        name: "Unlimited access to classes and live events",
      },
    ],
  },
  {
    abstract:
      "For professionals, for those who want to work in tourism and hospitality",
    description: "For the best start",
    cta: "Choose",
    features: [
      { name: "Unlimited access to all training" },
      { name: "New video courses every week" },
      { name: "Updates to previously published courses" },
      { name: "Take courses from PC, Mobile and Tablet" },
    ],
  },
  {
    abstract: "Getting to know each other",
    description: "Getting to know each other",
    cta: "Learn more",
    features: [
      {
        name: "Discover free content such as events and interviews",
      },
      {
        name: "View course previews",
      },
    ],
  },
];
