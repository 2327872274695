export const SignUpIT = {
  title: "Registrati",
  subtitle:
    "Scopri centinaia di video lezioni, esercitazioni e letture dai migliori professionisti e docenti del settore.",

  form: {
    stats:
      "Acconsento al trattamento dati per elaborazione automatica dei dati, per finalità statistiche e per miglioramento del servizio",
    marketing:
      "Acconsento al trattamento dati per finalità di marketing diretto",
    privacy:
      "Ho letto l'informativa e acconsento al trattamento dei dati per le finalità previste dall'<url>informativa sulla privacy</url>",

    action: "Registrati",

    // Validation
    errors: {
      emailMatch: "Le due email non corrispondono",
      passwordMatch: "Le due passwords non corrispondono",
      marketing:
        "Devi prima acconsentire al trattamento dei dati per finalità statistiche",
      tos: "Devi accettare i termini di servizio e privacy",
    },
  },

  alternative: "O",
  signIn: "Hai già un account? <b>Accedi</b>",
};
