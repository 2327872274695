export const AcceptInvitationEN = {
  seo: {
    title: "Accept invitation",
  },
  mainCard: {
    title: "Accept {{firstName}}'s invitation",
    description:
      "Access the largest library of video courses for tourism and hospitality professionals, certify your job skills, and be inspired by industry masters' expertise",
    cta: "Accept invitation",
  },
  images: {
    courses: {
      alt: "",
      title: "Courses",
      description: "Hands-on courses and tutorials",
    },
    live: {
      alt: "",
      title: "Live events",
      description: "Live events with industry experts",
    },
    certificate: {
      alt: "",
      title: "Certified paths",
      description: "Get certified and boost your career",
    },
  },
  error: {
    title: "Error: Expired or Invalid Invitation",
    description: `We apologize, but the invitation you are trying to use appears to be expired or invalid.`,
    cta: ` Please try requesting a new invitation, or if you believe this is an error, do not hesitate to contact our support team at <a href="mailto:info@example.com">info@example.com</a>.`,
    acceptInviteMutation:
      "It was not possible to complete the action. The invitation may have already been accepted or may no longer be valid",
  },
};
