import gql from "graphql-tag";

export const getUserId = gql`
  query MeUserId {
    me {
      user {
        id
      }
    }
  }
`;

export const getUserQuery = gql`
  query MeUser {
    me {
      user {
        id

        firstName
        lastName

        isTeacher
        isAdmin

        authHasConfirmedEmail
        authHasPassword
        authIsLocked

        subscriptionStatus

        image {
          id
          cdnUrl(width: 64, height: 64)
        }
      }
      impersonatedBy {
        id
      }
      sessionId
      errors {
        path
        message
      }
    }
  }
`;
