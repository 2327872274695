export const ImageDropzoneIT = {
  dialog: {
    title: "Carica un immagine",
  },
  action: {
    withPreview: "Aggiorna l'immagine",
    noPreview: "Carica un immagine",
  },
  dragging: {
    withPreview: "Rilascia l'immagine qui per aggiornarla",
    noPreview: "Rilascia l'immagine qui per caricarla",
  },
};
