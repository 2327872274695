export const forgotPasswordIT = {
  title: "Modifica la tua password",
  password: {
    new: "Nuova Password",
  },
  alert: {
    success:
      "Password cambiata con successo, ti stiamo reindirizzando alla home.",
    linkExpired:
      "Il link di rinnovo della password è scaduto. Stai per essere redirezionato alla pagina di richiesta di una nuova mail.",
  },
};
