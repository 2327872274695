import React from "react";
import { Select } from "formik-material-ui";
import { Field, getIn } from "formik";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import InputLabel from "@material-ui/core/InputLabel";

const SelectWithLabel = ({
  className,
  variant,
  hiddenLabel,
  label,
  controlComponent,
  helperText,
  field,
  form,
  children,
  ...props
}) => {
  const fieldError = getIn(form.errors, field.name);
  const showError = getIn(form.touched, field.name) && !!fieldError;

  return (
    <FormControl
      className={className}
      variant={variant}
      error={showError}
      component={controlComponent}
      hiddenLabel={hiddenLabel}
      {...props}>
      {hiddenLabel || (
        <InputLabel htmlFor={field.name} id={`${field.name}-label`}>
          {label}
        </InputLabel>
      )}

      <Select
        labelId={`${field.name}-label`}
        form={form}
        field={field}
        label={hiddenLabel ? undefined : label}>
        {children}
      </Select>

      {(showError || helperText) && (
        <FormHelperText>{showError ? fieldError : helperText}</FormHelperText>
      )}
    </FormControl>
  );
};

const FormikSelect = ({
  name,
  label,
  hiddenLabel,
  placeholder,
  options = [],
  ...props
}) => {
  return (
    <Field
      name={name}
      label={label}
      component={SelectWithLabel}
      hiddenLabel={hiddenLabel}
      {...props}>
      {(hiddenLabel || placeholder) && (
        <MenuItem value="" disabled>
          {label || placeholder}
        </MenuItem>
      )}

      {options.map(({ label, value, ...props }, index) => (
        <MenuItem key={index} value={value} {...props}>
          {label}
        </MenuItem>
      ))}
    </Field>
  );
};

export default FormikSelect;
