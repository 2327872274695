export const RequestConfirmEmailIT = {
  seo: {
    title: "Conferma la email",
  },
  title: "Conferma la tua email",
  description:
    "Ti abbiamo inviato una email di conferma alla casella di posta che hai impostato quando ti sei registrato." +
    "<br /><br />" +
    "<b>Controlla nello SPAM, alcune caselle di posta segnano le nostre email come spam</b>" +
    "<br /><br />" +
    "Clicca nel link che trovi nella mail, oppure richiedi l'invio di una nuova email cliccando il pulsante qui sotto.",
  btn: "Invia nuovo link",

  dialog: {
    title: "Richiedi una nuova mail di conferma",
    body: "Per richiedere una nuova email di verifica inserisci qui la tua password.",
    error: "C'è stato qualche errore nell'invio della mail, riprova",
    notRegistered: "Errore, email non registrata",
    success:
      "Email inviata con successo, accedi alla tua casella di posta per verificarla.",
  },
};
