export const LiveChatEN = {
  title: "Live chat",

  closed: "The session's chat is closed",
  loginNeeded: "Log in to chat",
  greetings: "How nice to have you. Join the conversation...",
  newMessages: "New messages!",

  actions: {
    open: "Open chat",
    close: "Close chat",
    sendMessage: "Send a message",
    deleteMessage: "Delete the message",
  },
};
