import { Box, IconButton, makeStyles, Tooltip } from "@material-ui/core";
import React from "react";
import FacebookIcon from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import YouTubeIcon from "@material-ui/icons/YouTube";
import clsx from "clsx";

const useClasses = makeStyles((theme) => ({
  /* Styles applied to the root element. */
  root: {
    display: "flex",
    justifyContent: "center",
  },
  socialItem: {
    color: "inherit",
  },
}));
const SocialIconsBar = ({ className, ...props }) => {
  const classes = useClasses();
  return (
    <Box className={clsx(classes.root, className)} {...props}>
      <IconButton
        className={classes.socialItem}
        href="https://www.facebook.com/welevel.academy"
        target="_blank">
        <Tooltip title="Facebook">
          <FacebookIcon fontSize="large" />
        </Tooltip>
      </IconButton>

      <IconButton
        className={classes.socialItem}
        href="https://www.instagram.com/welevel.academy/"
        target="_blank">
        <Tooltip title="Instagram">
          <InstagramIcon fontSize="large" />
        </Tooltip>
      </IconButton>

      <IconButton
        className={classes.socialItem}
        href="https://www.youtube.com/channel/UCCz-fFWE7uWbK3hqAu52uNA"
        target="_blank">
        <Tooltip title="Youtube">
          <YouTubeIcon fontSize="large" />
        </Tooltip>
      </IconButton>

      <IconButton
        className={classes.socialItem}
        href="https://www.linkedin.com/company/welevelacademy/"
        target="_blank">
        <Tooltip title="Linkedin">
          <LinkedInIcon fontSize="large" />
        </Tooltip>
      </IconButton>
    </Box>
  );
};

export default SocialIconsBar;
