import SvgIcon from "@material-ui/core/SvgIcon";


export default function CompassIconSolid(props) {
  return (
    <SvgIcon {...props}>
      <path d="M24,12A12,12,0,1,1,0,12,11.91,11.91,0,0,1,12,0,12,12,0,0,1,24,12ZM6,16.82a2.39,2.39,0,0,0,.21.6,1.16,1.16,0,0,0,1.52.39C9.91,17,12,16.15,14.18,15.34a1.81,1.81,0,0,0,1.15-1.14c.66-1.75,1.34-3.48,2-5.23a15.63,15.63,0,0,0,.55-1.49,1.08,1.08,0,0,0-1-1.4,1.55,1.55,0,0,0-.69.14L9.79,8.67A1.88,1.88,0,0,0,8.66,9.81L6.19,16.23A5,5,0,0,0,6,16.82Z"/>
      <path d="M13.48,12A1.44,1.44,0,0,1,12,13.5,1.47,1.47,0,0,1,10.51,12a1.49,1.49,0,1,1,3,0Z"/>
    </SvgIcon>
  );
}