import { AcceptInvitationEN } from "../../en/routes/acceptInvitation";

export const AcceptInvitationIT: typeof AcceptInvitationEN = {
  seo: {
    title: "Accetta l'invito",
  },
  mainCard: {
    title: "Accetta l'invito di {{firstName}}",
    description:
      "Accedi alla più grande libreria di video corsi per professionisti del turismo e dell'ospitalità, certifica le tue competenze lavorative e lasciati ispirare dall'esperienza dei maestri del settore",
    cta: "Accetta l'invito",
  },
  images: {
    courses: {
      alt: "",
      title: "Corsi",
      description: "Corsi pratici e tutorial",
    },
    live: {
      alt: "",
      title: "Eventi",
      description: "Eventi in diretta con esperti del settore",
    },
    certificate: {
      alt: "",
      title: "Percorsi certificati",
      description: "Ottieni la certificazione e migliora la tua carriera",
    },
  },
  error: {
    title: "Errore: Invito scaduto o non valido",
    description: `Ci dispiace, ma l'invito che stai cercando di utilizzare sembra essere scaduto o non valido.`,
    cta: ` Prova a richiedere un nuovo invito o se ritieni che si tratti di un errore non esitare a contattare il nostro team di supporto all'indirizzo <a href="mailto:info@example.com">info@example.com</a>.`,
    acceptInviteMutation:
      "Non è stato possibile completare l'azione. L'invito potrebbe essere già stato accettato o non essere più valido",
  },
};
