export const PartnersEN = {
  seo: {
    title: "Our partners",
  },

  header: {
    title: "Introducing to you our partners",
    description:
      "We have combined our knowledge of expert teachers, the professionalism of consultants and specialized trainers and the experience of professionals in order to make their knowledge available to everyone.",
  },
};
