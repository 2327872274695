import React, { Suspense, useEffect, useState } from "react";
// routing
import {
  BrowserRouter,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
// lazy
import loadable from "@loadable/component";
import { CircularProgress, Typography } from "@material-ui/core";
// store
import { useStore } from "outstated";
import PlaylistsDialogStore from "../store/PlaylistsDialogStore";
import LoginStore from "../store/LoginStore";
import AgencyConversionStore from "../store/AgencyConvertion";
// Extra utils
import Offline from "../components/Offline";
import WelevelLayout from "../components/Layout/Welevel";
import NotFound from "./notFound";
import AuthRoute from "./AuthRoute";
import Redirect from "../components/Redirect";
import DashboardLayout from "../components/Layout/DashboardLayout";
import { useAuth } from "../AuthProvider";
import ScrollToTop from "../helpers/scrollToTop";
import Logout from "../components/Login/logout";
import { useGTMDispatch } from "@elgorditosalsero/react-gtm-hook";
import "../i18n";
import GatingRoute from "./GatingRoute";
import ContactUs from "./ContactUs";
import TeacherGatingRoute from "./TeacherRoute";
import UscreenBanner from "../components/UscreenBanner";
// Routes
const LoginPage = loadable(() => import("../components/Login"));
const PlaylistMenuDialog = loadable(() =>
  import("../components/DialogAddToPlaylist")
);
const AgencyConvertionDialog = loadable(() =>
  import("../components/Dialogs/AgencyConvertion")
);

const HomeRoute = loadable(() => import("./Home2"));
const Browse = loadable(() => import("./Me"));
const CourseRoute = loadable(() => import("./Course"));
const PartnerRoute = loadable(() => import("./Partner"));
const LessonRoute = loadable(() => import("./Lesson"));
const DashboardCourses = loadable(() =>
  import("./TeacherDashboard/DashboardCourses")
);
const TeacherProfile = loadable(() => import("./Teacher"));
const Explore = loadable(() => import("./Explore"));
const Search = loadable(() => import("./Search"));
const Teachers = loadable(() => import("./Teachers"));
const Partners = loadable(() => import("./Partners"));
const TopicRoute = loadable(() => import("./Topic"));
const UpdatePassword = loadable(() => import("./UpdatePassword"));
const RequestConfirmationEmail = loadable(() =>
  import("./RequestConfirmationEmail")
);
const ConfirmEmail = loadable(() => import("./ConfirmEmail"));
const CompleteRegistration = loadable(() => import("./CompleteRegistration"));

const ReviewsRoute = loadable(() => import("./Reviews"));
const NewReviewRoute = loadable(() => import("./NewReview"));

const StudyPlans = loadable(() => import("./StudyPlans"));
const PlaylistRoute = loadable(() => import("./Playlist"));

const ProfileLocked = loadable(() => import("./ProfileLocked"));
const EventsRoute = loadable(() => import("./Events"));
const EventRoute = loadable(() => import("./Event"));
const StreamRoute = loadable(() => import("./Stream"));
const Abbonamento = loadable(() => import("./Abbonamento"));
const AbbonamentoId = loadable(() => import("./AbbonamentoCheckout"));
const Support = loadable(() => import("./Support"));
const DashboardStats = loadable(() =>
  import("./TeacherDashboard/DashboardStats")
);
const Tutorial = loadable(() =>
  import("./TeacherDashboard/DashboardCourses/components/TutorialPage")
);
const DashboardCourseNew = loadable(() =>
  import("./TeacherDashboard/DashboardCourseNew")
);
const BecomeTeacher = loadable(() => import("./BecomeTeacher"));
const StudyPlan = loadable(() => import("./StudyPlan"));
const AziendeComponent = loadable(() => import("./Aziende"));
const AtomicDashboardCourse = loadable(() =>
  import("./TeacherDashboard/AtomicDashboardCourse")
);
const Agreement = loadable(() => import("./Agreement"));
const CosEWelevel = loadable(() => import("./AboutUs"));

const ProfileSettings = loadable(() => import("./Settings"));
const Billing = loadable(() => import("./Billing"));
const Stats = loadable(() => import("./Stats"));
const Onboarding = loadable(() => import("./Onboarding"));

const Topics = loadable(() => import("./Topics"));
const TrovaLavoro = loadable(() => import("./TrovaLavoro"));
const Quiz = loadable(() => import("./Quiz"));
const NewPlaylist = loadable(() => import("./NewPlaylist"));
const Team = loadable(() => import("./Team"));
const TeamDashboard = loadable(() => import("./Team/sections/dashboard"));
const TeamEmployee = loadable(() => import("./Team/sections/employee"));
const TeamTraining = loadable(() => import("./Team/sections/training"));
const TeamSettings = loadable(() => import("./Team/sections/settings"));
const AcceptInvitation = loadable(() => import("./AcceptInvitation"));

// const LandingBackToSchool = loadable(() => import("./BackToSchool"));

const routeComponents = [
  LoginPage,
  PlaylistMenuDialog,
  AgencyConvertionDialog,
  HomeRoute,
  Browse,
  CourseRoute,
  PartnerRoute,
  LessonRoute,
  DashboardCourses,
  TeacherProfile,
  Explore,
  Search,
  Teachers,
  TopicRoute,
  UpdatePassword,
  RequestConfirmationEmail,
  ConfirmEmail,
  CompleteRegistration,
  ReviewsRoute,
  NewReviewRoute,
  StudyPlans,
  PlaylistRoute,
  ProfileLocked,
  EventsRoute,
  EventRoute,
  StreamRoute,
  Abbonamento,
  AbbonamentoId,
  Support,
  DashboardStats,
  Tutorial,
  DashboardCourseNew,
  BecomeTeacher,
  StudyPlan,
  AziendeComponent,
  AtomicDashboardCourse,
  Agreement,
  CosEWelevel,
  ProfileSettings,
  Billing,
  Stats,
  Onboarding,
  Topics,
  TrovaLavoro,
  Quiz,
  NewPlaylist,
  Team,
  AcceptInvitation,
  // LandingBackToSchool,
];

const loadRouteScripts = () =>
  Promise.all(routeComponents.map((c) => c.load()));

const LazyLoader = ({ delay = 250, hide = false, ...props }) => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShow(true);
    }, delay);
    return () => {
      clearTimeout(timeout);
    };
  }, [delay]);

  return show && !hide ? <CircularProgress {...props} /> : null;
};

const RoutesComponent = () => {
  // utils
  const navigate = useNavigate();
  const { pathname, search } = useLocation();
  // const sendDataToGTM = useGTMDispatch();

  // state
  const [previousPath, setPreviousPath] = useState(pathname);
  const [areAllRoutesLoaded, setAllRoutesLoaded] = useState(false);
  const [authLoading, setAuthLoading] = useState(false);
  const { check } = useAuth();

  // store
  const { open: openP, setOpen: closeP } = useStore(PlaylistsDialogStore);
  const { open: openL } = useStore(LoginStore);
  const { open: openA } = useStore(AgencyConversionStore);

  // Effect on fetch
  useEffect(() => {
    // sendDataToGTM({ event: "virtual_page_view" });

    if (pathname === "/" && search.includes("?text"))
      navigate(`/search${search}`);

    // making it smooth
    if (pathname.includes("/events") && previousPath.includes("/events")) {
    } else {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }

    setPreviousPath(pathname);
  }, [pathname]);

  useEffect(() => {
    setTimeout(async () => {
      await loadRouteScripts();
      setAllRoutesLoaded(true);
    }, 10 * 1000);
  }, []);

  useEffect(() => {
    console.log(
      !areAllRoutesLoaded ? "sto caricando con suspense" : "sto caricando senza"
    );
  }, [areAllRoutesLoaded]);

  useEffect(async () => {
    setAuthLoading(true);
    await check().catch(console.error);
    setAuthLoading(false);
  }, []);

  return (
    <>
      {/* <UscreenBanner /> */}
      <Offline />
      <Logout />

      {openL && <LoginPage />}
      {openA && <AgencyConvertionDialog />}

      <PlaylistMenuDialog
        open={Boolean(openP)}
        onClose={() => closeP(false)}
        courseId={openP}
      />

      <Suspense
        fallback={
          <LazyLoader delay={300} hide={areAllRoutesLoaded && !authLoading} />
        }>
        <Routes>
          <Route path="/" element={<GatingRoute />}>
            {/*<Route path="/black-friday" element={<PromoLanding />} />*/}

            {/*<Route path="/xmas" element={<PromoXMasLanding />} />*/}

            {/* Custom Landings */}
            {/*<Route path="/back-to-school" element={<LandingBackToSchool />} />*/}
            {/* Protected Routes */}
            {/* <Route element={<AuthRoute />}>
              <Route path="abbonamento/:id" element={<AbbonamentoId />} />
            </Route> */}

            {/* Basic Routes */}
            <Route element={<WelevelLayout />}>
              <Route index element={<HomeRoute />} />
              <Route path="teachers" element={<Teachers />} />
              {/* <Route path="partners" element={<Partners />} /> */}
              {/* <Route path="about" element={<CosEWelevel />} /> */}
              {/* <Route path="support" element={<Support />} /> */}
              {/* <Route path="abbonamento" element={<Abbonamento />} /> */}
              {/* <Route path="diventa-un-docente" element={<BecomeTeacher />} /> */}
              {/* <Route path="aziende" element={<AziendeComponent />} /> */}
              {/* <Route path="trova-lavoro" element={<TrovaLavoro />} /> */}
              {/* <Route path="contattaci" element={<ContactUs />} /> */}

              {/*Welevel Content*/}
              {/*---*/}
              {/*Explore*/}
              <Route path="explore" element={<Explore />} />
              <Route path="search" element={<Search />} />

              {/*Topics*/}
              <Route path="topics" element={<Topics />} />
              <Route path="topic/:id" element={<TopicRoute />} />

              {/*Courses*/}
              <Route path="course/:id" element={<CourseRoute />} />
              <Route
                path="/course/:id/review/new"
                element={<NewReviewRoute />}
              />
              <Route
                path="course/:id/reviews/:stars"
                element={<ReviewsRoute />}
              />
              <Route path="lesson/:lesson" element={<LessonRoute />} />

              {/*Events*/}
              {/* <Route path="events/*" element={<EventsRoute />} />
              <Route path="event/:id" element={<EventRoute />} />
              <Route
                // hideFooter={true}
                path="stream/:id"
                element={<StreamRoute />}
              /> */}

              {/*Partners*/}
              {/* <Route path="partner/:id" element={<PartnerRoute />} /> */}

              {/*Teacher Profile*/}
              <Route path="teacher/:id" element={<TeacherProfile />} />

              {/*Playlists*/}
              <Route path="studyplans" element={<StudyPlans />} />
              <Route path="studyplan/:id" element={<StudyPlan />} />

              {/*User Recovery*/}
              <Route path="invitation/:token" element={<AcceptInvitation />} />

              <Route
                path="forgot-password/:token"
                element={<UpdatePassword />}
              />

              <Route path="/confirm/email/:id" element={<ConfirmEmail />} />

              {/* Protected Routes */}
              <Route element={<AuthRoute />}>
                {/* User Registration */}
                <Route
                  path="/register/complete"
                  element={<CompleteRegistration />}
                />
                <Route
                  path="/register/complete/:platform"
                  element={<CompleteRegistration />}
                />
                {/* User Locked */}
                <Route path="/profile-locked" element={<ProfileLocked />} />
                <Route
                  path="/send-confirm-email"
                  element={<RequestConfirmationEmail />}
                />

                {/* User Navigation */}
                {/* <Route path="schooling/:id" element={<PlaylistRoute />} />
                <Route path="schooling/new" element={<NewPlaylist />} />
                <Route path="quiz/:id" element={<Quiz />} /> */}

                {/* User Settings */}
                <Route path="me" element={<Browse />} />
                <Route path="settings" element={<ProfileSettings />} />
                <Route path="billing" element={<Billing />} />
                <Route path="onboarding" element={<Onboarding />} />

                {/* User Stats */}
                {/* <Route path="stats" element={<Stats />} /> */}
                {/*<Route path="stats/:userId" element={<Stats />} />*/}

                {/* Agency Dashboard */}
                {/* <Route path="/team/:teamID" element={<Team />}>
                  <Route index element={<Team />} />

                  <Route path="dashboard" element={<TeamDashboard />} />
                  <Route path="training" element={<TeamTraining />} />
                  <Route path="settings" element={<TeamSettings />} />
                  <Route path=":userID" element={<TeamEmployee />} />
                </Route> */}

                {/* Teacher Dashboard */}
                <Route element={<TeacherGatingRoute />}>
                  <Route path="/dashboard" element={<DashboardLayout />}>
                    <Route index element={<Redirect to="/dashboard/corsi" />} />
                    <Route path="utile" element={<DashboardStats />} />
                    <Route path="corsi" element={<DashboardCourses />} />
                    <Route path="corsi/tutorial" element={<Tutorial />} />
                    <Route path="corsi/new" element={<DashboardCourseNew />} />
                    {/*FIXME - Redirect to correct path here! - corsi/:id/info */}
                    <Route
                      path="corsi/:id"
                      element={<AtomicDashboardCourse />}
                    />
                    <Route
                      path="corsi/:id/:section"
                      element={<AtomicDashboardCourse />}
                    />
                  </Route>
                </Route>

                {/* Teacher Agreement */}
                {/* <Route path="/agreement" element={<Agreement />} /> */}
                {/* <Route path="/agreement/:courseId" element={<Agreement />} /> */}
              </Route>
            </Route>
          </Route>

          <Route
            path="*"
            element={
              <NotFound to="/">
                <Typography style={{ textAlign: "center" }} variant="h2">
                  La pagina non è stata trovata
                </Typography>
              </NotFound>
            }
          />
        </Routes>
      </Suspense>
    </>
  );
};

const WelevelApp = () => (
  <BrowserRouter>
    <ScrollToTop />
    <RoutesComponent />
  </BrowserRouter>
);

export default WelevelApp;
