export const EventsEN = {
  hero: {
    title: "Events and Live Lessons",
    subtitle:
      "Attend classes from top trainers and learn about what's new in your industry from the comfort of home",
  },
  today: {
    title: "Scheduled today",
  },
  featured: {
    title: "Highlights",
  },
  calendar: {
    title: "Calendar",
    upcoming: "Upcoming",
    ended: "Ended",

    noUpcoming: {
      title: "There are no Live Events scheduled",
      subtitle:
        "Check out the most important past events in the tourism-hotel industry now!",
      button: "Discover all",
    },
  },
};
