import { useEffect } from "react";
import { useLocation } from "react-router";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../AuthProvider";

export default function Logout() {
  const { signout } = useAuth();
  const navigate = useNavigate();

  const { pathname, state } = useLocation();

  useEffect(() => {
    let isMounted = true;

    const performSignOut = async () => {
      await signout().then((success) => {
        if (success) navigate("/", { replace: true });
      });
    };

    if (state?.logoff && isMounted) {
      performSignOut().catch(console.error);
    }

    // Clean-up
    return () => {
      isMounted = false;
    };
  }, [navigate, pathname, signout, state?.logoff]);

  return null;
}
