import React, { useEffect, useMemo } from "react";
import { matchPath, Navigate, Outlet, useLocation } from "react-router";
import { useAuth } from "../AuthProvider";
import { useGTMDispatch } from "@elgorditosalsero/react-gtm-hook";

export const whitelistPaths = [
  // Auth routes
  "/register/complete",
  "/send-confirm-email",
  "/profile-locked",
  "/register/complete/:platform",
  "/forgot-password/:token",
  "/confirm/email/:id",
  "/logout",

  // Public pages
  "/abbonamento",
  "/explore",
  "/topics",
  "/studyplans",
  "/aziende",
  "/trova-lavoro",
  "/support",
  "/about",
  "/contact",
];

const GatingRoute = ({ ...rest }) => {
  let { pathname } = useLocation();

  const { user } = useAuth();
  // const sendDataToGTM = useGTMDispatch();

  const gateResult = useMemo(() => {
    const defRes = {
      redirectPath: "/",
    };

    // if this is a always whitelist path exit now
    if (
      whitelistPaths.findIndex((route) => {
        return matchPath(route, pathname);
      }) >= 0
    ) {
      return null;
    }

    // If forgotten the password we can access only the whitelist
    if (user && user.authIsLocked) {
      return { ...defRes, redirectPath: "/profile-locked" };
    }

    // If we have to confirm the email we are in a gray area,
    // only some paths are locked
    if (user && !user.authHasConfirmedEmail) {
      return { ...defRes, redirectPath: "/send-confirm-email" };
    }

    // If invitation or not Social login completed we must complete it
    if (user && !user.authHasPassword) {
      return { ...defRes, redirectPath: "/register/complete" };
    }

    return null;
  }, [pathname, user]);

  // useEffect(() => {
  //   sendDataToGTM({
  //     user_id: user?.id || null,
  //   });
  // }, [user]);

  if (gateResult) {
    const { redirectPath } = gateResult;

    // Redirect to specified path
    return <Navigate to={redirectPath} state={{ from: pathname }} replace />;
  }

  // common authenticated route
  return <Outlet />;
};

export default GatingRoute;
