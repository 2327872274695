import React, { useEffect, useState } from "react";
import { Box, useMediaQuery, withStyles } from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import IconButton from "@material-ui/core/IconButton";
import Toolbar from "@material-ui/core/Toolbar";
import { useTheme } from "@material-ui/styles";
import { useStore } from "outstated";
import { Link, useLocation } from "react-router-dom";
import Logo from "../../../../Logo";
import SearchBar from "../../../../SearchBar";
import UserMenu from "../UserMenu";
import ExploreMenu from "../ExploreMenu";
import MoreHorizIcon from "@material-ui/icons/MenuRounded";
import SearchIcon from "@material-ui/icons/Search";
import LoginStore from "../../../../../store/LoginStore";
import NavAvatarButton from "./NavAvatarButton";
import AuthAlerts from "../AuthAlerts";
import clsx from "clsx";
import { useAuth } from "../../../../../AuthProvider";
import DropdownMenu from "../DropDownMenu/DropdownMenu";
import {
  AwardIcon,
  AwardIconSolid,
  BellIcon,
  BellIconSolid,
  CompassIcon,
  CompassIconSolid,
  HotelIcon,
  HotelIconSolid,
  LiveIcon,
  LiveIconSolid,
} from "../../icons";
import NavIconMenu from "../ToolbarMenu/ToolbarMenu";
import NavIconItemMenu from "../ToolbarMenu/ToolbarMenuItem";
import {
  AwardAnimator,
  BellAnimator,
  ExploreAnimator,
  LiveAnimator,
} from "./styles";
import ImpersonateButton from "./ImpersonateButton";
import { useTranslation } from "react-i18next";
import logoUniverso from "../../../../../assets/logos/logo-universo.svg";

export const styles = (theme) => ({
  root: {},
  appBarColor: {
    backgroundColor: "white",
    color: theme.palette.primary.contrastText,
  },
  logoWrapper: {
    display: "flex",
    padding: "12px 8px",
    minWidth: "64px",
  },
  centerWrapper: {
    display: "inline-flex",
    maxWidth: "100%",
    margin: "0 auto",
    alignItems: "center",
    alignSelf: "stretch",
  },
  rightWrapper: {
    display: "inline-flex",
    maxWidth: "100%",
    alignItems: "center",
    alignSelf: "stretch",
  },
  iconMenu: {
    ["@media (max-width:420px)"]: {
      display: "none",
    },
  },
  search: {
    flex: "1 1 auto",
    maxWidth: "300px",
  },
});

const NavBar = React.forwardRef(function NavBar(props, ref) {
  const { children, classes, className, showLabels = false, ...other } = props;

  const { pathname } = useLocation();
  const { t } = useTranslation();

  const {
    breakpoints: { down },
  } = useTheme();
  const isSm = useMediaQuery(down("sm"));
  const isXs = useMediaQuery(down("xs"));
  const isXxs = useMediaQuery("(max-width:420px)");

  const { user, endImpersonate } = useAuth();
  const { setOpen } = useStore(LoginStore);

  const leftMainMenuItems = [
    // {
    //   icon: ({ active }) => (active ? <BellIconSolid /> : <BellIcon />),
    //   label: t("layout.navbar.menu.unlimited"),
    //   to: "/abbonamento",
    //   value: "/abbonamento",
    // },
  ];

  const leftExtraMenuItems = [
    // {
    //   icon: ({ active }) => (active ? <AwardIconSolid /> : <AwardIcon />),
    //   label: t("layout.navbar.menu.studyplans"),
    //   to: "/studyplans",
    //   value: "/studyplans",
    // },
    // {
    //   icon: ({ active }) => (active ? <LiveIconSolid /> : <LiveIcon />),
    //   label: t("layout.navbar.menu.events"),
    //   to: "/events",
    //   value: "/events",
    // },
    {
      icon: ({ active }) => (active ? <CompassIconSolid /> : <CompassIcon />),
      label: t("layout.navbar.menu.explore"),
      to: "/explore",
      value: "/explore",
    },
  ];

  // the state
  const [searchOpen, setSearchOpen] = useState(!isSm);
  const [searching, setSearching] = useState(false);

  const [anchorUserMenuEl, setUserMenuAnchorEl] = useState(null);
  const [anchorMainMenuEl, setMainMenuAnchorEl] = useState(null);
  const [anchorExploreMenuEl, setAnchorExploreMenuEl] = useState(null);
  const [mainMenuItems, setMainMenuItems] = useState(leftMainMenuItems);

  const id = window.localStorage.getItem("impersonationAdminId");

  const onLogin = () => setOpen("login");

  async function handleEndImpersonate(event) {
    event.preventDefault();
    await endImpersonate();
  }

  function handleUserMenuClick(event) {
    event.preventDefault();
    event.stopPropagation();
    if (user) setUserMenuAnchorEl(event.currentTarget);
    else onLogin();
  }

  function handleUserMenuClose() {
    setUserMenuAnchorEl(null);
  }

  function handleClickExplore(event) {
    event.preventDefault();
    setAnchorExploreMenuEl(event.currentTarget);
  }

  function handleCloseExplore() {
    setAnchorExploreMenuEl(null);
  }

  function handleClickMain(event) {
    setMainMenuAnchorEl(event.currentTarget);
  }

  function handleCloseMain() {
    setMainMenuAnchorEl(null);
  }

  useEffect(() => {
    setSearchOpen(!isSm || searching);
  }, [isSm]);

  useEffect(() => {
    if (isXxs) {
      setMainMenuItems(leftExtraMenuItems.concat(leftMainMenuItems));
    } else {
      setMainMenuItems(leftMainMenuItems);
    }
  }, [isXxs]);

  return (
    <AppBar
      ref={ref}
      position="fixed"
      elevation={6}
      className={clsx(classes.root, className)}
      classes={{ colorPrimary: classes.appBarColor }}>
      <Toolbar>
        {/* HIDE - If small and search is open*/}
        {mainMenuItems.length > 0 && (!searchOpen || !isSm) && (
          <IconButton
            variant="contained"
            aria-controls="explore-menu"
            aria-haspopup="true"
            onClick={handleClickMain}>
            <MoreHorizIcon />
          </IconButton>
        )}

        {/* HIDE - If small and search is open*/}
        {(!searchOpen || !isSm) && (
          <Link to={"/"}>
            <Box className={classes.logoWrapper}>
              {/* <Logo size={"sm"} width={54} height={24} /> */}
              <img
                // className={classes.logo}
                src={logoUniverso}
                height={24}
                alt="Che ci fai qui?"
              />
            </Box>
          </Link>
        )}

        <div className={clsx(classes.centerWrapper)}>
          {(!isXxs || !searchOpen) && (
            <NavIconMenu
              className={classes.iconMenu}
              value={pathname}
              showLabels={!isXs}
              variant={"line"}>
              {/* <NavIconItemMenu
                label={t("layout.navbar.menu.studyplans")}
                value="/studyplans"
                icon={(active) => {
                  return (
                    <AwardAnimator active={active}>
                      {active ? <AwardIconSolid /> : <AwardIcon />}
                    </AwardAnimator>
                  );
                }}
                component={Link}
                to="/studyplans"
              />
              <NavIconItemMenu
                label={t("layout.navbar.menu.events")}
                value="/events"
                icon={(active) => {
                  return (
                    <LiveAnimator active={active}>
                      {active ? <LiveIconSolid /> : <LiveIcon />}
                    </LiveAnimator>
                  );
                }}
                noWrap={true}
                component={Link}
                to="/events"
              /> */}
              <NavIconItemMenu
                label={t("layout.navbar.menu.explore")}
                value="/explore"
                onClick={handleClickExplore}
                icon={(active) => {
                  return (
                    <ExploreAnimator active={active}>
                      {active ? <CompassIconSolid /> : <CompassIcon />}
                    </ExploreAnimator>
                  );
                }}
              />
            </NavIconMenu>
          )}

          <SearchBar
            className={classes.search}
            hide={isSm && !searchOpen}
            onClose={() => {
              setSearchOpen(false);
            }}
            onChange={(searching) => {
              setSearching(searching);
              setSearchOpen(searching);
            }}
          />
        </div>

        <div className={clsx(classes.rightWrapper)}>
          {!searchOpen && (
            <IconButton onClick={() => setSearchOpen(true)}>
              <SearchIcon />
            </IconButton>
          )}

          {/* {!isSm &&
            user &&
            user?.subscriptionStatus &&
            user?.subscriptionStatus === "REGISTERED" && (
              <NavIconMenu
                className={classes.iconMenu}
                value={pathname}
                showLabels={!isXs}>
                <NavIconItemMenu
                  label={t("general.subscribe_plan")}
                  value="/abbonamento"
                  icon={(active) => {
                    return (
                      <BellAnimator active={active}>
                        {active ? <BellIconSolid /> : <BellIcon />}
                      </BellAnimator>
                    );
                  }}
                  component={Link}
                  to="/abbonamento"
                />
              </NavIconMenu>
            )} */}

          {/* HIDE - If small and search is open*/}
          {(!isSm || !searchOpen) && (
            <NavAvatarButton onClick={handleUserMenuClick} user={user} />
          )}

          {id && (!isSm || !searchOpen) && (
            <ImpersonateButton onClick={handleEndImpersonate} />
          )}
        </div>

        <ExploreMenu
          handleClose={handleCloseExplore}
          anchorEl={anchorExploreMenuEl}
        />

        <DropdownMenu
          items={mainMenuItems}
          onClose={handleCloseMain}
          anchorEl={anchorMainMenuEl}
          open={Boolean(anchorMainMenuEl)}
          value={pathname}
          variant="color"
        />
        {user && (
          <UserMenu
            user={user}
            handleClose={handleUserMenuClose}
            anchorEl={anchorUserMenuEl}
          />
        )}
      </Toolbar>
      <AuthAlerts user={user} />
    </AppBar>
  );
});

export default withStyles(styles, { name: "NavBar" })(NavBar);
