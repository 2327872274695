export const HomeIT = {
  seo: {
    title: "Universo Corsi: Video corsi per una vita serena!",
  },
  index: {
    section: {
      title: [
        "Scopri la più grande piattaforma di formazione online dedicata ai professionisti del ",
        "turismo",
        " e dell'",
        "ospitalità",
        "!",
      ],
    },
    startLearningButton: "Inizia a imparare",
  },
  cover: {
    mainHero: {
      title: ["Illuminiamo", "la Tua Vita", "un corso alla volta"],
      subtitle: "Corsi Online per il Vero Illuminato Distratto!",
      listLines: [
        "Percorsi Spirituali Originali",
        "Apprendimento con un Sorriso",
        "Adatto a Tutti",
      ],
    },
    discoverCoursesButton: "Scopri i corsi",
    startNowButton: "Inizia adesso",
  },
  topics: {
    section: {
      title: "Argomenti",
      subtitle: "Tutti i reparti di ogni struttura a 360°",
    },
    showMoreButton: "Mostra più argomenti",
  },
  agenciesBanner: {
    introductoryTitleQuestion: "Gestisci una struttura ricettiva?",
    section: {
      title: "Forma il tuo staff con Welevel Business",
      description:
        "Il tuo staff avrà disposizione <b>la più grande libreria</b> italiana di corsi sul mondo dell’ospitalità con oltre 2500 contenuti formativi sempre disponibili e aggiornati e oltre 120 formatori e professionisti del settore.",
    },
    advantageList: {
      title: "I vantaggi di Welevel Business:",
      lines: [
        "I tuoi collaboratori avranno una montagna di materiale a disposizione: esercitazioni pratiche, test di verifica dell'apprendimento, slide, case history, e lezioni video.",
        "Dashboard di controllo per assegnare ad ogni collaboratore un percorso personalizzato e per monitorarne i progressi.",
        "È sempre più veloce ed economico rispetto alla formazione in aula tradizionale e avrai un tutor sempre al tuo fianco che ti fornirà assistenza e monitoraggio periodico.",
        "Il tuo staff sarà più gratificato e motivato quindi più produttivo!",
      ],
    },
    discoverMoreButton: "Scopri di più",
  },
  socialProof: {
    section: {
      title: "Dicono di noi",
      subtitle:
        "Ascolta le voci di direttori e professionisti che utilizzano Welevel Business",
    },
  },
  agencies: {
    corporate: "CORPORATE",
    sectionTitle: "Per il tuo staff",
    advantageList: {
      title: "Formare il tuo staff non è mai stato così facile:",
      lines: [
        [
          "Puoi controllare",
          " in tempo reale il livello di apprendimento dei tuoi collaboratori",
        ],
        [
          "Il tuo staff",
          " avrà disposizione ",
          "la più grande",
          " libreria italiana di corsi sul mondo dell’ospitalità con ",
          "oltre 1500 contenuti formativi ",
          "sempre disponibili e aggiornati",
          " e oltre 120 ",
          "formatori",
          " e professionisti del settore",
        ],
        [
          "Basta scuse",
          ": i corsi sono sempre accessibili 7/7 24/h da casa o dal lavoro e ",
          "rivedibili",
          " tutte le volte che si vuole, da smartphone o da desktop",
        ],
        [
          "È più ",
          "veloce",
          " ed ",
          "economico",
          " rispetto alla formazione d’aula tradizionale",
        ],
        [
          "C’è ",
          "un tutor sempre al tuo fianco",
          " che ti fornirà ",
          "assistenza",
          " e monitoraggio periodico",
        ],
        [
          "I tuoi collaboratori avranno una montagna di materiale a disposizione:",
          "esercitazioni pratiche",
          ",",
          "test di verifica",
          " dell’apprendimento, ",
          "slide",
          ", e-book, case history e lezioni video",
        ],
        [
          "Il tuo staff sarà più ",
          "gratificato",
          " e ",
          "motivato",
          " quindi più produttivo",
        ],
      ],
    },
  },
  PromoBanner: {
    section: {
      title: "Promo Welevel-Aziende",
    },
  },
  companies: {
    section: {
      title: "Hanno utilizzato Welevel Business anche...",
    },
  },
  courses: {
    mostRecentSection: {
      title: "Novità",
      subtitle:
        "Ogni settimana nuovi video corsi e contenuti sempre aggiornati",
    },
    mostViewedSection: {
      title: "I più popolari",
      subtitle: "I video corsi più visti e cliccati dagli utenti",
    },
  },
  teachers: {
    section: {
      title: "Alcuni dei nostri docenti",
    },
    seeAll: "Vedi tutti...",
  },
  features: {
    list: [
      "Corsi di formazione online",
      "Livello di soddisfazione basato su più di -1 recensioni",
      "Persone iscritte",
    ],
  },
};
