import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import SignalWifiOffIcon from "@material-ui/icons/SignalWifiOff";
import React, { useState } from "react";
import { Offline as OfflineComponent } from "react-detect-offline";
const { baseUrl } = require("../../config");

const styles = theme => ({
  paper: {
    padding: `${theme.spacing(
      2
    )}px ${theme.spacing()}px ${theme.spacing()}px ${theme.spacing() * 2}px`
  },
  avatar: {
    backgroundColor: theme.palette.primary.main
  },
  box: {
    position: "fixed",
    bottom: 0,
    margin: "10px",
    zIndex: 100,
    boxShadow: "rgba(0, 0, 0, 0.25) 0px 4px 48px -16px"
  }
});

const inBrowser = typeof navigator !== "undefined";

// these browsers don't fully support navigator.onLine, so we need to use a polling backup
const unsupportedUserAgentsPattern = /Windows.*Chrome|Windows.*Firefox|Linux.*Chrome/;

const Offline = ({ classes }) => {
  const [state, setstate] = useState(true);

  return (
    <OfflineComponent
      onChange={offline => setstate(!offline)}
      polling={{
        enabled:
          inBrowser && unsupportedUserAgentsPattern.test(navigator.userAgent),
        url: new URL("/check/offline", baseUrl),
        timeout: 5000,
        interval: 25000,
      }}>
      {state && (
        <div className={classes.box}>
          <Paper elevation={0} className={classes.paper}>
            <Grid container spacing={10} alignItems="center" wrap="nowrap">
              <Grid item xs={1}>
                <Avatar>
                  <SignalWifiOffIcon />
                </Avatar>
              </Grid>
              <Grid item xs={11}>
                <Typography>
                  Hai perso la connessione ad internet. Questa app è offline.
                </Typography>
              </Grid>
            </Grid>
            <Grid container justify="flex-end" spacing={8}>
              <Grid item>
                <Button color="primary" onClick={() => setstate(false)}>
                  Chiudi
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </div>
      )}
    </OfflineComponent>
  );
};

export default withStyles(styles)(Offline);
