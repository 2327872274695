import gql from "graphql-tag";

export const loginMutation = gql`
  mutation userLogin($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      sessionId
      user {
        id

        firstName
        lastName

        isTeacher
        isAdmin

        authHasConfirmedEmail
        authHasPassword
        authIsLocked

        subscriptionStatus
      }
      errors {
        path
        message
      }
    }
  }
`;

export const registerMutation = gql`
  mutation userRegister($user: RegisterUserInput!, $url: String) {
    register(user: $user, url: $url) {
      user {
        id
        email
        firstName
        lastName
      }
      sessionId
      errors {
        path
        message
      }
    }
  }
`;
