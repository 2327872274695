import {
  Box,
  Container,
  Grid,
  makeStyles,
  Typography,
  Hidden,
} from "@material-ui/core";
import clsx from "clsx";
import { Trans } from "react-i18next";
import { useIubendaCS } from "../../helpers/iubenda/ConsentManagerProvider";
import "../../i18n";
import { colors } from "../../style/colors";
import Logo from "../Logo/index";
import { MailchimpSubscribe } from "../MailchimpSubscribe";
import SocialIconsBar from "../SocialIconsBar";
import LanguageSelector from "./components/languageSelector";
import { FooterLink, FooterMenu } from "./components/linksMenu";
import logoUniverso from "../../assets/logos/logo-universo.svg";

const useClasses = makeStyles((theme) => ({
  /* Styles applied to the root element. */
  root: {
    backgroundColor: " #212121",
    color: "#fff",
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(5),

    "&:@media print": { display: "none" },
  },
  logoWrapper: {
    display: "flex",
    height: "100%",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
  logo: {
    maxWidth: "400px",
  },
  titleWrapper: {
    marginBottom: theme.spacing(2),
  },
  title: {
    display: "block",
    textTransform: "uppercase",
  },
  subtitle: {
    display: "block",
  },
  footer: {
    marginTop: theme.spacing(-3),
    marginBottom: theme.spacing(-3),
  },
  social: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    color: colors.textWhitePrimary,
  },
  copyright: {
    textAlign: "center",
    color: colors.textWhiteSecondary,
    fontSize: "0.875rem",
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
}));

const Footer = ({ className }) => {
  const classes = useClasses();

  // const { openIubendaCSDialog } = useIubendaCS();

  return (
    <Box component={"footer"} className={clsx(classes.root, className)}>
      <Container>
        <Grid container spacing={4}>
          <Hidden smDown>
            <Grid item xs={0} sm={0} md={2} lg={3}></Grid>
          </Hidden>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Box className={classes.logoWrapper}>
              {/* <Logo
                className={classes.logo}
                size="lg"
                color={colors.textWhitePrimary}
              /> */}
              <img
                className={classes.logo}
                src={logoUniverso}
                alt="Che ci fai qui?"
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <Box className={classes.titleWrapper}>
                  <Typography
                    className={classes.title}
                    component={"span"}
                    variant="h6">
                    <Trans i18nKey="layout.footer.featured.title" />
                  </Typography>
                </Box>
                {/*---*/}
                <FooterMenu>
                  <FooterLink to="/explore">
                    <Trans i18nKey="layout.footer.featured.explore" />
                  </FooterLink>
                  <FooterLink to="/topics">
                    <Trans i18nKey="layout.footer.featured.topics" />
                  </FooterLink>
                  <FooterLink to="/teachers">
                    <Trans i18nKey="layout.footer.welevel.teachers" />
                  </FooterLink>
                  <LanguageSelector />
                </FooterMenu>
              </Grid>
            </Grid>
          </Grid>
          <Hidden smDown>
            <Grid item xs={0} sm={0} md={2} lg={3}></Grid>
          </Hidden>
        </Grid>

        <Box className={classes.footer}>
          {/* <SocialIconsBar className={classes.social} /> */}
          <Typography className={classes.copyright}>
            © Universo Corsi 2300 a.C. - 2024 d.C. • P.IVA 666-42069 Nice!
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};

export default Footer;
