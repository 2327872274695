export const completeSubscriptionFormEN = {
  header: {
    title: "Complete your registration",
    description: "We need some more information to activate your account.",
  },
  firstName: {
    errorRequired: "First name is required",
  },
  lastName: {
    errorRequired: "Last name is required",
  },
  email: {
    errorRequired: "Email is required",
    errorInvalid: "Email is not valid",
  },
  password: {
    errorRequired: "Password is required",
    errorTooShort: "Password is too short",
  },
  submit: "Complete registration",
  completeSubscriptionMutationError:
    "Something went wrong. Check if the email is already in use or contact our support team at info@example.com",
};
