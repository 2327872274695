import SvgIcon from "@material-ui/core/SvgIcon";

export default function CompassIcon(props) {
  return (
    <SvgIcon {...props}>
      <path d="M24,12A12,12,0,1,1,12,0,12,12,0,0,1,24,12Zm-2.23,0A9.73,9.73,0,0,0,12,2.24,9.64,9.64,0,0,0,2.24,12,9.63,9.63,0,0,0,12,21.76,9.71,9.71,0,0,0,21.77,12Z" />
      <path d="M7.17,17.92a1.12,1.12,0,0,1-1-1.63c.45-1.19.91-2.38,1.37-3.56.38-1,.76-1.94,1.13-2.92A1.78,1.78,0,0,1,9.79,8.7l6.45-2.49a1.12,1.12,0,0,1,1.66.88,1.42,1.42,0,0,1-.11.64c-.83,2.18-1.68,4.36-2.51,6.54a1.68,1.68,0,0,1-1,1l-6.5,2.5A4,4,0,0,1,7.17,17.92ZM13.5,12A1.48,1.48,0,0,0,12,10.49a1.51,1.51,0,1,0,0,3A1.47,1.47,0,0,0,13.5,12Z" />
    </SvgIcon>
  );
}
