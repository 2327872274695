export const EventsIT = {
  hero: {
    title: "Eventi e Lezioni live",
    subtitle:
      "Partecipa alle lezioni dei migliori formatori e scopri le novità del tuo settore, comodamente da casa",
  },
  today: {
    title: "Oggi in programma",
  },
  featured: {
    title: "In evidenza",
  },
  calendar: {
    title: "Calendario",
    upcoming: "Prossimi",
    ended: "Conclusi",

    noUpcoming: {
      title: "Non ci sono Eventi Live in programma",
      subtitle:
        "Guarda subito i più importanti eventi passati del settore turistico-alberghiero!",
      button: "Scopri tutti",
    },
  },
};
