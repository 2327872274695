export const ContactUsEN = {
  seo: {
    title: "Contact us",
  },
  head: {
    title: "Contact us, we're ready to help you!",
    subtitle: "Any question?",
    body: "Message us, we'll reach you out you as soon as possible.",
  },
  form: {
    requestType: {
      support: "Support request",
      general: "General informations",
      other: "Other request",
    },
    button: "Submit",
  },
  address: {
    title: "Where can you find us?",
    subtitle: "Welevel srl",
    address: "Via Macanno, 38Q <br/> ZIP: 47923 - Rimini (RN), Italy",
    phone: "<url>0039 0541 57474</url>",
    mobile: "<url>0039 331 6209442</url> (also on  <wa>Whatsapp</wa>)",
    email: "<url>info@example.com</url>",
  },
  social: {
    title: "Our social channels",
  },
};
