export const forgotPasswordEN = {
  title: "Change your password",
  password: {
    new: "New Password",
  },
  alert: {
    success: "Password successfully changed, we are redirecting you to home.",
    linkExpired:
      "The password renewal link has expired. You are being redirected to the request page for a new email.",
  },
};
