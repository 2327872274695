export const ReviewFormEN = {
  newReview: {
    title: "Congratulations!",
    description: "You have completed all the lessons",
    ratingField: {
      label: "How do you rate this course?",
      error: "Add a star rating",
    },
    contentField: {
      label: "Review",
      placeholder: "Write a review...",
    },
    cancel: "Skip",
    sendReview: "Send review",
    error: "Unable to send the review. Please try again later.",
  },
  reviewSent: {
    title: "Thank you for your help!",
    description: "Your review has been sent",
    cta: "Continue your training",
  },
};
