export const LessonEN = {
  lessonCompleted: {
    title: "Congratulations!",
    description: "You have completed this lesson. Go to the next one!",
    cta: "Next lesson",
  },
  lessonUpsell: {
    freeLesson: {
      title: "Free Lesson",
      description: "Log in or Sign up to watch it now!",
    },
    paidLesson: {
      title: "Get Welevel Unlimited",
      description:
        "Access to over 2500 courses for tourism and hospitality professionals",
    },
  },
};
