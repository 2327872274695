import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import * as yup from "yup";

function buildFieldString(key, { path, prefix, label, ...props }, { i18n, t }) {
  if (label || i18n.exists(`${prefix}.${path}`)) {
    label = t(label ? label : `${prefix}.${path}`);
  }
  return t(key, { path: label ? label : path, ...props });
}

export const YUP_ERROR_CODE = "BAD_USER_INPUT";

export function parseGqlYupErrorToFormik(error, { path, prefix }, { i18n, t }) {
  // TODO: @deprecated
  // remove after all components are updated and the prefix is implemented
  const errors = error.graphQLErrors || [];
  return (
    errors
      .filter(({ extensions: { code, yup } }) => code === YUP_ERROR_CODE)
      // TODO - Rebuild path in dot notation (reduce) and compare it to path
      .filter(({ path: gqlPath }) => (path ? gqlPath[0] === path : true))
      .map(
        ({
          extensions: {
            yup: { errors },
          },
        }) => {
          return errors;
        }
      )
      .reduce((acc, el) => {
        console.log(errors);
        console.log(el);
        return [...acc, ...el];
      }, [])
      .reduce((acc, el) => {
        if (el.key && i18n.exists(el.key)) {
          acc[el.path] = buildFieldString(
            el.key,
            { prefix, ...el },
            { i18n, t }
          );
        } else {
          acc[el.path] = el.message;
        }
        return acc;
      }, {})
  );
}

function buildYupLocale({ prefix }, translation) {
  // FIXME - Missing types
  //
  // export let object: Required<ObjectLocale> = {
  //   noUnknown: '${path} field has unspecified keys: ${unknown}',
  // };
  //
  // export let array: Required<ArrayLocale> = {
  //   min: '${path} field must have at least ${min} items',
  //   max: '${path} field must have less than or equal to ${max} items',
  //   length: '${path} must have ${length} items',
  // };

  yup.setLocale({
    mixed: {
      default: (props) => {
        return buildFieldString(
          "yup.mixed.default",
          { prefix, ...props },
          translation
        );
      },
      required: (props) => {
        return buildFieldString(
          "yup.mixed.required",
          { prefix, ...props },
          translation
        );
      },
      defined: (props) => {
        return buildFieldString(
          "yup.mixed.defined",
          { prefix, ...props },
          translation
        );
      },
      notNull: (props) => {
        return buildFieldString(
          "yup.mixed.notNull",
          { prefix, ...props },
          translation
        );
      },
      oneOf: (props) => {
        return buildFieldString(
          "yup.mixed.oneOff",
          { prefix, ...props },
          translation
        );
      },
      notOneOf: (props) => {
        return buildFieldString(
          "yup.mixed.notOneOf",
          { prefix, ...props },
          translation
        );
      },
      // FIXME - Missing error
      //   notType: ({ path, type, value, originalValue }) => {
      //     const castMsg =
      //       originalValue != null && originalValue !== value
      //         ? ` (cast from the value \`${printValue(originalValue, true)}\`).`
      //         : '.';
      //
      //     return type !== 'mixed'
      //       ? `${path} must be a \`${type}\` type, ` +
      //       `but the final value was: \`${printValue(value, true)}\`` +
      //       castMsg
      //       : `${path} must match the configured type. ` +
      //       `The validated value was: \`${printValue(value, true)}\`` +
      //       castMsg;
      //   },
    },

    string: {
      length: (props) => {
        return buildFieldString(
          "yup.string.length",
          { prefix, ...props },
          translation
        );
      },
      min: (props) => {
        return buildFieldString(
          "yup.string.min",
          { prefix, ...props },
          translation
        );
      },
      max: (props) => {
        return buildFieldString(
          "yup.string.max",
          { prefix, ...props },
          translation
        );
      },
      matches: (props) => {
        return buildFieldString(
          "yup.string.matches",
          { prefix, ...props },
          translation
        );
      },
      email: (props) => {
        return buildFieldString(
          "yup.string.email",
          { prefix, ...props },
          translation
        );
      },
      url: (props) => {
        return buildFieldString(
          "yup.string.url",
          { prefix, ...props },
          translation
        );
      },
      uuid: (props) => {
        return buildFieldString(
          "yup.string.uuid",
          { prefix, ...props },
          translation
        );
      },
      trim: (props) => {
        return buildFieldString(
          "yup.string.trim",
          { prefix, ...props },
          translation
        );
      },
      lowercase: (props) => {
        return buildFieldString(
          "yup.string.lowercase",
          { prefix, ...props },
          translation
        );
      },
      uppercase: (props) => {
        return buildFieldString(
          "yup.string.uppercase",
          { prefix, ...props },
          translation
        );
      },
    },
    number: {
      min: (props) => {
        return buildFieldString(
          "yup.number.min",
          { prefix, ...props },
          translation
        );
      },
      max: (props) => {
        return buildFieldString(
          "yup.number.max",
          { prefix, ...props },
          translation
        );
      },
      lessThan: (props) => {
        return buildFieldString(
          "yup.number.lessThan",
          { prefix, ...props },
          translation
        );
      },
      moreThan: (props) => {
        return buildFieldString(
          "yup.number.moreThan",
          { prefix, ...props },
          translation
        );
      },
      positive: (props) => {
        return buildFieldString(
          "yup.number.positive",
          { prefix, ...props },
          translation
        );
      },
      negative: (props) => {
        return buildFieldString(
          "yup.number.negative",
          { prefix, ...props },
          translation
        );
      },
      integer: (props) => {
        return buildFieldString(
          "yup.number.integer",
          { prefix, ...props },
          translation
        );
      },
    },
    date: {
      min: (props) => {
        return buildFieldString(
          "yup.date.min",
          { prefix, ...props },
          translation
        );
      },
      max: (props) => {
        return buildFieldString(
          "yup.date.max",
          { prefix, ...props },
          translation
        );
      },
    },
    boolean: {
      isValue: (props) => {
        return buildFieldString(
          "yup.boolean.isValue",
          { prefix, ...props },
          translation
        );
      },
    },
  });
}

/**
 * Yup Provider
 */
function YupProvider({ prefix = null, children }) {
  const translation = useTranslation();

  useEffect(async () => {
    buildYupLocale({ prefix }, translation);
  }, []);

  return <>{children}</>;
}

export default YupProvider;
export { YupProvider };
