import * as React from "react";
import clsx from "clsx";
import { withStyles } from "@material-ui/styles";
import { Button } from "@material-ui/core";
import { colors } from "../../../../style/colors";

export const styles = (theme) => ({
  /* Styles applied to the root element. */
  root: {
    "&:not(:first-child)": {
      marginLeft: theme.spacing(1),

    },
    "&$selected": {
      backgroundColor: colors.primary,
      color: "#fff",
      "&:hover": {
        backgroundColor: colors.primaryDark
      },
    },
  },

  /* Pseudo-class applied to the root element if selected. */
  selected: {},
});

const NavButton = React.forwardRef(function NavButtonItem(props, ref) {
  const {
    classes,
    className,
    selected,
    ...other
  } = props;

  return (
    <Button
      ref={ref}
      className={clsx(
        classes.root,
        {
          [classes.selected]: selected,
        },
        className
      )}
      {...other}
    />
  );
});

export default withStyles(styles, { name: "NavButton" })(NavButton);
