import React from "react";
import styled, { keyframes } from "styled-components";

// export const NavBarButton = styled(Button)`
//   min-width: 0;
//   background-color: ${({ $active, $solid }) =>
//     $active
//       ? colors.textPrimary
//       : $solid
//       ? colors.softDarkBackground
//       : "transparent"};
//   border-radius: ${({ $borderRadius }) => $borderRadius || "800px"};
//   color: ${({ $active }) =>
//     $active ? colors.textWhitePrimary : colors.textPrimary};
//
//   &:hover {
//     background-color: ${({ $active }) =>
//       $active ? colors.textPrimary : colors.softDarkBackgroundHover};
//     color: ${({ $active }) =>
//       $active ? colors.textWhitePrimary : colors.textPrimary};
//   }
// `;

const ExploreAnimation = keyframes`
  0% {
    transform: rotate(0deg);
  }

  25% {
    transform: rotate(-30deg);
  }

  60% {
    transform: rotate(160deg);
  }

  100% {
    transform: rotate(0deg);
  }
`;

export const ExploreAnimator = styled.div`
  animation: ${({ active }) => active && ExploreAnimation} 1400ms ease-in-out
    forwards;
  transform-origin: center;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const BellAnimation = keyframes`
  0% {
    transform: rotate(0deg);
  }

  25% {
    transform: rotate(-30deg);
  }

  60% {
    transform: rotate(30deg);
  }

  100% {
    transform: rotate(0deg);
  }
`;

export const BellAnimator = styled.div`
  animation: ${({ active }) => active && BellAnimation} 1400ms ease-in-out
    forwards;
  transform-origin: center;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const AwardAnimation = keyframes`
  from {
    transform: scale3d(1, 1, 1);
  }
  10%,
  20% {
    transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
  }
  30%,
  50%,
  70%,
  90% {
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
  }
  40%,
  60%,
  80% {
      transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
  }
  to {
    transform: scale3d(1, 1, 1);
  }
`;

export const AwardAnimator = styled.div`
  animation: ${({ active }) => active && AwardAnimation} 1400ms;
  transform-origin: center;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const LiveAnimation = keyframes`
  from {
    transform: scale3d(1, 1, 1);
  }

  50% {
    transform: scale3d(1.05, 1.05, 1.05);
  }

  to {
    transform: scale3d(1, 1, 1);
  }
`;

export const LiveAnimator = styled.div`
  animation: ${({ active }) => active && LiveAnimation} 1400ms;
  transform-origin: center;
  display: flex;
  justify-content: center;
  align-items: center;
`;
